export default {
    size:'kích thước',
    color:'màu',
    dongjies:'Tài khoản này đã bị đóng băng, vui lòng liên hệ với bộ phận chăm sóc khách hàng',
    dongjie:'Tiền đã bị đóng băng, vui lòng liên hệ với dịch vụ khách hàng',
    "mg": {
        "fy1": "Bảo Bối Lãi Suất",
        "fy2": "Trang Chủ",
        "fy3": "Điện thoại di động nạp tiền",
        "fy4": "Mã Mời",
        "fy5": "Trong Hosting",
        "fy6": "Ngày",
        "fy7": "Năng Suất Hàng Ngày",
        "fy8": "Mua Tối Thiểu",
        "fy9": "Yêu Cầu Mua Ngay",
        "fy10": "Số Dư Có Sẵn",
        "fy11": "Vui lòng nhập số tiền mua",
        "fy12": "Tất Cả",
        "fy13": "Chu Kỳ Liên Tục",
        "fy14": "Năng Suất Hàng Ngày",
        "fy15": "Thời Gian Phân Phối",
        "fy16": "Hàng Ngày",
        "fy17": "Tiền Ký Quỹ",
        "fy18": "Hết Hạn Hoàn Trả",
        "fy19": "Mua Tối Thiểu",
        "fy20": "Lợi Nhuận Dự Kiến",
        "fy21": "Yêu Cầu Mua Ngay",
        "fy22": "Mua Tối Thiểu",
        "fy23": "Số dư không đủ Vui lòng thử lại sau khi nạp tiền",
        "fy24": "Hồ Sơ Đặt Hàng",
        "fy25": "Đặt Hàng",
        "fy26": "Đang Tiến Hành",
        "fy27": "Đã Hoàn Thành",
        "fy28": "Thời Gian Còn Lại",
        "fy29": "Đang Tiến Hành",
        "fy30": "Đã Hoàn Thành",
        "fy31": "Số Thứ Tự",
        "fy32": "Số Tiền Yêu Cầu Mua",
        "fy33": "Lợi Nhuận Hàng Ngày",
        "fy34": "Chu Kỳ Liên Tục",
        "fy35": "Lợi Nhuận Dự Kiến",
        "fy36": "Lợi Nhuận",
        "fy37": "Ngày Mua",
        "fy38": "Giải Cứu",
        "fy39": "Xác Nhận Redemption",
        "fy40": "Thu Hồi",
        "fy41": "Xác nhận việc mua lại quỹ ký quỹ",
        "fy42": "Lưu ý: Một khi quỹ ký quỹ được mua lại, lãi suất sẽ trống.",
        "fy43": "Nạp Tiền",
        "fy44": "Chi Tiết",
        "fy45": "Số Diện Thoại",
        "fy46": "Số Tiền Nạp",
        "fy47": "Chưa Chọn",
        "fy48": "Xin lưu ý, sau khi nạp tiền thành công xin lưu ý thanh toán.",
        "fy49": "Nạp Tiền Ngay",
        "fy50": "Vui lòng nhập số điện thoại của bạn",
        "fy51": "Nhận Xét",
        "fy52": "Đã Bình Luận",
        "fy53": "Viết Nhận Xét",
        "fy54": "Đánh Giá Sản Phẩm",
        "fy55": "Viết Nhận Xét",
        "fy56": "Để lại bình luận quý giá của bạn",
        "fy57": "Phơi Nắng.",
        "fy58": "Giới Thiệu",
        "fy59": "Rất Tệ",
        "fy60": "Rất Tệ.",
        "fy61": "Tổng Quan",
        "fy62": "Tốt Lắm.",
        "fy63": "Rất Tốt",
        "fy64": "Hồ Sơ Nạp Tiền",
        "fy65": "Nạp Tiền Thành Công",
        "fy66": "Mã Mời",
        "fy67": "Vui Lòng Nhập Mã Mời",
        "fy68": "Tổng Lợi Nhuận",
        "fy69": "Lợi Nhuận Hôm Nay",
        "fy70": "Chưa Hoàn Thành",
        "fy71": "Phiếu giảm giá hiện tại đã đạt đến giới hạn mua",
        "fy72": "Không đạt được phiếu giảm giá Menkan",
        "fy73": "Nạp Tiền Người Dùng",
        "fy74": "Thanh Toán Này",
        "fy75": "Vui lòng nhập số lượng",
        "fy76": "Thanh Toán Nhanh",
        "fy77": "Nạp Tiền Ngay",
        "fy78": "Vui lòng chọn ví thanh toán của bạn",
        "fy79": "Hiện Tại",
        "fy80": "Chuỗi Trường Sóng",
        "fy81": "Chuỗi Ethereum",
        "fy82": "Chuỗi Binance",
        "fy83": "Chuỗi Euro",
        "fy84": "Châu Âu",
        "fy85": "Chuỗi",
        "fy86": "Kênh",
        "fy87": "Ưu Tiên"
    },
    "newadd": { "fy1": "Tên Chủ Thẻ", "fy2": "Vui lòng nhập tên chủ thẻ", "fy3": "Số Thẻ Ngân Hàng", "fy4": "Vui lòng nhập số thẻ ngân hàng", "fy5": "Tên Ngân Hàng", "fy6": "Vui lòng nhập tên ngân hàng", "fy7": "Mã Ngân Hàng", "fy8": "Vui lòng nhập mã ngân hàng", "fy9": "Giao hàng thương mại điện tử", "fy10": "Đơn giản hóa quy trình giao hàng của bạn và cung cấp các tùy chọn giao hàng nhanh chóng và thuận tiện cho khách hàng của bạn. Nền tảng của chúng tôi đảm bảo rằng các mặt hàng của bạn được giao an toàn và đúng giờ mỗi lần.", "fy11": "Theo dõi thời gian thực", "fy12": "Cung cấp cho khách hàng của bạn thông tin cập nhật theo thời gian thực theo từng bước. Nền tảng của chúng tôi cung cấp cho bạn kinh nghiệm công cụ bạn cần để cung cấp giao hàng hạng nhất.", "fy13": "Khả Năng Mở Rộng", "fy14": "Khi doanh nghiệp của bạn phát triển, nền tảng của chúng tôi cũng vậy. Mạng lưới phân phối của chúng tôi được thiết kế để đi cùng với doanh nghiệp của bạn, đảm bảo bạn có thể xử lý bất kỳ tăng khối lượng giao hàng nào." },
    "components": {
        "fy1": "Phần",
        "fy2": "Thanh Toán",
        "fy3": "Quên Mật Khẩu",
        "fy4": "Giải Quyết Ngay",
        "fy5": "Tổng Cộng",
        "fy6": "Hàng Hóa",
        "fy7": "Hãy chọn những sản phẩm phù hợp.",
        "fy8": "Vui lòng thêm địa chỉ nhận trước",
        "fy9": "Vui lòng đặt mật khẩu thanh toán trước",
        "fy10": "Vui lòng nhập mật khẩu",
        "fy11": "Đi Và Trả Tiền",
        "fy12": "Vui lòng nhập mật khẩu thanh toán",
        "fy13": "Cập Nhật Mật Khẩu",
        "fy14": "Đặt Mật Khẩu",
        "fy15": "Mật Khẩu Cũ",
        "fy16": "Mật Khẩu",
        "fy17": "Vui Lòng Nhập",
        "fy18": "Mật Khẩu Mới",
        "fy19": "Xác Nhận Mật Khẩu",
        "fy20": "Vui lòng nhập mật khẩu mới",
        "fy21": "Vui lòng xác nhận mật khẩu",
        "fy22": "Lưu",
        "fy23": "Vui lòng nhập mật khẩu 6 chữ số",
        "fy24": "Vui lòng nhập đúng mật khẩu 6 chữ số",
        "fy25": "Mật khẩu nhập hai lần không phù hợp",
        "fy26": "Thông Điệp",
        "fy27": "Gửi",
        "fy28": "Tải ảnh không thành công",
        "fy29": "Vui lòng nhập thuộc tính",
        "fy30": "Xác Nhận",
        "fy31": "Tải Thêm",
        "fy32": "Khách Hàng",
        "fy33": "Người Bán",
        "fy34": "Hộp Thư",
        "fy35": "Điện Thoại",
        "fy36": "Mật Khẩu",
        "fy37": "Quên Mật Khẩu",
        "fy38": "Đăng Ký",
        "fy39": "Đăng Nhập",
        "fy40": "Đăng nhập bằng điện thoại",
        "fy41": "Đăng nhập bằng hộp thư",
        "fy42": "Trợ Giúp",
        "fy43": "Vui lòng nhập số điện thoại",
        "fy44": "Vui lòng nhập địa chỉ hộp thư",
        "fy45": "Xác Minh Hộp Thư",
        "fy46": "Xác Minh Di Động",
        "fy47": "Vui lòng nhập CAPTCHA",
        "fy48": "Tải Thêm",
        "fy49": "Tải Thêm",
        "fy50": "Chọn giới tính của bạn",
        "fy51": "Con Trai",
        "fy52": "Cô Gái",
        "fy53": "Tiếp Theo",
        "fy54": "Chọn Tuổi Của Bạn",
        "fy55": "Chọn sở thích của bạn",
        "fy56": "Tập trung ít nhất 4 mối quan tâm",
        "fy57": "Bắt Đầu Khám Phá",
        "fy58": "Vuốt lên để chọn tuổi",
        "fy59": "Gửi Captcha",
        "fy60": "Lỗi Captcha",
        "fy61": "Tuổi",
        "fy62": "Thử Lại Sau Vài Giây",
        "fy63": "Gửi CAPTCHA thành công",
        "fy64": "Thiết lập lại thành công, vui lòng đăng nhập",
        "fy65": "Xác nhận tung ra tài khoản hiện tại?",
        "fy66": "Đăng Nhập",
        "fy67": "Tài Trợ",
        "fy68": "Bán Hàng Tháng",
        "fy69": "Không Có Dữ Liệu.",
        "fy70": "Thanh Toán Ngay",
        "fy71": "Ví Tiền",
        "fy72": "Thanh Toán",
        "fy73": "Xác Nhận Thanh Toán",
        "fy74": "ID, bằng lái xe, hộ chiếu",
        "fy75": "Vui lòng tải giấy tờ lên trước.",
        "fy76": "Ứng dụng thành công, vui lòng chờ xét duyệt",
        "fy77": "Vui Lòng Chọn"
    },
    "buyer": {
        "withdrawList": {
            "fy1": "Rút tiền Chi Tiết",
            "fy2": "Thời Gian",
            "fy3": "Loại",
            "fy4": "Số Lượng",
            "fy5": "Trạng Thái",
            "fy6": "Đang Được Xem Xét",
            "fy7": "Thông Qua",
            "fy8": "Từ Chối"
        },
        "withdraw": {
            "fy1": "Cách Rút tiền",
            "fy2": "không",
            "fy3": "Giá Dịch Vụ",
            "fy4": "Số Tiền Rút Ra",
            "fy5": "Vui lòng nhập số tiền",
            "fy6": "Phí Dịch Vụ",
            "fy7": "Hoạt Động",
            "fy8": "Rút tiền Địa Chỉ",
            "fy9": "Rút tiền",
            "fy10": "Mật Khẩu Thanh Toán",
            "fy11": "Vui lòng nhập mật khẩu thanh toán",
            "fy12": "Xác Định",
            "fy13": "Vui lòng đặt mật khẩu thanh toán trước",
            "fy14": "Vui lòng nhập số tiền",
            "fy15": "Vui lòng nhập mật khẩu",
            "fy16": "Vui lòng thêm phương thức rút tiền",
            "fy17": "Số Dư Có Sẵn"
        },
        "shippingAddress": {
            "fy1": "Địa Chỉ Giao Hàng"
        },
        "selected": {
            "fy1": "Cửa Hàng Chọn Lọc",
            "fy2": "Tìm Kiếm",
            "fy3": "Tập Trung",
            "fy4": "Số Lượng Truy Cập",
            "fy5": "Tất Cả"
        },
        "orderInfo": {
            "fy1": "Chi Tiết Đặt Hàng",
            "fy2": "Số Thứ Tự",
            "fy3": "Đặt Hàng Thời Gian",
            "fy4": "Số Lượng Sản Phẩm",
            "fy5": "Đơn Giá Hàng Hóa",
            "fy6": "Thanh Toán Thực",
            "fy7": "Xác Định"
        },
        "layout": {
            "fy1": "Về Chúng Tôi",
            "fy2": "Lên Trên",
            "fy3": "Dịch Vụ Trợ Giúp",
            "fy4": "Chính Sách Bảo Mật",
            "fy5": "Cửa Hàng",
            "fy6": "Miễn Phí",
            "fy7": "Mở Cửa Hàng Ngay",
            "fy8": "Hàng Hóa",
            "fy9": "Cửa Hàng",
            "fy10": "Tìm Kiếm",
            "fy11": "Hủy Bỏ",
            "fy12": "Đăng Nhập",
            "fy13": "Thêm",
            "fy14": "Cửa Hàng Chọn Lọc",
            "fy15": "Vui lòng nhập ít nhất ba ký tự để tìm kiếm",
            "fy16": "Lịch Sử Tìm Kiếm",
            "fy17": "Tập Trung",
            "fy18": "Số Lượng Truy Cập",
            "fy19": "Xem Tất Cả"
        },
        "category": {
            "fy1": "Đề Xuất Chọn Lọc",
            "fy2": "Cửa Hàng Chọn Lọc",
            "fy3": "Chọn"
        },
        "goodsDetail": {
            "fy1": "Số Lượng",
            "fy2": "Bán Hàng",
            "fy3": "Thêm Vào Giỏ Hàng",
            "fy4": "Giao Hàng Nhanh",
            "fy5": "Miễn phí vận chuyển/trả lại",
            "fy6": "Mô Tả Sản Phẩm",
            "fy7": "Đánh Giá Sản Phẩm",
            "fy8": "Ngôi Sao",
            "fy9": "Tạm thời không có đánh giá",
            "fy10": "Sản phẩm này tạm thời không mua được."
        },
        "home": {
            "fy1": "Ưu Đãi Mới Nhất",
            "fy2": "Lời Khuyên Tốt",
            "fy3": "Mua Một Tặng Một",
            "fy4": "Mua Ngay",
            "fy5": "Ưu đãi giới hạn thời gian",
            "fy6": "Đếm Ngược",
            "fy7": "Hàng Nóng",
            "fy8": "Miễn Phí Vận Chuyển",
            "fy9": "Tất Cả Sản Phẩm",
            "fy10": "Cửa Hàng Nổi Tiếng",
            "fy11": "Xem các cửa hàng nóng"
        },
        "myHeart": {
            "fy1": "Tập Trung",
            "fy2": "Xem Tất Cả",
            "fy3": "Số dư có sẵn trong ví"
        },
        "shopDetail": {
            "fy1": "Tập Trung",
            "fy2": "Số Lượng Truy Cập",
            "fy3": "Tổng Hợp",
            "fy4": "Thời Gian",
            "fy5": "Bán Hàng",
            "fy6": "Giá Cả",
            "fy7": "Xem Tất Cả"
        },
        "personal": {
            "fy1": "Đăng Nhập",
            "fy2": "Trung Tâm Cá Nhân",
            "fy3": "Thông Tin Cơ Bản",
            "fy4": "Ví Của Tôi",
            "fy5": "Tiền Gửi",
            "fy6": "Rút Tiền",
            "fy7": "Chi Tiết Hóa Đơn",
            "fy8": "Nhật Ký Tiền Gửi",
            "fy9": "Nhật Ký Rút Tiền",
            "fy10": "Liên Kết Usdt",
            "fy11": "Trung Tâm Đặt Hàng",
            "fy12": "Lệnh Của Tôi",
            "fy13": "Địa Chỉ Giao Hàng",
            "fy14": "Dịch Vụ Chức Năng",
            "fy15": "Cửa Hàng Chọn Lọc",
            "fy16": "Nhà cung cấp ứng dụng",
            "fy17": "Thay Đổi Mật Khẩu",
            "fy18": "Thay đổi mật khẩu thanh toán",
            "fy19": "Đặt mật khẩu thanh toán",
            "fy20": "Liên Hệ Hỗ Trợ",
            "fy21": "Về Chúng Tôi",
            "fy22": "Dịch Vụ Trợ Giúp",
            "fy23": "Sửa Đổi",
            "fy24": "Thêm",
            "fy25": "Địa Chỉ",
            "fy26": "Tên",
            "fy27": "Vui Lòng Nhập",
            "fy28": "Thông Tin Liên Hệ",
            "fy29": "Lưu",
            "fy30": "Xoá",
            "fy31": "Sửa Đổi Thành Công",
            "fy32": "Thành Công Mới",
            "fy33": "Địa chỉ mặc định không thể xóa",
            "fy34": "Xóa Thành Công",
            "fy35": "Vui lòng chọn Mainnet",
            "fy36": "Vui lòng chọn Thỏa thuận",
            "fy37": "Địa Chỉ Ví",
            "fy38": "Vui lòng chọn địa chỉ ví USDT",
            "fy39": "Thành Viên Thường",
            "fy40": "Số Dư Của Tôi",
            "fy41": "Tìm Kiếm",
            "fy42": "Mối Quan Tâm Của Tôi",
            "fy43": "Tập Trung",
            "fy44": "Số Lượng Truy Cập",
            "fy45": "Tất Cả",
            "fy46": "Chi Tiết Hóa Đơn",
            "fy47": "Thời Gian",
            "fy48": "Số Thứ Tự",
            "fy49": "Số Lượng",
            "fy50": "Ghi Chú",
            "fy51": "Thay đổi mật khẩu thành công, vui lòng đăng nhập",
            "fy52": "Hai lần mật khẩu không phù hợp",
            "fy53": "Mật khẩu mới không được để trống",
            "fy54": "Mật khẩu cũ không được để trống",
            "fy55": "Thay Đổi Mật Khẩu",
            "fy56": "Tài Khoản Của Tôi",
            "fy57": "Mật Khẩu Cũ",
            "fy58": "Vui lòng nhập mật khẩu cũ",
            "fy59": "Mật Khẩu Mới",
            "fy60": "Vui lòng nhập mật khẩu mới",
            "fy61": "Lặp Lại Mật Khẩu Mới",
            "fy62": "Vui lòng lặp lại mật khẩu",
            "fy63": "Cập Nhật Mật Khẩu",
            "fy64": "Đặt Mật Khẩu",
            "fy65": "Xác Nhận Mật Khẩu",
            "fy66": "Vui lòng xác nhận mật khẩu",
            "fy67": "Vui lòng nhập mật khẩu 6 chữ số",
            "fy68": "Vui lòng nhập đúng mật khẩu 6 chữ số",
            "fy69": "Mật Khẩu",
            "fy70": "Số Tiền Nạp",
            "fy71": "Vui lòng nhập số tiền nạp",
            "fy72": "Gợi Ý Ấm Áp",
            "fy73": "Là một nền tảng thương mại điện tử cho phép bạn mua các mặt hàng bạn biết trực tuyến trong khi khám phá các cửa hàng và người bán mới, tìm kiếm các nhu yếu phẩm hàng ngày trong các cửa hàng hoặc duyệt qua một loạt các mặt hàng được lựa chọn trong các danh mục như Sức khỏe&Sắc đẹp, Hàng điện tử, Thời trang, Nhà&Cuộc sống, Trẻ sơ sinh&Đồ chơi và nhiều hơn nữa.",
            "fy74": "Số Tiền Nạp",
            "fy75": "Địa Chỉ Usdt",
            "fy76": "Sao Chép",
            "fy77": "Vui lòng liên hệ với thư ký phụ trách kinh doanh",
            "fy78": "Vui lòng tải lên phiếu thanh toán",
            "fy79": "Giới Thiệu",
            "fy80": "Vui lòng nhập số tiền",
            "fy81": "Không có kênh thanh toán",
            "fy82": "File Quá Lớn",
            "fy83": "Chi Tiết Tiền Gửi",
            "fy84": "Thời Gian",
            "fy85": "Loại",
            "fy86": "Số Lượng",
            "fy87": "Trạng Thái",
            "fy88": "Đang Được Xem Xét",
            "fy89": "Thông Qua",
            "fy90": "Từ Chối",
            "fy91": "Mua Sắm Là Gì",
            "fy92": "Là một nền tảng thương mại điện tử cho phép bạn mua các thương hiệu bạn đã biết trực tuyến trong khi khám phá các cửa hàng và người bán mới.",
            "fy93": "Tìm kiếm các mặt hàng thiết yếu hàng ngày trong cửa hàng, hoặc duyệt qua rất nhiều mặt hàng được lựa chọn trong các loại như chăm sóc sức khỏe, mỹ phẩm, điện tử, thời trang, gia đình và cuộc sống, trẻ sơ sinh và đồ chơi.",
            "fy94": "Bạn cũng có thể theo dõi các chương trình khuyến mãi vô tận trên nền tảng của chúng tôi. Bắt giao dịch flash hàng ngày, chơi trò chơi để giành chiến thắng hàng hóa hoặc xem các cuộc họp trực tiếp với các ưu đãi độc quyền trực tiếp.",
            "fy95": "Khi bạn đã sẵn sàng mua hàng, hãy đảm bảo rằng nền tảng cho phép bạn mua hàng trong một đường ống an toàn và liền mạch, nơi bạn có thể duyệt qua xếp hạng và đánh giá hàng hóa, thanh toán không rủi ro với Guarantee và dễ dàng theo dõi việc giao hàng của bạn.",
            "fy96": "Nếu bạn muốn biết thêm thông tin, vui lòng duyệt bài viết Trung tâm trợ giúp mua sắm cuối cùng của chúng tôi để bắt đầu hoặc bạn có thể kết thúc một chương trình duyệt nhanh với video này.",
            "fy97": "Lệnh Của Tôi",
            "fy98": "Số Thứ Tự",
            "fy99": "Thời Gian Mua Hàng",
            "fy100": "Thanh Toán Thực",
            "fy101": "Chi Tiết",
            "fy102": "Hoàn Tiền",
            "fy103": "Liên Hệ Người Bán",
            "fy104": "Tất Cả",
            "fy105": "Thanh toán cho người mua",
            "fy106": "Chờ Vận Chuyển",
            "fy107": "Đã Gửi",
            "fy108": "Đang Phân Phối",
            "fy109": "Người Mua Nhận Hàng",
            "fy110": "Đang Trả Lại",
            "fy111": "Đã Trả Lại",
            "fy112": "Nhà cung cấp ứng dụng",
            "fy113": "Điện Thoại",
            "fy114": "Hộp Thư",
            "fy115": "Vui lòng nhập hộp thư",
            "fy116": "Vui lòng nhập số điện thoại",
            "fy117": "Mã Xác Nhận",
            "fy118": "Vui lòng nhập CAPTCHA",
            "fy119": "Gửi Từ Mới",
            "fy120": "Nhận Captcha",
            "fy121": "Mật Khẩu",
            "fy122": "Vui lòng nhập mật khẩu",
            "fy123": "Mã Giới Thiệu",
            "fy124": "Vui lòng nhập mã giới thiệu",
            "fy125": "Danh mục nhà cung cấp",
            "fy126": "Vui lòng chọn loại Merchant",
            "fy127": "Tên Cửa Hàng",
            "fy128": "Vui lòng nhập tên cửa hàng",
            "fy129": "Cửa Hàng Logo",
            "fy130": "Vui lòng tải lên logo cửa hàng",
            "fy131": "ID, bằng lái xe, hộ chiếu",
            "fy132": "Vui lòng tải lên ID, bằng lái xe, hộ chiếu",
            "fy133": "Giới Thiệu",
            "fy134": "Vui Lòng Chọn Loại",
            "fy135": "Xác minh điện thoại không thành công",
            "fy136": "Xác minh hộp thư thất bại",
            "fy137": "Vui lòng chọn loại Merchant"
        }
    },
    "seller": {
        "layout": {
            "fy1": "Trang Chủ",
            "fy2": "Công Cụ Cơ Bản",
            "fy3": "Hỗ Trợ Trực Tuyến",
            "fy4": "Thông Điệp Hệ Thống",
            "fy5": "Trung Tâm Cá Nhân"
        },
        "home": {
            "fy1": "Dữ Liệu Doanh Nghiệp",
            "fy2": "Tổng Số Đơn Đặt Hàng",
            "fy3": "Tổng Lợi Nhuận",
            "fy4": "Tổng khối lượng bán hàng",
            "fy5": "Lệnh Thành Công",
            "fy6": "Tổng khối lượng giao dịch",
            "fy7": "Tổng số lượt truy cập",
            "fy8": "Ngày",
            "fy9": "Tuần Này",
            "fy10": "Tháng Này",
            "fy11": "Số Đơn Đặt Hàng",
            "fy12": "Số Lượng Lệnh Chốt",
            "fy13": "Số Đơn Đặt Hàng",
            "fy14": "Số Lượng Lệnh Chốt",
            "fy15": "Đăng Nhập",
            "fy16": "Tập Trung",
            "fy17": "Số Lượng Truy Cập",
            "fy18": "Số Dư Tài Khoản",
            "fy19": "Dịch Vụ Đặt Hàng",
            "fy20": "Đặt Hàng",
            "fy21": "Lệnh Chờ Xử Lý",
            "fy22": "Đặt hàng trong khi vận chuyển",
            "fy23": "Đơn hàng trả lại/hoàn tiền",
            "fy24": "Lệnh Được Đánh Giá",
            "fy25": "Dữ Liệu Doanh Nghiệp",
            "fy26": "Dữ Liệu Doanh Nghiệp",
            "fy27": "Lệnh Của Tôi",
            "fy28": "Số Thứ Tự",
            "fy29": "Thời Gian Mua Hàng",
            "fy30": "Thanh Toán Thực",
            "fy31": "Chi Tiết",
            "fy32": "Xác Nhận",
            "fy33": "Tất Cả",
            "fy34": "Thanh toán cho người mua",
            "fy35": "Chờ Vận Chuyển",
            "fy36": "Đã Gửi",
            "fy37": "Đang Phân Phối",
            "fy38": "Người Mua Nhận Hàng",
            "fy39": "Đang Trả Lại",
            "fy40": "Đã Trả Lại",
            "fy41": "Thanh toán cho người mua",
            "fy42": "Chờ Vận Chuyển",
            "fy43": "Đã Gửi",
            "fy44": "Đang Phân Phối",
            "fy45": "Người Mua Nhận Hàng",
            "fy46": "Đang Trả Lại",
            "fy47": "Đã Trả Lại",
            "fy48": "Chi Tiết Đặt Hàng",
            "fy49": "Số Thứ Tự",
            "fy50": "Sao Chép",
            "fy51": "Đặt Hàng Thời Gian",
            "fy52": "Số Lượng Sản Phẩm",
            "fy53": "Đơn Giá Hàng Hóa",
            "fy54": "Thanh Toán Thực",
            "fy55": "Xác Định"
        },
        "tool": {
            "fy1": "Đăng Nhập",
            "fy2": "Tập Trung",
            "fy3": "Số Lượng Truy Cập",
            "fy4": "Công Cụ Cơ Bản",
            "fy5": "Trung Tâm Bán Buôn",
            "fy6": "Quản Lý Hàng Hóa",
            "fy7": "Quản Lý Đơn Hàng",
            "fy8": "Ký Quỹ",
            "fy9": "Quản Lý Đánh Giá",
            "fy10": "Nâng Cấp Kinh Doanh",
            "fy11": "Lên Nóng",
            "fy12": "Hỗ Trợ Trực Tuyến",
            "fy13": "Đang kiểm tra cửa hàng",
            "fy14": "Lên Nóng",
            "fy15": "Đặt Hàng",
            "fy16": "Đẩy Định Hướng",
            "fy17": "Đẩy Nhanh",
            "fy18": "Sẽ được giới thiệu trong Hot",
            "fy19": "Ngày",
            "fy20": "Cửa Hàng Mới.",
            "fy21": "Sẽ Trong Tương Lai",
            "fy22": "Giới Thiệu Cửa Hàng",
            "fy23": "Chi Phí Cần Thiết",
            "fy24": "Đã đọc và sử dụng Thỏa thuận dịch vụ",
            "fy25": "Xác Định Thanh Toán",
            "fy26": "Số dư không đủ, xin nạp trước.",
            "fy27": "Giới thiệu cửa hàng cho khách hàng tiềm năng",
            "fy28": "Đề xuất thông minh hệ thống",
            "fy29": "Đẩy định hướng tùy chỉnh",
            "fy30": "Mã Đẩy Của Tôi",
            "fy31": "Sao Chép",
            "fy32": "Cấu hình đẩy tùy chỉnh",
            "fy33": "Nhập vào sơ đồ khớp tự động theo mã đẩy",
            "fy34": "Giới Tính (radio)",
            "fy35": "Tuổi (nhiều lựa chọn)",
            "fy36": "Thể loại sở thích (radio)",
            "fy37": "Khu Vực Quốc Gia",
            "fy38": "Xác Định",
            "fy39": "Không Giới Hạn",
            "fy40": "Nam",
            "fy41": "Phụ Nữ",
            "fy42": "Chi Tiết Dữ Liệu",
            "fy43": "Truy Cập Mới",
            "fy44": "Số Lượng Fan Mới",
            "fy45": "Số lượng giao dịch mới",
            "fy46": "Số Tiền Giao Dịch",
            "fy47": "Thêm Đơn Đặt Hàng",
            "fy48": "Tỷ Lệ",
            "fy49": "Hôm Nay",
            "fy50": "Trạng Thái Thả",
            "fy51": "Số Thứ Tự",
            "fy52": "Thời Gian Mua Hàng",
            "fy53": "Chu Kỳ Mua",
            "fy54": "Số Tiền Mua",
            "fy55": "Loại Mua",
            "fy56": "Đẩy Định Hướng",
            "fy57": "Đẩy Nhanh",
            "fy58": "Chi Tiết Dữ Liệu",
            "fy59": "Đặt Hàng",
            "fy60": "Đang Thả",
            "fy61": "Kết Thúc Thả",
            "fy62": "Trung Tâm Bán Buôn",
            "fy63": "Sản Phẩm Đã Chọn",
            "fy64": "Mã Sản Phẩm",
            "fy65": "Vui lòng nhập mã sản phẩm",
            "fy66": "Tìm Kiếm",
            "fy67": "Giá Tối Thiểu",
            "fy68": "Giá Tối Đa",
            "fy69": "Tìm Kiếm Sản Phẩm",
            "fy70": "Bán Chạy Nhất",
            "fy71": "Trước Khi Bán",
            "fy72": "Bảng xếp hạng lợi nhuận",
            "fy73": "Trước Lợi Nhuận",
            "fy74": "Bảng Yêu Cầu",
            "fy75": "Trước Khi Yêu Cầu",
            "fy76": "Lên Kệ Nhanh",
            "fy77": "Hướng dẫn sử dụng Rack",
            "fy78": "Lựa Chọn Chiến Lược",
            "fy79": "Vui lòng chọn sản phẩm",
            "fy80": "Lên Kệ Thành Công",
            "fy81": "Chọn",
            "fy82": "Chọn loại sản phẩm (nhiều lựa chọn)",
            "fy83": "Vui lòng chọn loại sản phẩm",
            "fy84": "Vui lòng nhập số tiền",
            "fy85": "Vui lòng nhập số lượng sản phẩm",
            "fy86": "Khớp",
            "fy87": "Đề xuất khớp hệ thống",
            "fy88": "Đồng Khớp",
            "fy89": "Sản Phẩm",
            "fy90": "Khớp Lại",
            "fy91": "Thanh toán và sử dụng",
            "fy92": "Vui Lòng Nhập",
            "fy93": "Số",
            "fy94": "Vui lòng chọn loại sản phẩm",
            "fy95": "Vui lòng nhập số lượng sản phẩm",
            "fy96": "Vui lòng nhập số tiền",
            "fy97": "Tìm Kiếm Sản Phẩm",
            "fy98": "Mã Sản Phẩm",
            "fy99": "Sao Chép",
            "fy100": "Giá Mua",
            "fy101": "Giá Khuyến Mãi",
            "fy102": "Hàng Hóa Dưới Kệ",
            "fy103": "Ký Quỹ",
            "fy104": "Giới Thiệu",
            "fy105": "Tất Cả Sản Phẩm",
            "fy106": "Số Lượng Bình Luận",
            "fy107": "Hoạt Động",
            "fy108": "Xem Chi Tiết",
            "fy109": "Sản phẩm này tổng cộng",
            "fy110": "Bình Luận"
        },
        "sys": {
            "fy1": "Thông Điệp Hệ Thống",
            "fy2": "Thu Gọn",
            "fy3": "Chi Tiết",
            "fy4": "Gửi Hàng"
        },
        "myCenter": {
            "fy1": "Đăng Nhập",
            "fy2": "Trung Tâm Cá Nhân",
            "fy3": "Ủy Thác Của Tôi",
            "fy4": "Thư Mời Của Tôi",
            "fy5": "Ví Của Tôi",
            "fy6": "Gạch Ngang",
            "fy7": "Tiền Gửi",
            "fy8": "Rút tiền",
            "fy9": "Chi Tiết Hóa Đơn",
            "fy10": "Ràng Buộc",
            "fy11": "Dịch Vụ Chức Năng",
            "fy12": "Thay Đổi Mật Khẩu",
            "fy13": "Thay đổi mật khẩu thanh toán",
            "fy14": "Đặt mật khẩu thanh toán",
            "fy15": "Thông Tin Cửa Hàng",
            "fy16": "Tập Trung",
            "fy17": "Số Lượng Truy Cập",
            "fy18": "Số Dư Khuyến Mãi",
            "fy19": "Số Dư Của Tôi",
            "fy20": "Ủy Thác Của Tôi",
            "fy21": "Người giúp việc của tôi.",
            "fy22": "Cửa Hàng Được Mời",
            "fy23": "Mã Mời",
            "fy24": "Mời Kết Nối",
            "fy25": "Mã Mời",
            "fy26": "Số Lượng Lớp 1",
            "fy27": "Số Lượng Lớp Ii",
            "fy28": "Số Lượng Cấp Iii",
            "fy29": "Thư Mời Của Tôi",
            "fy30": "Người dùng mời của tôi",
            "fy31": "Hồ Sơ Mời/thu Nhập",
            "fy32": "Số Dư",
            "fy33": "Số Dư Khuyến Mãi",
            "fy34": "Vui Lòng Nhập",
            "fy35": "Chuyển Đổi Tối Đa",
            "fy36": "Giới Thiệu",
            "fy37": "Nạp Số Dư Của Tôi",
            "fy38": "Nhập Tối Đa",
            "fy39": "Vui lòng nhập số tiền chuyển khoản",
            "fy40": "Chuyển Thành Công",
            "fy41": "Số Tiền Nạp",
            "fy42": "Vui lòng nhập số tiền nạp",
            "fy43": "Gợi Ý Ấm Áp",
            "fy44": "Là một nền tảng thương mại điện tử cho phép bạn mua các mặt hàng bạn biết trực tuyến trong khi khám phá các cửa hàng và người bán mới, tìm kiếm các nhu yếu phẩm hàng ngày trong các cửa hàng hoặc duyệt qua một loạt các mặt hàng được lựa chọn trong các danh mục như Sức khỏe&Sắc đẹp, Hàng điện tử, Thời trang, Nhà&Cuộc sống, Trẻ sơ sinh&Đồ chơi và nhiều hơn nữa.",
            "fy45": "Số Tiền Nạp",
            "fy46": "Địa Chỉ",
            "fy47": "Sao Chép",
            "fy48": "Vui lòng liên hệ với dịch vụ khách hàng để được tư vấn",
            "fy49": "Vui lòng tải lên phiếu thanh toán",
            "fy50": "Giới Thiệu",
            "fy51": "Vui lòng nhập số tiền",
            "fy52": "Không có kênh thanh toán",
            "fy53": "Tải Lên Nhiều Nhất",
            "fy54": "Cách Rút Tiền Mặt",
            "fy55": "Vui lòng chọn phương thức rút tiền",
            "fy56": "Giá Dịch Vụ",
            "fy57": "Số Tiền Rút Ra",
            "fy58": "Vui lòng nhập số tiền",
            "fy59": "Phí Dịch Vụ",
            "fy60": "Hoạt Động",
            "fy61": "Có Thể Cân Bằng",
            "fy62": "Rút tiền Địa Chỉ",
            "fy63": "Rút tiền",
            "fy64": "Mật Khẩu Thanh Toán",
            "fy65": "Vui lòng nhập mật khẩu thanh toán",
            "fy66": "Đi Và Trả Tiền",
            "fy67": "Vui lòng nhập số tiền",
            "fy68": "Vui lòng thêm phương thức rút tiền",
            "fy69": "Chi Tiết Hóa Đơn",
            "fy70": "Thời Gian",
            "fy71": "Số Thứ Tự",
            "fy72": "Số Lượng",
            "fy73": "Ghi Chú",
            "fy74": "Ràng Buộc",
            "fy75": "Vui lòng chọn Thỏa thuận",
            "fy76": "Địa Chỉ Ví",
            "fy77": "Vui Lòng Nhập",
            "fy78": "Lưu",
            "fy79": "Thành Công Mới",
            "fy80": "Sửa Đổi",
            "fy81": "Thêm",
            "fy82": "Địa Chỉ",
            "fy83": "Vui lòng chọn Mainnet",
            "fy84": "Vui lòng chọn Thỏa thuận",
            "fy85": "Địa Chỉ Ví",
            "fy86": "Vui Lòng Nhập",
            "fy87": "Lưu",
            "fy88": "Xoá",
            "fy89": "Sửa Đổi Thành Công",
            "fy90": "Thành Công Mới",
            "fy91": "Xóa Thành Công",
            "fy92": "Cập nhật thông tin cửa hàng",
            "fy93": "Vui lòng nhập tên cửa hàng",
            "fy94": "Lưu",
            "fy95": "Tải Lên Nhiều Nhất",
            "fy96": "Vui lòng nhập tên cửa hàng"
        },
        size: {
            fy1: 'Kích thước'
        }
    }
}
