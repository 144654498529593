export default {
    size:'Größe',
    color:'Farbe',
    dongjies:'Dieses Konto wurde gesperrt, bitte wenden Sie sich an den Kundendienst',
    dongjie:'Gelder wurden eingefroren, bitte wenden Sie sich an den Kundendienst',
    "mg": {
        "fy1": "Zinsschatz",
        "fy2": "Gutschein",
        "fy3": "Mobiles Aufladen",
        "fy4": "Einladungscode",
        "fy5": "In Gewahrsam",
        "fy6": "Tag",
        "fy7": "Tagesertrag",
        "fy8": "Mindesteinkauf",
        "fy9": "Sofortabonnement",
        "fy10": "Verfügbarer Saldo",
        "fy11": "Bitte geben Sie den Kaufbetrag ein",
        "fy12": "Ganz",
        "fy13": "Dauer",
        "fy14": "Tagesertrag",
        "fy15": "Auszahlungszeit der Dividende",
        "fy16": "Jeden Tag",
        "fy17": "Treuhandfonds",
        "fy18": "Fällige Rückgabe",
        "fy19": "Mindesteinkauf",
        "fy20": "Erwartete Einnahmen",
        "fy21": "Sofortabonnement",
        "fy22": "Mindesteinkauf",
        "fy23": "Unzureichendes Gleichgewicht, laden Sie bitte auf und versuchen Sie es erneut",
        "fy24": "Auftragsdatensatz",
        "fy25": "Alle Bestellungen",
        "fy26": "In Bearbeitung",
        "fy27": "Abgeschlossen",
        "fy28": "Restzeit",
        "fy29": "In Bearbeitung",
        "fy30": "Abgeschlossen",
        "fy31": "Bestellnummer",
        "fy32": "Bezugsbetrag",
        "fy33": "Tagesgewinn",
        "fy34": "Dauer",
        "fy35": "Erwartete Einnahmen",
        "fy36": "Profitabel",
        "fy37": "Kaufdatum",
        "fy38": "Einlösung",
        "fy39": "Einlösung Bestätigen",
        "fy40": "Einlösung Abbrechen",
        "fy41": "Sind Sie sicher, dass Sie die Depotfonds einlösen",
        "fy42": "Hinweis: Sobald die Depotfonds eingelöst sind, werden die Zinsen verrechnet",
        "fy43": "Mobiles Aufladen",
        "fy44": "Aufladungsaufzeichnung",
        "fy45": "Bitte geben Sie Ihre Telefonnummer ein",
        "fy46": "Aufladungsbetrag",
        "fy47": "Nicht Ausgewählt",
        "fy48": "Bitte beachten Sie, dass Sie nach erfolgreichem Aufladen auf die Zahlung der Telefonrechnung achten.",
        "fy49": "Jetzt Aufladen",
        "fy50": "Bitte geben Sie Ihre Telefonnummer ein",
        "fy51": "Gehe Zu Kommentar",
        "fy52": "Überprüft",
        "fy53": "Einen Kommentar schreiben",
        "fy54": "Produktbewertung",
        "fy55": "Einen Kommentar schreiben",
        "fy56": "Hinterlassen Sie Ihre wertvollen Kommentare",
        "fy57": "Sonnenbrunnen",
        "fy58": "Absenden",
        "fy59": "Sehr Arm",
        "fy60": "Sehr Arm",
        "fy61": "Häufig",
        "fy62": "Sehr Gut",
        "fy63": "Sehr Gut",
        "fy64": "Aufladungsaufzeichnung",
        "fy65": "Erfolgreich aufgeladen",
        "fy66": "Einladungscode",
        "fy67": "Bitte geben Sie den Einladungscode ein",
        "fy68": "Gesamtergebnis",
        "fy69": "Der Heutige Gewinn",
        "fy70": "Unvollständig",
        "fy71": "Der aktuelle Gutschein hat das Kauflimit erreicht",
        "fy72": "Gutscheinschwelle nicht erreicht",
        "fy73": "Benutzeraufladung",
        "fy74": "Diese Zahlung",
        "fy75": "Bitte geben Sie die Menge ein",
        "fy76": "Schnelle Zahlung",
        "fy77": "Jetzt Aufladen",
        "fy78": "Bitte wählen Sie Ihr Payment Wallet",
        "fy79": "Aktuell",
        "fy80": "Wellenfeldkette",
        "fy81": "Ethereum-kette",
        "fy82": "Münzsicherheitskette",
        "fy83": "Europäische italienische Kette",
        "fy84": "Ouyi",
        "fy85": "Kette",
        "fy86": "Kanal",
        "fy87": "Bevorzugt"
    },
    "newadd": { "fy1": "Name des Karteninhabers", "fy2": "Bitte geben Sie den Namen des Karteninhabers ein", "fy3": "Bankkartennummer", "fy4": "Bitte geben Sie Ihre Bankkartennummer ein", "fy5": "Name Der Bank", "fy6": "Bitte geben Sie den Banknamen ein", "fy7": "Bankleitzahl", "fy8": "Bitte geben Sie Ihre Bankleitzahl ein", "fy9": "E-commerce Lieferung", "fy10": "Vereinfachen Sie Ihren Lieferprozess und bieten Sie Ihren Kunden schnelle und bequeme Lieferoptionen. Unsere Plattform sorgt dafür, dass Ihre Artikel jederzeit sicher und pünktlich geliefert werden.", "fy11": "Echtzeit-tracking", "fy12": "Halten Sie Ihre Kunden bei jedem Schritt über Echtzeit-Tracking-Updates auf dem Laufenden. Unsere Plattform bietet Ihnen die notwendige Werkzeugerfahrung für eine erstklassige Lieferung.", "fy13": "Skalierbarkeit", "fy14": "Wenn Ihr Unternehmen wächst, wird auch unsere Plattform entsprechend wachsen. Unser Liefernetzwerk verfolgt Ihr Geschäft und stellt sicher, dass Sie jede Erhöhung des Liefervolumens bewältigen können." },
    "components": {
        "fy1": "Stück",
        "fy2": "Zahlung",
        "fy3": "Passwort Vergessen",
        "fy4": "Sofortige Abwicklung",
        "fy5": "Insgesamt",
        "fy6": "Ware",
        "fy7": "Wählen Sie die Produkte aus, die Ihnen gefallen",
        "fy8": "Bitte geben Sie zuerst eine Lieferadresse an",
        "fy9": "Bitte geben Sie zuerst das Zahlungspasswort ein",
        "fy10": "Bitte geben Sie das Passwort ein",
        "fy11": "Zu Zahlen",
        "fy12": "Bitte geben Sie das Zahlungspasswort ein",
        "fy13": "Passwort aktualisieren",
        "fy14": "Passwort Festlegen",
        "fy15": "Altes Passwort",
        "fy16": "Passwort",
        "fy17": "Bitte Geben Sie Ein",
        "fy18": "Neues Passwort",
        "fy19": "Passwort Bestätigen",
        "fy20": "Bitte geben Sie ein neues Passwort ein",
        "fy21": "Bitte bestätigen Sie das Passwort",
        "fy22": "Konservieren",
        "fy23": "Bitte geben Sie ein 6-stelliges Passwort ein",
        "fy24": "Bitte geben Sie das richtige 6-stellige Passwort ein",
        "fy25": "Die zweimal eingegebenen Passwörter stimmen nicht überein",
        "fy26": "News",
        "fy27": "Senden",
        "fy28": "Bildhochladen fehlgeschlagen",
        "fy29": "Bitte Attribute eingeben",
        "fy30": "Bestätigen",
        "fy31": "Mehr Laden",
        "fy32": "Käufer",
        "fy33": "Verkäufer",
        "fy34": "Postfach",
        "fy35": "Telefon",
        "fy36": "Passwort",
        "fy37": "Passwort Vergessen",
        "fy38": "Registrieren",
        "fy39": "Login",
        "fy40": "Melden Sie sich mit dem Telefon an",
        "fy41": "Melden Sie sich per E-Mail an",
        "fy42": "Hilfe",
        "fy43": "Bitte geben Sie eine Telefonnummer ein",
        "fy44": "Bitte geben Sie eine E-Mail-Adresse ein",
        "fy45": "E-mail-überprüfung",
        "fy46": "Mobile Verifizierung",
        "fy47": "Bitte geben Sie den Bestätigungscode ein",
        "fy48": "Mehr Laden",
        "fy49": "Mehr Laden",
        "fy50": "Wählen Sie Ihr Geschlecht",
        "fy51": "Jungs",
        "fy52": "Mädchen",
        "fy53": "Nächster Schritt",
        "fy54": "Wählen Sie Ihr Alter",
        "fy55": "Wählen Sie Ihre Interessen",
        "fy56": "Folgen Sie mindestens vier Interessen",
        "fy57": "Erkunden Sie",
        "fy58": "Wischen Sie nach oben, um das Alter auszuwählen",
        "fy59": "Bestätigungscode senden",
        "fy60": "Fehler im Verifizierungscode",
        "fy61": "Jahr",
        "fy62": "Versuchen Sie es in Sekunden erneut",
        "fy63": "Erfolgreich gesendeter Verifizierungscode",
        "fy64": "Zurücksetzen erfolgreich, bitte einloggen",
        "fy65": "Sind Sie sicher, das Girokonto zu eröffnen?",
        "fy66": "Abmelden Vom Login",
        "fy67": "Sponsor",
        "fy68": "Monatsverkäufe",
        "fy69": "Derzeit liegen keine Daten vor",
        "fy70": "Sofortige Zahlung",
        "fy71": "Brieftasche",
        "fy72": "Zu Zahlen",
        "fy73": "Zahlung Bestätigen",
        "fy74": "Ausweis, Führerschein, Reisepass",
        "fy75": "Bitte laden Sie zuerst Ihre ID hoch",
        "fy76": "Bewerbung erfolgreich, bitte warten Sie auf Überprüfung",
        "fy77": "Bitte Wählen"
    },
    "buyer": {
        "withdrawList": {
            "fy1": "Details Rücknahme",
            "fy2": "Zeit",
            "fy3": "Typ",
            "fy4": "Geld",
            "fy5": "Zustand",
            "fy6": "Ausstehende Überprüfung",
            "fy7": "Verabschieden",
            "fy8": "Ablehnen"
        },
        "withdraw": {
            "fy1": "Verkörperungsmethode",
            "fy2": "Nichts",
            "fy3": "Servicerate",
            "fy4": "Auszahlungsbetrag",
            "fy5": "Bitte geben Sie den Betrag ein",
            "fy6": "Servicegebühr",
            "fy7": "Aktivität",
            "fy8": "Adresse Rücknahme",
            "fy9": "Rücknahme",
            "fy10": "Zahlungspasswort",
            "fy11": "Bitte geben Sie das Zahlungspasswort ein",
            "fy12": "Bestätigen",
            "fy13": "Bitte geben Sie zuerst das Zahlungspasswort ein",
            "fy14": "Bitte geben Sie den Betrag ein",
            "fy15": "Bitte geben Sie das Passwort ein",
            "fy16": "Bitte fügen Sie Auszahlungsmethode hinzu",
            "fy17": "Verfügbarer Saldo"
        },
        "shippingAddress": {
            "fy1": "Lieferadresse"
        },
        "selected": {
            "fy1": "Ausgewählte Stores",
            "fy2": "Suche",
            "fy3": "Folgen",
            "fy4": "Besuche",
            "fy5": "Ganz"
        },
        "orderInfo": {
            "fy1": "Bestelldetails",
            "fy2": "Bestellnummer",
            "fy3": "Bestellzeit",
            "fy4": "Produktmenge",
            "fy5": "Artikelpreise",
            "fy6": "Tatsächliche Zahlung",
            "fy7": "Bestätigen"
        },
        "layout": {
            "fy1": "Über Uns",
            "fy2": "Zurück Nach Oben",
            "fy3": "Hilfedienste",
            "fy4": "Datenschutzerklärung",
            "fy5": "Eröffnung eines Ladens",
            "fy6": "Kostenlos",
            "fy7": "Sofort ein Geschäft eröffnen",
            "fy8": "Ware",
            "fy9": "Shop",
            "fy10": "Suche",
            "fy11": "Abbrechen",
            "fy12": "Login",
            "fy13": "Mehr",
            "fy14": "Ausgewählte Stores",
            "fy15": "Bitte geben Sie mindestens drei Zeichen für die Suche ein",
            "fy16": "Suchverlauf",
            "fy17": "Folgen",
            "fy18": "Besuche",
            "fy19": "Alle Anzeigen"
        },
        "category": {
            "fy1": "Ausgewählte Empfehlungen",
            "fy2": "Ausgewählte Stores",
            "fy3": "Ausgewählt"
        },
        "goodsDetail": {
            "fy1": "Menge",
            "fy2": "Verkaufsvolumen",
            "fy3": "In Den Warenkorb",
            "fy4": "Dringende Verbringung",
            "fy5": "Kostenloser Versand/Rückgabe",
            "fy6": "Produktbeschreibung",
            "fy7": "Produktbewertung",
            "fy8": "Stern",
            "fy9": "Derzeit keine Bewertung verfügbar",
            "fy10": "Dieses Produkt ist vorübergehend nicht verfügbar."
        },
        "home": {
            "fy1": "Neueste Rabatte",
            "fy2": "Gute Produktempfehlung",
            "fy3": "Kaufen One Get One Free",
            "fy4": "Jetzt Kaufen",
            "fy5": "Zeitlich begrenztes Sonderangebot",
            "fy6": "Countdown",
            "fy7": "Beliebte Produkte",
            "fy8": "Kostenloser Versand",
            "fy9": "Alle Produkte",
            "fy10": "Beliebte Geschäfte",
            "fy11": "Beliebte Geschäfte ansehen"
        },
        "myHeart": {
            "fy1": "Folgen",
            "fy2": "Alle Anzeigen",
            "fy3": "Verfügbares Guthaben der Geldbörse"
        },
        "shopDetail": {
            "fy1": "Folgen",
            "fy2": "Besuche",
            "fy3": "Umfassend",
            "fy4": "Zeit",
            "fy5": "Verkaufsvolumen",
            "fy6": "Preis",
            "fy7": "Alle Anzeigen"
        },
        "personal": {
            "fy1": "Abmelden Vom Login",
            "fy2": "Persönliches Zentrum",
            "fy3": "Grundlegende Informationen",
            "fy4": "Meine Brieftasche",
            "fy5": "Kaution",
            "fy6": "Rücknahme",
            "fy7": "Rechnungsdetails",
            "fy8": "Einzahlungsprotokoll",
            "fy9": "Auszahlungsprotokoll",
            "fy10": "Usdt Binden",
            "fy11": "Bestellcenter",
            "fy12": "Meine Bestellung",
            "fy13": "Lieferadresse",
            "fy14": "Funktionelle Dienstleistungen",
            "fy15": "Ausgewählte Geschäfte",
            "fy16": "Beantragung von Lieferanten",
            "fy17": "Passwort Ändern",
            "fy18": "Zahlungspasswort ändern",
            "fy19": "Zahlungspasswort festlegen",
            "fy20": "Kontaktieren Sie den Kundenservice",
            "fy21": "Über Uns",
            "fy22": "Hilfedienste",
            "fy23": "Modifizieren",
            "fy24": "Hinzufügen",
            "fy25": "Adresse",
            "fy26": "Name",
            "fy27": "Bitte Geben Sie Ein",
            "fy28": "Kontaktinformationen",
            "fy29": "Konservieren",
            "fy30": "Löschen",
            "fy31": "Erfolgreich Geändert",
            "fy32": "Erfolgreich hinzugefügt",
            "fy33": "Standardadresse kann nicht gelöscht werden",
            "fy34": "Erfolgreich Gelöscht",
            "fy35": "Bitte wählen Sie das Hauptnetz aus",
            "fy36": "Bitte wählen Sie ein Protokoll",
            "fy37": "Wallet-adresse",
            "fy38": "Bitte wählen Sie eine USDT Wallet Adresse",
            "fy39": "Ordentliche Mitglieder",
            "fy40": "Mein Gleichgewicht",
            "fy41": "Suche",
            "fy42": "Meine Aufmerksamkeit",
            "fy43": "Folgen",
            "fy44": "Besuche",
            "fy45": "Ganz",
            "fy46": "Rechnungsdetails",
            "fy47": "Zeit",
            "fy48": "Bestellnummer",
            "fy49": "Geld",
            "fy50": "Bemerkungen",
            "fy51": "Passwort erfolgreich geändert, bitte loggen Sie sich ein",
            "fy52": "Die beiden Passwörter stimmen nicht überein",
            "fy53": "Das neue Passwort kann nicht leer sein",
            "fy54": "Altes Passwort kann nicht leer sein",
            "fy55": "Passwort Ändern",
            "fy56": "Mein Konto",
            "fy57": "Altes Passwort",
            "fy58": "Bitte geben Sie Ihr altes Passwort ein",
            "fy59": "Neues Passwort",
            "fy60": "Bitte geben Sie ein neues Passwort ein",
            "fy61": "Neues Passwort wiederholen",
            "fy62": "Bitte wiederholen Sie das Passwort",
            "fy63": "Passwort aktualisieren",
            "fy64": "Passwort Festlegen",
            "fy65": "Passwort Bestätigen",
            "fy66": "Bitte bestätigen Sie das Passwort",
            "fy67": "Bitte geben Sie ein 6-stelliges Passwort ein",
            "fy68": "Bitte geben Sie das richtige 6-stellige Passwort ein",
            "fy69": "Passwort",
            "fy70": "Einzahlungsbetrag",
            "fy71": "Bitte geben Sie den Anzahlungsbetrag ein",
            "fy72": "Liebevolle Erinnerung",
            "fy73": "Es ist eine E-Commerce-Plattform, die es Ihnen ermöglicht, bekannte Produkte online zu kaufen, neue Geschäfte und Verkäufer zu entdecken, nach den täglichen Notwendigkeiten in Geschäften zu suchen oder eine große Auswahl an Produkten in Kategorien wie Gesundheit und Schönheit, Elektronik, Mode, Haus und Leben, Baby und Spielzeug usw. zu durchsuchen.",
            "fy74": "Aufladungsbetrag",
            "fy75": "Usdt-adresse",
            "fy76": "Kopie",
            "fy77": "UnionPay aufladen, wenden Sie sich bitte an den Kundendienst Sekretär, um das Geschäft abzuwickeln",
            "fy78": "Bitte laden Sie den Zahlungsbeleg hoch",
            "fy79": "Absenden",
            "fy80": "Bitte geben Sie den Betrag ein",
            "fy81": "Derzeit ist kein Zahlungskanal verfügbar",
            "fy82": "Datei Zu Groß",
            "fy83": "Details Zur Einlage",
            "fy84": "Zeit",
            "fy85": "Typ",
            "fy86": "Geld",
            "fy87": "Zustand",
            "fy88": "Ausstehende Überprüfung",
            "fy89": "Verabschieden",
            "fy90": "Ablehnen",
            "fy91": "Was Ist Shopping?",
            "fy92": "Es ist eine E-Commerce-Plattform, mit der Sie Marken kaufen können, die Sie bereits online kennen, während Sie neue Geschäfte und Verkäufer entdecken.",
            "fy93": "Suchen Sie im Geschäft nach den täglichen Essentials oder stöbern Sie durch eine große Auswahl an Produkten in Kategorien wie Gesundheit und Schönheit, Elektronik, Mode, Zuhause und Leben, Babys und Spielzeug.",
            "fy94": "Sie können auch auf die endlosen Werbeaktivitäten auf unserer Plattform achten. Holen Sie sich tägliche Flash-Angebote ein, spielen Sie Spiele, um Produkte zu gewinnen oder schauen Sie sich Live-Konferenzen an, um exklusive Rabatte für Live-Streaming zu genießen.",
            "fy95": "Wenn Sie bereit sind, einen Kauf zu tätigen, stellen Sie sicher, dass die Plattform es Ihnen ermöglicht, über einen sicheren und nahtlosen Kanal zu kaufen. Sie können Produktbewertungen und Bewertungen durchsuchen, Garantie für risikofreie Zahlungen verwenden und Ihren Produktlieferstatus einfach verfolgen.",
            "fy96": "Wenn Sie weitere Informationen erfahren möchten, lesen Sie bitte unseren letzten Artikel zum Shopping-Help-Center, um zu beginnen, oder Sie können die kurze Tour durch dieses Video beenden.",
            "fy97": "Meine Bestellung",
            "fy98": "Bestellnummer",
            "fy99": "Kaufzeit",
            "fy100": "Tatsächliche Zahlung",
            "fy101": "Details",
            "fy102": "Erstattung",
            "fy103": "Kontaktieren Sie den Verkäufer",
            "fy104": "Ganz",
            "fy105": "Zahlung Des Käufers",
            "fy106": "Warten Auf Versand",
            "fy107": "Versendet",
            "fy108": "Lieferung in Bearbeitung",
            "fy109": "Kaufbeleg",
            "fy110": "Rückkehr",
            "fy111": "Rückgabe",
            "fy112": "Beantragung von Lieferanten",
            "fy113": "Handy",
            "fy114": "Postfach",
            "fy115": "Bitte geben Sie Ihre E-Mail-Adresse ein",
            "fy116": "Bitte geben Sie eine Telefonnummer ein",
            "fy117": "Prüfcode",
            "fy118": "Bitte geben Sie den Bestätigungscode ein",
            "fy119": "Von Neu Senden",
            "fy120": "Überprüfungscode erhalten",
            "fy121": "Passwort",
            "fy122": "Bitte geben Sie das Passwort ein",
            "fy123": "Empfehlungscode",
            "fy124": "Bitte geben Sie den Empfehlungscode ein",
            "fy125": "Lieferantenkategorie",
            "fy126": "Bitte wählen Sie Händlertyp",
            "fy127": "Speichername",
            "fy128": "Bitte geben Sie den Shop-Namen ein",
            "fy129": "Logo Speichern",
            "fy130": "Bitte laden Sie das Store Logo hoch",
            "fy131": "Ausweis, Führerschein, Reisepass",
            "fy132": "Bitte laden Sie Ihren Personalausweis, Führerschein und Reisepass hoch",
            "fy133": "Absenden",
            "fy134": "Bitte wählen Sie eine Kategorie",
            "fy135": "Mobile Verifizierung fehlgeschlagen",
            "fy136": "E-Mail-Überprüfung fehlgeschlagen",
            "fy137": "Bitte wählen Sie Händlertyp"
        }
    },
    "seller": {
        "layout": {
            "fy1": "Startseite",
            "fy2": "Grundlegende Werkzeuge",
            "fy3": "Online Service",
            "fy4": "Systemmeldungen",
            "fy5": "Persönliches Zentrum"
        },
        "home": {
            "fy1": "Geschäftsdaten",
            "fy2": "Gesamtzahl der Bestellungen",
            "fy3": "Gesamtergebnis",
            "fy4": "Verkaufsvolumen insgesamt",
            "fy5": "成功訂單",
            "fy6": "Transaktionsvolumen insgesamt",
            "fy7": "Besuche Insgesamt",
            "fy8": "Am Selben Tag",
            "fy9": "Diese Woche",
            "fy10": "Diesen Monat",
            "fy11": "Anzahl der Bestellungen",
            "fy12": "Anzahl abgeschlossener Aufträge",
            "fy13": "Anzahl der Bestellungen",
            "fy14": "Anzahl abgeschlossener Aufträge",
            "fy15": "Abmelden Vom Login",
            "fy16": "Folgen",
            "fy17": "Besuche",
            "fy18": "Kontostand",
            "fy19": "Bestellservice",
            "fy20": "Alle Bestellungen",
            "fy21": "Ausstehende Aufträge",
            "fy22": "Versandaufträge",
            "fy23": "Rückgabe-/Rückerstattungsbefehl",
            "fy24": "Zu bewertende Aufträge",
            "fy25": "Geschäftsdaten",
            "fy26": "Geschäftsdaten",
            "fy27": "Meine Bestellung",
            "fy28": "Bestellnummer",
            "fy29": "Kaufzeit",
            "fy30": "Tatsächliche Zahlung",
            "fy31": "Details",
            "fy32": "Bestätigen",
            "fy33": "Ganz",
            "fy34": "Zahlung Des Käufers",
            "fy35": "Warten Auf Versand",
            "fy36": "Versendet",
            "fy37": "Lieferung in Bearbeitung",
            "fy38": "Kaufbeleg",
            "fy39": "Rückkehr",
            "fy40": "Rückgabe",
            "fy41": "Zahlung Des Käufers",
            "fy42": "Warten Auf Versand",
            "fy43": "Versendet",
            "fy44": "Lieferung in Bearbeitung",
            "fy45": "Kaufbeleg",
            "fy46": "Rückkehr",
            "fy47": "Rückgabe",
            "fy48": "Bestelldetails",
            "fy49": "Bestellnummer",
            "fy50": "Kopie",
            "fy51": "Bestellzeit",
            "fy52": "Produktmenge",
            "fy53": "Artikelpreise",
            "fy54": "Tatsächliche Zahlung",
            "fy55": "Bestätigen"
        },
        "tool": {
            "fy1": "Abmelden Vom Login",
            "fy2": "Folgen",
            "fy3": "Besuche",
            "fy4": "Grundlegende Werkzeuge",
            "fy5": "Großhandelszentrum",
            "fy6": "Produktmanagement",
            "fy7": "Auftragsverwaltung",
            "fy8": "Marge",
            "fy9": "Evaluationsmanagement",
            "fy10": "Business Upgrade",
            "fy11": "Top-trends",
            "fy12": "Online Service",
            "fy13": "Speicherüberprüfung in Bearbeitung",
            "fy14": "Top-trends",
            "fy15": "Bestellformular",
            "fy16": "Gezielter Schub",
            "fy17": "Schnell Drücken",
            "fy18": "Wird in beliebten Empfehlungen erwähnt",
            "fy19": "Tag",
            "fy20": "Exklusiv für neue Stores",
            "fy21": "Wird in der Zukunft sein",
            "fy22": "Empfohlene Geschäfte",
            "fy23": "Erforderliche Gebühren",
            "fy24": "Lesen und nutzen Sie die Servicevereinbarung",
            "fy25": "Zahlung Bestätigen",
            "fy26": "Unzureichendes Gleichgewicht, bitte zuerst aufladen",
            "fy27": "Empfehlen Sie den Shop potenziellen Nutzern",
            "fy28": "Intelligente Systemempfehlung",
            "fy29": "Benutzerdefinierter gerichteter Druck",
            "fy30": "我的定推碼",
            "fy31": "Kopie",
            "fy32": "Benutzerdefinierte Push-Konfiguration",
            "fy33": "Automatisches Matching Schema für feste Push-Codes eingeben",
            "fy34": "Geschlecht (Single Choice)",
            "fy35": "Alter (mehrfachwahl)",
            "fy36": "Zinskategorie (Single Choice)",
            "fy37": "Land/region",
            "fy38": "Bestätigen",
            "fy39": "Unbegrenzt",
            "fy40": "Männlich",
            "fy41": "Weiblich",
            "fy42": "Daten Details",
            "fy43": "Neue Besuche",
            "fy44": "Anzahl der neuen Lüfter",
            "fy45": "Neues Transaktionsvolumen",
            "fy46": "Transaktionsbetrag",
            "fy47": "Neue Bestellmenge",
            "fy48": "Anteil",
            "fy49": "Heute",
            "fy50": "Startstatus",
            "fy51": "Bestellnummer",
            "fy52": "Kaufzeit",
            "fy53": "Einkaufszyklus",
            "fy54": "Kaufbetrag",
            "fy55": "Kaufart",
            "fy56": "Gezielter Schub",
            "fy57": "Schnell Drücken",
            "fy58": "Daten Details",
            "fy59": "Alle Bestellungen",
            "fy60": "In Launch",
            "fy61": "Ende Des Starts",
            "fy62": "Großhandelszentrum",
            "fy63": "Ausgewähltes Produkt",
            "fy64": "Produktcode",
            "fy65": "Bitte geben Sie den Produktcode ein",
            "fy66": "Suche",
            "fy67": "Mindestpreis",
            "fy68": "Höchstpreis",
            "fy69": "Suche Nach Produkten",
            "fy70": "Bestseller-rankings",
            "fy71": "Vor Dem Verkauf Gut",
            "fy72": "Gewinnranking",
            "fy73": "Vor Gewinn",
            "fy74": "Rangfolge der Anforderungen",
            "fy75": "Vor Nachfrage",
            "fy76": "Schnelle Auflistung",
            "fy77": "Manuelle Regale",
            "fy78": "Strategische Auswahl",
            "fy79": "Bitte wählen Sie ein Produkt",
            "fy80": "Erfolgreich gestartet",
            "fy81": "Wahl",
            "fy82": "Produkttyp auswählen (mehrere Auswahlmöglichkeiten sind erlaubt)",
            "fy83": "Bitte wählen Sie einen Produkttyp",
            "fy84": "Bitte geben Sie den Betrag ein",
            "fy85": "Bitte geben Sie die Anzahl der Produkte ein",
            "fy86": "Passend",
            "fy87": "Empfehlung zur Systemabgleich",
            "fy88": "Co-matching",
            "fy89": "Artikel",
            "fy90": "Rematch",
            "fy91": "Bezahlen Und Nutzen",
            "fy92": "Bitte Geben Sie Ein",
            "fy93": "Zahl",
            "fy94": "Bitte wählen Sie einen Produkttyp",
            "fy95": "Bitte geben Sie die Anzahl der Produkte ein",
            "fy96": "Bitte geben Sie den Betrag ein",
            "fy97": "Suche Nach Produkten",
            "fy98": "Produktcode",
            "fy99": "Kopie",
            "fy100": "Kaufpreis",
            "fy101": "Förderpreis",
            "fy102": "Standardprodukte",
            "fy103": "Marge",
            "fy104": "Absenden",
            "fy105": "Alle Produkte",
            "fy106": "Anzahl der Bemerkungen",
            "fy107": "Betrieb",
            "fy108": "Details Anzeigen",
            "fy109": "Dieses Produkt hat insgesamt",
            "fy110": "Bemerkungen"
        },
        "sys": {
            "fy1": "Systemmeldungen",
            "fy2": "Pack Zusammen",
            "fy3": "Details",
            "fy4": "Zum Versand"
        },
        "myCenter": {
            "fy1": "Abmelden Vom Login",
            "fy2": "Persönliches Zentrum",
            "fy3": "Meine Delegation",
            "fy4": "Meine Einladung",
            "fy5": "Meine Brieftasche",
            "fy6": "Transfer",
            "fy7": "Kaution",
            "fy8": "Rücknahme",
            "fy9": "Rechnungsdetails",
            "fy10": "Bindung",
            "fy11": "Funktionelle Dienstleistungen",
            "fy12": "Passwort Ändern",
            "fy13": "Zahlungspasswort ändern",
            "fy14": "Zahlungspasswort festlegen",
            "fy15": "Informationen speichern",
            "fy16": "Folgen",
            "fy17": "Besuche",
            "fy18": "Ausgleich der Förderung",
            "fy19": "Mein Gleichgewicht",
            "fy20": "Meine Delegation",
            "fy21": "Meine Provision",
            "fy22": "Eingeladener Laden",
            "fy23": "Einladungscode",
            "fy24": "Verbindung Einladen",
            "fy25": "Einladungscode",
            "fy26": "Anzahl des Personals erster Ebene",
            "fy27": "Anzahl des Sekundärpersonals",
            "fy28": "Zahl des Personals der dritten Ebene",
            "fy29": "Meine Einladung",
            "fy30": "Meine eingeladenen Benutzer",
            "fy31": "Einladungs-/Leistungsaufzeichnung",
            "fy32": "Saldo",
            "fy33": "Ausgleich der Förderung",
            "fy34": "Bitte Geben Sie Ein",
            "fy35": "Maximal Einlösbar",
            "fy36": "Absenden",
            "fy37": "Mein Guthaben aufladen",
            "fy38": "Maximale Eingabe",
            "fy39": "Bitte geben Sie den Überweisungsbetrag ein",
            "fy40": "Transfer Erfolgreich",
            "fy41": "Einzahlungsbetrag",
            "fy42": "Bitte geben Sie den Anzahlungsbetrag ein",
            "fy43": "Liebevolle Erinnerung",
            "fy44": "Es ist eine E-Commerce-Plattform, die es Ihnen ermöglicht, bekannte Produkte online zu kaufen, neue Geschäfte und Verkäufer zu entdecken, nach den täglichen Notwendigkeiten in Geschäften zu suchen oder eine große Auswahl an Produkten in Kategorien wie Gesundheit und Schönheit, Elektronik, Mode, Haus und Leben, Baby und Spielzeug usw. zu durchsuchen.",
            "fy45": "Aufladungsbetrag",
            "fy46": "Adresse",
            "fy47": "Kopie",
            "fy48": "UnionPay aufladen, wenden Sie sich bitte an den Kundendienst Sekretär, um das Geschäft abzuwickeln",
            "fy49": "Bitte laden Sie den Zahlungsbeleg hoch",
            "fy50": "Absenden",
            "fy51": "Bitte geben Sie den Betrag ein",
            "fy52": "Derzeit ist kein Zahlungskanal verfügbar",
            "fy53": "Bis Zum Hochladen",
            "fy54": "Auszahlungsmethode",
            "fy55": "Bitte wählen Sie die Auszahlungsmethode",
            "fy56": "Servicerate",
            "fy57": "Auszahlungsbetrag",
            "fy58": "Bitte geben Sie den Betrag ein",
            "fy59": "Servicegebühr",
            "fy60": "Aktivität",
            "fy61": "Verfügbarer Saldo",
            "fy62": "Adresse Rücknahme",
            "fy63": "Rücknahme",
            "fy64": "Zahlungspasswort",
            "fy65": "Bitte geben Sie das Zahlungspasswort ein",
            "fy66": "Zu Zahlen",
            "fy67": "Bitte geben Sie den Betrag ein",
            "fy68": "Bitte fügen Sie Auszahlungsmethode hinzu",
            "fy69": "Rechnungsdetails",
            "fy70": "Zeit",
            "fy71": "Bestellnummer",
            "fy72": "Geld",
            "fy73": "Bemerkungen",
            "fy74": "Bindung",
            "fy75": "Bitte wählen Sie ein Protokoll",
            "fy76": "Wallet-adresse",
            "fy77": "Bitte Geben Sie Ein",
            "fy78": "Konservieren",
            "fy79": "Erfolgreich hinzugefügt",
            "fy80": "Modifizieren",
            "fy81": "Hinzufügen",
            "fy82": "Adresse",
            "fy83": "Bitte wählen Sie das Hauptnetz aus",
            "fy84": "Bitte wählen Sie ein Protokoll",
            "fy85": "Wallet-adresse",
            "fy86": "Bitte Geben Sie Ein",
            "fy87": "Konservieren",
            "fy88": "Löschen",
            "fy89": "Erfolgreich Geändert",
            "fy90": "Erfolgreich hinzugefügt",
            "fy91": "Erfolgreich Gelöscht",
            "fy92": "Speicherinformationen aktualisieren",
            "fy93": "Bitte geben Sie den Shop-Namen ein",
            "fy94": "Konservieren",
            "fy95": "Bis Zum Hochladen",
            "fy96": "Bitte geben Sie den Shop-Namen ein"
        },
        size: {
            fy1: 'Größe'
        }
    }
}
