export default {
    size:'Saiz',
    color:'Warna',
    dongjie:'Dana telah dibekukan, sila hubungi perkhidmatan pelanggan',
    dongjies:'Akaun ini telah dibekukan. Sila hubungi perkhidmatan pelanggan',
    mg: {
        fy1: 'Harta bunga',
        fy2: 'kupon',
        fy3: 'Muat semula bimbit',
        fy4: 'Kod jemputan',
        fy5: 'Dalam tahanan',
        fy6: 'hari',
        fy7: 'Kadar kembalian sehari',
        fy8: 'Pembelian minimum',
        fy9: 'Langganan segera',
        fy10: 'Imbangan tersedia',
        fy11: 'Sila masukkan jumlah pembelian',
        fy12: 'keseluruhan',
        fy13: 'Siklus masa',
        fy14: 'Kadar kembalian sehari',
        fy15: 'Masa pembayaran divided',
        fy16: 'setiap hari',
        fy17: 'Dana penjagaan',
        fy18: 'Kembalian Due',
        fy19: 'Pembelian minimum',
        fy20: 'Penghasilan dijangka',
        fy21: 'Langganan segera',
        fy22: 'Pembelian minimum',
        fy23: 'Kesimbangan tidak mencukupi, sila muat semula dan cuba lagi',
        // 訂單記錄
        fy24: 'Rekod perintah',
        fy25: 'Semua arahan',
        fy26: 'Dalam proses',
        fy27: 'Selesai',
        fy28: 'Masa yang tersisa',
        fy29: 'Dalam proses',
        fy30: 'Selesai',
        fy31: 'Nombor perintah',
        fy32: 'Jumlah tandatangan',
        fy33: 'Peruntungan sehari',
        fy34: 'Siklus masa',
        fy35: 'Penghasilan dijangka',
        fy36: 'Gaji yang diperoleh',
        fy37: 'Tarikh pembelian',
        fy38: 'penebusan',
        fy39: 'Sahkan Pemulihan',
        fy40: 'Batalkan Pemulihan',
        fy41: 'Adakah anda pasti untuk menebus dana penjagaan',
        fy42: 'Perhatian: Setelah dana penjagaan diselamatkan, faedah akan dibersihkan',
        //     手機充值
        fy43: 'Muat semula bimbit',
        fy44: 'Muat semula rekod',
        fy45: 'Sila masukkan nombor telefon anda',
        fy46: 'Jumlah muat semula',
        fy47: 'Tidak dipilih',
        fy48: 'Sila perhatikan selepas muat semula berjaya, sila perhatikan bil telefon yang dipercayai kepada akaun anda',
        fy49: 'Muat Semula Sekarang',
        fy50: 'Sila masukkan nombor telefon anda',
        //     評論
        fy51: 'Pergi komentar',
        fy52: 'Diperhatikan',
        fy53: 'Tulis komen',
        fy54: 'Evaluasi produk',
        fy55: 'Tulis komen',
        fy56: 'Tinggalkan komen berharga anda',
        fy57: 'Item kering matahari',
        fy58: 'Hantar',
        fy59: 'Sangat miskin',
        fy60: 'Sangat miskin',
        fy61: 'biasanya',
        fy62: 'Sangat bagus.',
        fy63: 'Sangat bagus.',
        fy64: 'Muat semula rekod',
        fy65: 'Muat semula berjaya',
        fy66: 'Kod jemputan',
        fy67: 'Sila masukkan kod undangan',
        fy68: 'Jumlah keuntungan',
        fy69: 'Keuntungan hari ini',
        fy70: 'Tidak lengkap',
        fy71: 'Kupon semasa telah mencapai had pembelian',
        fy72: 'Tidak mencapai ambang kupon',
        fy73: 'Muat semula pengguna',
        fy74: 'Bayaran ini',
        fy75: 'Sila masukkan kuantiti',
        fy76: 'Pembayaran cepat',
        fy77: 'Muat Semula Sekarang',
        fy78: 'Sila pilih dompet pembayaran anda',
        fy79: 'semasa',
        fy80: 'Rangkaian medan gelombang',
        fy81: 'Rantai Ethereum',
        fy82: 'Rangkaian Binasi',
        fy83: 'Rantai Itali Eropah',
        fy84: 'Ouyi',
        fy85: 'rantaian',
        fy86: 'Saluran',
        fy87: 'Pilihan optimum'

    },
    newadd: {
        fy1: 'Nama Pemilik Kad',
        fy2: 'Sila masukkan nama pemilik kad',
        fy3: 'Nombor kad bank',
        fy4: 'Sila masukkan nombor kad bank anda',
        fy5: "Nama Bank",
        fy6: 'Sila masukkan nama bank',
        fy7: 'Kod bank',
        fy8: 'Sila masukkan kod bank',
        fy9: "E-Commerce Delivery",
        fy10: 'Simpilkan proses penghantaran anda dan memberikan pelanggan anda pilihan penghantaran yang cepat dan selesa. Platform kami memastikan bahawa barang anda dihantar dengan selamat dan tepat waktu setiap kali',
        fy11: 'Pengjejak masa sebenar',
        fy12: 'Pastikan pelanggan anda diberitahu mengenai kemaskini pengesan masa sebenar untuk setiap langkah. Platform kami memberikan anda pengalaman alat yang diperlukan untuk menyediakan produk tertinggi',
        fy13: "Skalability",
        fy14:'Sebagai perniagaan anda tumbuh, platform kami juga akan tumbuh sesuai. Rangkaian penghantaran kami dirancang untuk menjaga bisnes anda dan memastikan anda boleh menangani mana-mana volum penghantaran baru.'
    },
    components: {
        fy1: 'piece',
        fy2: "Bayaran",
        fy3: 'Lupakan kata laluan',
        fy4: "Selesaikan dengan segera",
        fy5: 'Total',
        fy6: 'Produk',
        fy7: "Pergi pilih produk kegemaran anda",
        fy8: 'Sila tambah alamat penghantaran dahulu',
        fy9: 'Sila tetapkan kata laluan pembayaran dahulu',
        fy10: 'Sila masukkan kata laluan',
        fy11: 'Go pay',
        fy12: 'Sila masukkan kata laluan pembayaran',
        fy13: 'Kemaskini kata laluan',
        fy14: 'Tetapkan kata laluan',
        fy15: 'Kata laluan lama',
        fy16: 'Kata laluan',
        fy17: "Sila masuklah",
        fy18: 'Kata laluan baru',
        fy19: 'Sahkan kata laluan',
        fy20: 'Sila masukkan kata laluan baru',
        fy21: 'Sila sahkan kata laluan',
        fy22: 'Simpan',
        fy23: 'Sila masukkan kata laluan 6 digit',
        fy24: 'Sila masukkan kata laluan 6 digit yang betul',
        fy25: 'Kata laluan yang dimasukkan dua kali tidak sepadan',
        fy26: "Pesan",
        fy27: "Hantar",
        fy28: 'Muat naik imej gagal',
        fy29: 'Sila masukkan atribut',
        fy30: 'Sahkan',
        fy31: 'Muat lebih',
        fy32: "Pembeli",
        fy33: "Penjual",
        fy34: 'Email',
        fy35: 'Telefon',
        fy36: 'Kata laluan',
        fy37: 'Lupakan kata laluan',
        fy38: "Daftar",
        fy39: 'Login',
        fy40: 'Login menggunakan telefon',
        fy41: 'Login menggunakan e-mel',
        fy42: 'Bantuan',
        fy43: 'Sila masukkan nombor telefon',
        fy44: 'Sila masukkan alamat e-mel anda',
        fy45: 'Pengesahan mel',
        fy46: "Pengesahan bimbit",
        fy47: 'Sila masukkan kod pengesahan',
        fy48: 'Muat lebih',
        fy49: 'Muat lebih',
        fy50: "Pilih jenis kamu",
        fy51: 'Lelaki',
        fy52: "Gadis",
        fy53: 'Langkah seterusnya',
        fy54: "Pilih usia kamu",
        fy55: "Pilih kepentingan kamu",
        fy56: 'Ikut sekurang-kurangnya 4 kepentingan',
        fy57: 'Mula mengeksplorasi',
        fy58: 'Switch up to select age',
        fy59: 'Hantar kod pengesahan',
        fy60: 'Ralat kod pengesahan',
        fy61: 'Umur tahun',
        fy62: 'Cuba lagi dalam saat',
        fy63: 'Kod pengesahan dihantar berjaya',
        fy64: 'Tetap semula berjaya, sila log masuk',
        fy65: 'Sahkan peluncuran akaun semasa?',
        fy66: 'Log keluar dari log masuk',
        fy67: 'Sponsorship',
        fy68: "Penjualan Bulan",
        fy69: 'Tidak ada data yang tersedia',
        fy70: 'Pay Now',
        fy71: 'Dompet',
        fy72: "Untuk dibayar",
        fy73: 'Sahkan pembayaran',
        fy74: 'ID, lesen pemandu, pasport',
        fy75: 'Sila muat naik dokumen anda dahulu',
        fy76: 'Aplikasi berjaya, sila tunggu ulasan',
        fy77: 'Sila pilih',
    },

    buyer: {
        withdrawList: {
            fy1: "Rentetan Perincian",
            fy2: "Masa",
            fy3: 'Jenis',
            fy4: "Jumlah",
            fy5: 'Status',
            fy6: "Penduduk Review",
            fy7: "Dilulus",
            fy8: 'Menolak'
        },
        withdraw: {
            fy1: 'Kaedah tarik',
            fy2: 'Tiada',
            fy3: 'Kadar perkhidmatan',
            fy4: 'Jumlah tarik',
            fy5: 'Sila masukkan jumlah',
            fy6: 'Gaji perkhidmatan',
            fy7: 'Aktiviti',
            fy8: 'Alamat tarik',
            fy9: "Berundur",
            fy10: 'Kata laluan pembayaran',
            fy11: 'Sila masukkan kata laluan pembayaran',
            fy12: 'OK',
            fy13: 'Sila tetapkan kata laluan pembayaran dahulu',
            fy14: 'Sila masukkan jumlah',
            fy15: 'Sila masukkan kata laluan',
            fy16: 'Sila tambah kaedah penarikan',
            fy17: "Kesimbangan yang tersedia",
        },
        shippingAddress: {
            fy1: 'Alamat penghantaran',
        },
        selected: {
            fy1: 'Kedai terpilih',
            fy2: 'Cari',
            fy3: 'Ikut',
            fy4: 'Lawat',
            fy5: 'Semua'
        },
        orderInfo: {
            fy1: 'Perintah perintah',
            fy2: 'Nombor perintah',
            fy3: 'Perintah masa tempatan',
            fy4: "Jumlah barang",
            fy5: 'Harga unit barang',
            fy6: 'Pembayaran sebenar',
            fy7: 'OK'
        },
        layout: {
            "fy1": "Tentang Kami",
            "fy2": "Kembali ke atas",
            "fy3": "Perkhidmatan Bantuan",
            "fy4": "Polisi Peribadi",
            "fy5": "Membuka kedai",
            "fy6": "Bebas",
            "fy7": "Buka kedai segera",
            "fy8": "Produk",
            "fy9": "Simpan",
            "fy10": "Cari",
            "fy11": "Batalkan",
            "fy12": "Login",
            "fy13": "Lebih",
            "fy14": "Kedai terpilih",
            "fy15": "Sila masukkan sekurang-kurangnya tiga aksara untuk mencari",
            "fy16": "Sejarah Carian",
            "fy17": "Ikut",
            "fy18": "Lawat",
            "fy19": "Lihat Semua",
        },
        category: {
            "fy1": "rekomendasi terpilih",
            "fy2": "Kedai terpilih",
            "fy3": "Dipilih",
        },
        goodsDetail: {
            "fy1": "Kuantiti",
            "fy2": "Volum jualan",
            "fy3": "Tambah ke kereta",
            "fy4": "Menghantar ekspres",
            "fy5": "Penghantaran/kembalian bebas",
            "fy6": "Keterangan Produk",
            "fy7": "Revisi produk",
            "fy8": "bintang",
            "fy9": "Sekarang tiada ulasan",
            "fy10": "Produk ini sementara tidak tersedia untuk membeli",
        },
        home: {
            "fy1": "diskaun terakhir",
            "fy2": "Rekomendasi produk yang baik",
            "fy3": "Buy One Get One Free",
            "fy4": "Buy Now",
            "fy5": "Tawaran istimewa masa terbatas",
            "fy6": "Kiraan ke bawah",
            "fy7": "Produk Popular",
            "fy8": "Penghantaran bebas",
            "fy9": "Semua produk",
            "fy10": "Kedai Popular",
            "fy11": "Lihat kedai populer",
        },
        myHeart: {
            "fy1": "Ikut",
            "fy2": "Lihat Semua",
            "fy3": "Imbangan dompet yang tersedia",
        },
        shopDetail: {
            "fy1": "Ikut",
            "fy2": "Lawat",
            "fy3": "Comprehensive",
            "fy4": "Masa",
            "fy5": "Volum jualan",
            "fy6": "Harga",
            "fy7": "Lihat Semua",
        },
        personal: {
            "fy1": "Log keluar dari log masuk",
            "fy2": "Pusat Peribadi",
            "fy3": "Maklumat Asas",
            "fy4": "Dompet saya",
            "fy5": "Deposit",
            "fy6": "Berundur",
            "fy7": "Perincian bil",
            "fy8": "Log Deposit",
            "fy9": "Log tarik",
            "fy10": "Bind USDT",
            "fy11": "Pusat Arahan",
            "fy12": "My Order",
            "fy13": "Alamat penghantaran",
            "fy14": "Servis Fungsi",
            "fy15": "Kedai terpilih",
            "fy16": "Aplikasi untuk penyedia",
            "fy17": "Ubah kata laluan",
            "fy18": "Ubah kata laluan pembayaran",
            "fy19": "Tetapkan kata laluan pembayaran",
            "fy20": "Hubungi perkhidmatan pelanggan",
            "fy21": "Tentang Kami",
            "fy22": "Perkhidmatan Bantuan",
            "fy23": "Ubahsuai",
            "fy24": "Tambah",
            "fy25": "Alamat",
            "fy26": "Nama",
            "fy27": "Sila masukkan",
            "fy28": "Maklumat kenalan",
            "fy29": "Simpan",
            "fy30": "Padam",
            "fy31": "Diubah dengan berjaya",
            "fy32": "Baru berjaya ditambah",
            "fy33": "Alamat lalai tidak boleh dipadam",
            "fy34": "Hapuskan berjaya",
            "fy35": "Sila pilih rangkaian utama",
            "fy36": "Sila pilih protokol",
            "fy37": "Alamat dompet",
            "fy38": "Sila pilih alamat dompet USDT",
            "fy39": "Ahli biasa",
            "fy40": "keseimbangan saya",
            "fy41": "Cari",
            "fy42": "My Follow",
            "fy43": "Ikut",
            "fy44": "Lawat",
            "fy45": "Semua",
            "fy46": "Perincian Bill",
            "fy47": "Masa",
            "fy48": "Nombor perintah",
            "fy49": "Jumlah",
            "fy50": "Remarks",
            "fy51": "Kata laluan berubah dengan berjaya, sila log masuk",
            "fy52": "Dua kata laluan tidak sepadan",
            "fy53": "Katalaluan baru tidak boleh kosong",
            "fy54": "Kata laluan lama tidak boleh kosong",
            "fy55": "Ubah kata laluan",
            "fy56": "Akaun saya",
            "fy57": "Kata laluan lama",
            "fy58": "Sila masukkan kata laluan lama anda",
            "fy59": "Katalaluan baru",
            "fy60": "Sila masukkan kata laluan baru",
            "fy61": "Ulangi kata laluan baru",
            "fy62": "Sila ulangi kata laluan",
            "fy63": "Kemaskini kata laluan",
            "fy64": "Tetapkan kata laluan",
            "fy65": "Sahkan kata laluan",
            "fy66": "Sila sahkan kata laluan",
            "fy67": "Sila masukkan kata laluan 6 digit",
            "fy68": "Sila masukkan kata laluan 6 digit yang betul",
            "fy69": "Kata laluan",
            "fy70": "Jumlah deposit",
            "fy71": "Sila masukkan jumlah deposit",
            "fy72": "Warm reminder",
            "fy73": "Ia adalah platform perdagangan e-mel yang membolehkan and a membeli produk yang diketahui online, menemui kedai dan penjual baru, mencari keperluan sehari-hari di kedai, atau melayar pemilihan besar produk dalam kategori seperti kesehatan dan kecantikan, elektronik, moden, rumah dan kehidupan, bayi dan mainan, dll.",
            "fy74": "Jumlah muat semula",
            "fy75": "alamat USDT",
            "fy76": "Salin",
            "fy77": "UnionPay recharge, sila hubungi setiausaha perkhidmatan pelanggan untuk menangani perniagaan",
            "fy78": "Sila muat naik surat bayaran",
            "fy79": "Serahkan",
            "fy80": "Sila masukkan jumlah",
            "fy81": "Sekarang tiada saluran pembayaran",
            "fy82": "Fail terlalu besar",
            "fy83": "Perincian Deposit",
            "fy84": "Masa",
            "fy85": "Jenis",
            "fy86": "Jumlah",
            "fy87": "Status",
            "fy88": "Untuk diulang",
            "fy89": "Melalui",
            "fy90": "Menolak",
            "fy91": "Apa yang berbelanja",
            "fy92": "Ia adalah platform e-commerce yang membenarkan anda untuk membeli markah yang anda sudah kenal online, semasa mencari kedai dan penjual baru.",
            "fy93": "Cari barang-barang sehari-hari di kedai, atau melayar melalui pemilihan besar produk dalam kategori seperti kesehatan, keindahan, elektronik, fashion, home and lifestyle, babies, and toys.",
            "fy94": "Anda juga boleh mengawasi kegiatan promosi platform kita yang tak berakhir. Tangkap dengan perjanjian flash sehari-hari, bermain permainan untuk menang produk, atau menonton pertemuan langsung dan menikmati diskaun streaming langsung eksklusif.",
            "fy95": "Apabila and a bersedia untuk membuat pembelian, sila pastikan platform membenarkan anda untuk membuat pembelian melalui saluran yang selamat dan tidak senyap. Anda boleh melayar nilai dan ulasan produk, guna Garantia untuk pembayaran bebas risiko, dan dengan mudah mengesan status penghantaran produk anda",
            "fy96": "Jika anda ingin belajar lebih maklumat, sila melayar artikel pusat bantuan belanja terakhir kami untuk dimulakan, atau anda boleh tamatkan pelayaran cepat anda melalui video ini.",
            "fy97": "My Order",
            "fy98": "Nombor perintah",
            "fy99": "Masa pembelian",
            "fy100": "pembayaran sebenar",
            "fy101": "Perincian",
            "fy102": "Kembalikan",
            "fy103": "Hubungi",
            "fy104": "Semua",
            "fy105": "Pembeli pembayaran",
            "fy106": "Menunggu penghantaran",
            "fy107": "Dihantar",
            "fy108": "Penghantaran dalam proses",
            "fy109": "Pembeli menerima barang",
            "fy110": "Kembalikan barang",
            "fy111": "Kembali",
            "fy112": "Apply for supplier",
            "fy113": "Telefon bimbit",
            "fy114": "Email",
            "fy115": "Sila masukkan alamat e-mel anda",
            "fy116": "Sila masukkan nombor telefon",
            "fy117": "Kod pengesahan",
            "fy118": "Sila masukkan kod pengesahan",
            "fy119": "Menghantar semula",
            "fy120": "Dapatkan kod pengesahan",
            "fy121": "Kata laluan",
            "fy122": "Sila masukkan kata laluan",
            "fy123": "Kod rekomendasi",
            "fy124": "Sila masukkan kod rekomendasi",
            "fy125": "Kategori penyedia",
            "fy126": "Sila pilih jenis pedagang",
            "fy127": "Simpan nama",
            "fy128": "Sila masukkan nama kedai",
            "fy129": "Simpan IOGO",
            "fy130": "Sila muat naik logo kedai",
            "fy131": "ID, lesen pemandu, pasport",
            "fy132": "Sila muat naik ID, lesen pemandu, pasport",
            "fy133": "Serahkan",
            "fy134": "Sila pilih kategori",
            "fy135": "Pengesahan bimbit gagal",
            "fy136": "Pengesahan mel gagal",
            "fy137": "Sila pilih jenis pedagang",
        },
    }
    ,

    seller: {
        layout: {
            "fy1": "Halaman Rumah",
            "fy2": "Alat Asas",
            "fy3": "Servis pelanggan online",
            "fy4": "Mesej sistem",
            "fy5": "Pusat Peribadi",
        },
        home: {
            "fy1": "Data Perusahaan",
            "fy2": "Jumlah perintah",
            "fy3": "Jumlah keuntungan",
            "fy4": "Jumlah volum jualan",
            "fy5": "Tertib berjaya",
            "fy6": "Jumlah transaksi keseluruhan",
            "fy7": "Total Visits",
            "fy8": "Pada hari yang sama",
            "fy9": "Minggu ini",
            "fy10": "Bulan ini",
            "fy11": "Bilangan arahan",
            "fy12": "Bilangan perintah selesai",
            "fy13": "Bilangan arahan",
            "fy14": "Bilangan perintah selesai",
            "fy15": "Log keluar dari log masuk",
            "fy16": "Ikut",
            "fy17": "Lawat",
            "fy18": "Imbangan akaun",
            "fy19": "Perkhidmatan Perintah",
            "fy20": "Semua arahan",
            "fy21": "Perintah tunggu",
            "fy22": "Perintah penghantaran",
            "fy23": "Perintah Kembali/Kembali",
            "fy24": "Perintah untuk diteliti",
            fy25: "Data Perusahaan",
            fy26: 'Data perniagaan',
            fy27: "My Order",
            fy28: 'Nombor perintah',
            fy29: "Masa pembelian",
            fy30: 'Pembayaran sebenar',
            fy31: 'Perincian',
            fy32: 'Sahkan',
            fy33: "Semua",
            fy34: "Pembeli pembayaran",
            fy35: "Menunggu penghantaran",
            fy36: 'Dihantar',
            fy37: "Dalam penghantaran",
            fy38: "Pembeli menerima barang",
            fy39: "Kembalikan barang",
            fy40: "Kembali",
            fy41: 'Pembeli bayaran',
            fy42: "Menunggu penghantaran",
            fy43: 'Dihantar',
            fy44: "Dalam penghantaran",
            fy45: "Pembeli menerima barang",
            fy46: "Kembalikan barang",
            fy47: "Kembali",
            fy48: 'Perintah perintah',
            fy49: 'Nombor perintah',
            fy50: 'Diterima',
            fy51: 'Perintah masa tempatan',
            fy52: "Jumlah barang",
            fy53: 'Harga unit barang',
            fy54: 'Pembayaran sebenar',
            fy55: 'OK'
        },
        tool: {
            "fy1": "Log keluar dari log masuk",
            "fy2": "Ikut",
            "fy3": "Lawat",
            "fy4": "Alat Asas",
            "fy5": "Pusat Penjualan Besar",
            "fy6": "Pengurusan Produk",
            "fy7": "Pengurusan Perintah",
            "fy8": "Deposit",
            "fy9": "Pengurusan Evaluasi",
            "fy10": "Business Upgrade",
            "fy11": "Tenderasi tertinggi",
            "fy12": "Servis pelanggan online",
            "fy13": "Simpan ulasan dalam proses",
            "fy14": "Tenderasi tertinggi",
            "fy15": "Perintah",
            "fy16": "Targeted push",
            "fy17": "Quick Push",
            "fy18": "Akan ditampilkan dalam rekomendasi populer",
            "fy19": "Hari",
            "fy20": "Exclusive for new stores",
            "fy21": "Akan ada di masa depan",
            "fy22": "Store Recommended",
            "fy23": "Kehabisan yang diperlukan",
            "fy24": "Perjanjian Perkhidmatan telah dibaca dan digunakan secara luas",
            "fy25": 'Sahkan pembayaran',
            fy26: "Tiada keseimbangan yang mencukupi, sila muatkan semula dahulu",
            fy27: 'Recommend the store to potential users',
            fy28: 'Recommendation Intelligent System',
            fy29: 'Tekan arah suai',
            fy30: 'Kod dorongan saya',
            fy31: 'Salin',
            fy32: 'Konfigurasi tekan suai',
            fy33: 'Masukkan kod tolak tetap untuk sepadan secara automatik dengan skema',
            fy34: 'Gender (single choice)',
            fy35: 'Umur (pilihan berbilang)',
            fy36: 'Kategori kepentingan (pilihan tunggal)',
            fy37: 'Negara/Kawasan',
            fy38: 'OK',
            fy39: 'tidak terbatas',
            fy40: 'Lelaki',
            fy41: "Perempuan",
            fy42: 'Perincian data',
            fy43: 'Lawat Baru',
            fy44: 'Bilangan pengikut baru',
            fy45: 'Jumlah transaksi baru',
            fy46: 'Jumlah transaksi',
            fy47: 'Kuantiti perintah baru ditambah',
            fy48: 'Proportion',
            fy49: "Hari ini",
            fy50: 'Status tempatan',
            fy51: 'Nombor perintah',
            fy52: 'Masa pembelian',
            fy53: 'Ciklus pembelian',
            fy54: "Jumlah pembelian",
            fy55: 'Jenis pembelian',
            fy56: 'Targeted push',
            fy57: 'Push cepat',
            fy58: 'Perincian data',
            fy59: 'Semua perintah',
            fy60: 'dalam kemajuan',
            fy61: 'Akhir peluncuran',
            fy62: "Pusat Penjualan Besar",
            fy63: 'Produk terpilih',
            fy64: 'Kod produk',
            fy65: 'Sila masukkan kod produk',
            fy66: 'Cari',
            fy67: 'Harga minimum',
            fy68: 'Harga maksimum',
            fy69: 'Cari produk',
            fy70: 'Bestseller Ranking',
            fy71: "Sebelum menjual",
            fy72: 'Rangkaian keuntungan',
            fy73: "Sebelum keuntungan",
            fy74: 'Demand ranking',
            fy75: "Sebelum permintaan",
            fy76: 'Pendaftaran cepat',
            fy77: 'Rak manual',
            fy78: 'Pilihan Strategi',
            fy79: 'Sila pilih produk',
            fy80: 'Daftar berjaya',
            fy81: 'Pilih',
            fy82: 'Pilih jenis produk (pilihan berbilang dibenarkan)',
            fy83: 'Sila pilih jenis produk',
            fy84: 'Sila masukkan jumlah',
            fy85: "Sila masukkan jumlah barang",
            fy86: 'Match',
            fy87: 'Recommendation for System matching',
            fy88: 'Co sepadan',
            fy89: 'Items',
            fy90: 'Rematch',
            fy91: "Bayar dan guna",
            fy92: "Sila masukkan",
            fy93: 'Nombor',
            fy94: 'Sila pilih jenis produk',
            fy95: 'Sila masukkan kuantiti barang',
            fy96: 'Sila masukkan jumlah',
            fy97: 'Cari produk',
            fy98: 'Kod produk',
            fy99: 'Diterima',
            fy100: 'Harga pembelian',
            fy101: 'Harga promosi',
            fy102: 'Produk luar talian',
            fy103: 'Deposit',
            fy104: "Serahkan",
            fy105: 'Semua produk',
            fy106: 'Bilangan komen',
            fy107: 'Operasi',
            fy108: 'Lihat perincian',
            fy109: 'Produk ini mempunyai jumlah ',
            fy110: 'komen'
        },
        sys: {
            "fy1": "Mesej sistem",
            "fy2": "runtuh",
            "fy3": "Perincian",
            "fy4": "Pergi kapal",
        },
        myCenter: {
            "fy1": "Log keluar dari log masuk",
            "fy2": "Pusat Peribadi",
            "fy3": "Delegasi saya",
            "fy4": "undangan saya",
            "fy5": "Dompet saya",
            "fy6": "pemindahan",
            "fy7": "Deposit",
            "fy8": "Berundur",
            "fy9": "Perincian bil",
            "fy10": "Mengikat",
            "fy11": "Servis Fungsi",
            "fy12": "Ubah kata laluan",
            "fy13": "Ubah kata laluan pembayaran",
            "fy14": "Tetapkan kata laluan pembayaran",
            "fy15": "Simpan Maklumat",
            "fy16": "Ikut",
            "fy17": "Lawat",
            "fy18": "keseimbangan promosi",
            "fy19": "keseimbangan saya",
            "fy20": "Delegasi saya",
            "fy21": "Pembayaran komisi saya",
            "fy22": "kedai yang diundang",
            "fy23": "Kod jemputan",
            "fy24": "Panggilan Sambungan",
            fy25: 'Kod undangan',
            fy26: 'Bilangan pegawai tahap pertama',
            fy27: 'Bilangan pegawai sekunder',
            fy28: 'Bilangan pegawai tahap ketiga',
            fy29: "Undangan saya",
            fy30: 'Pengguna diundang saya',
            fy31: "Rekod undangan/pendapatan",
            fy32: "Kesimbangan",
            fy33: 'keseimbangan promosi',
            fy34: "Sila masuklah",
            fy35: 'Maximum redeemable',
            fy36: "Serahkan",
            fy37: "Ulangilah keseimbanganku",
            fy38: 'Input Maksimum',
            fy39: 'Sila masukkan jumlah pemindahan',
            fy40: 'Pemindahan berjaya',
            //------
            fy41: 'Jumlah deposit',
            fy42: 'Sila masukkan jumlah deposit',
            fy43: 'peringatan yang baik',
            fy44: 'merupakan platform perdagangan e-mel yang membolehkan and a membeli produk yang diketahui anda secara online, menemui kedai dan penjual baru, mencari keperluan sehari-hari di kedai, atau melayar pemilihan besar produk dalam kategori seperti kesehatan dan kecantikan, elektronik, moden, rumah dan gaya hidup, bayi dan mainan, dll.',
            fy45: 'Jumlah muatan semula',
            fy46: 'Alamat',
            fy47: 'Diterima',
            fy48: 'UnionPay recharge, sila hubungi perkhidmatan pelanggan untuk menangani perniagaan',
            fy49: 'Sila muat naik surat bayaran',
            fy50: "Serahkan",
            fy51: 'Sila masukkan jumlah',
            fy52: 'Tidak ada saluran pembayaran yang tersedia',
            fy53: 'Up to upload',
            //*-*-*--
            fy54: 'Kaedah tarik',
            fy55: 'Sila pilih kaedah penarikan',
            fy56: 'Kadar perkhidmatan',
            fy57: "Jumlah tarik",
            fy58: 'Sila masukkan jumlah',
            fy59: 'Gaji perkhidmatan',
            fy60: 'Aktiviti',
            fy61: "Boleh seimbang",
            fy62: 'Alamat tarik',
            fy63: 'Berundur',
            fy64: 'Kata laluan pembayaran',
            fy65: 'Sila masukkan kata laluan pembayaran',
            fy66: 'Go pay',
            fy67: 'Sila masukkan jumlah',
            fy68: 'Sila tambah kaedah penarikan',
            // ----
            fy69: 'Perincian bil',
            fy70: 'Masa',
            fy71: 'Nombor perintah',
            fy72: "Jumlah",
            fy73: 'Remarks',
            fy74: 'Binding',
            fy75: 'Sila pilih protokol',
            fy76: 'Alamat dompet',
            fy77: "Sila masuklah",
            fy78: 'Simpan',
            fy79: 'Ditambah dengan sukses',
            fy80: 'Ubahsuai',
            fy81: 'Tambah',
            fy82: 'Alamat',
            fy83: 'Sila pilih rangkaian utama',
            fy84: 'Sila pilih protokol',
            fy85: 'Alamat dompet',
            fy86: "Sila masuklah",
            fy87: 'Simpan',
            fy88: 'Hapuskan',
            fy89: 'Diubah dengan berjaya',
            fy90: 'Ditambah dengan sukses',
            fy91: 'Hapuskan berjaya',
            fy92: 'Kemaskini maklumat kedai',
            fy93: "Sila masukkan nama kedai",
            fy94: 'Simpan',
            fy95: 'Sampai muat naik',
            fy96: 'Sila masukkan nama kedai'
        },
        size: {
            fy1: 'Saiz'
        }
    }

}
