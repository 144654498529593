import {defineStore} from 'pinia'
import userStores from './user.js'
// const userStore = userStores()
export default defineStore('system', {
    state: () => {
        return {
            getImg: (id) => {

                if (import.meta.env.DEV) {
                    return `http://hunike.natapp1.cc/8082/buyer/file/getFile?fileId=${id}`;
                } else {
                    return `${window.location.origin}/${import.meta.env.MODE}/file/getFile?fileId=${id}`;
                }
            },
            getShopImg: () => (id) => {
                if (window.location.hostname === 'localhost') {
                    return `https://ntsell.com/seller/shopFile/getFile?fileId=${id}`;
                } else {
                    return `${window.location.origin}/seller/shopFile/getFile?fileId=${id}`;
                }
            },
            getLevel: (type) => {
                let str = ''
                switch (type) {
                    case 6:
                        str = "口碑店铺"
                        break
                    case 7:
                        str = "优先店铺"
                        break
                    case 8:
                        str = "自营店铺"
                        break
                    case 9:
                        str = "金牌店铺"
                        break
                    default:
                        str = '普通店铺'
                }
                return str
            },
            doMainLink: '',
            defaultLang:'zh',
            lang: {
                key:''
            },
            langList:[]
        }
    },
    actions: {
        setLang(lang){
            console.log(lang,'==========')
            if(!lang?.key) return
            if(lang.key !== this.lang.key && this.lang?.key) {
                setTimeout(() => {
                    window.location.reload()
                }, 200)
            }
            sessionStorage.setItem('lang',lang.key)
            localStorage.setItem('lang',lang.key)
            this.lang = lang
            xb.i18n.locale = lang.key
            // xb.i18n.setLocaleMessage(lang.key,xb.i18n.messages[lang.key])
        },
        getDomain() {
            xb.$api.getDomainLink({}).then(res => {
                res.forEach(res1 => {
                    if (res1.key === 'seller_host_url' && import.meta.env.MODE === 'buyer') {
                        this.doMainLink = res1.value
                    }
                    if (res1.key === 'buyer_host_url' && import.meta.env.MODE === 'seller') {
                        this.doMainLink = res1.value
                    }
                })
                this.upDataLogo()
            })
        },
        upDataLogo() {
            const user = userStores()
            xb.$api.getLogo({logoType: import.meta.env.MODE === 'buyer'?1:2}).then(res => {
                user.logo= res.logo
                if(res.name === '京东国际'){//
                    this.setLang(this.langList.filter(it=>it.key==='zh')[0])
                    localStorage.setItem('lang','zh')
                    sessionStorage.setItem('lang','zh')
                }
                const logo = document.getElementsByTagName('link')
                const DomLogo = Array.from(logo).filter(it=>it.rel === 'icon')
                if(DomLogo.length!==0){
                    DomLogo[0].href=this.getImg(res.logo)
                }else{
                    let node = document.createElement('link')
                    node.rel = 'shortcut icon'
                    node.type = 'image/x-icon'
                    node.href = this.getImg(res.logo)
                    document.head.append(node)
                }
                document.title = res.name
            })
        }
    },
    // 使用该插件，开启数据缓存
    persist: {
        //这里存储默认使用的是session
        enabled: true,
        strategies: [
            {
                key: 'system',
                //更改默认存储，我更改为localStorage
                storage: sessionStorage,
                // 可以选择哪些进入local存储，这样就不用全部都进去存储了
                // 默认是全部进去存储
            }
        ]
    }
})
