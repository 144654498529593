<script setup>
import userStore from '@/store/module/user'
import system from "@/store/module/system.js";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {onMounted} from "vue";
const i18n = useI18n()
xb.$t = i18n.t
const router = useRouter()
xb.i18n = i18n
xb['$user'] = userStore()
xb['$sys'] = system()
// xb.$api['123']({uuid:'123',agentId:123})
xb.$sys.getDomain()
let whiteList = ['/','/category','/openAStore','/selectedStores']
router.beforeEach((to,from,next)=>{
  if(!xb.$user.isLogin){
    if(whiteList.includes(to.path)){
      next()
    }else{
      xb.$emit('noLogin')
    }
  }else{
    next()
  }

})
router.afterEach((to,from)=>{
  if(to.matched.length === 0){
    window.location.hash = from.hash
    return false
  }
  if(xb.$user.isLogin)xb.$user.getUserInfo()

})
if (xb.$user.isLogin) {

  xb.$user.getUserInfo()
  xb.$api.queryUserWallet().then(res => {

    xb.$user.wallet = res
    xb.$user.activeWallet = res[0]
  })


  xb.$api.getPayChannel().then(res => {

    xb.$user.PayChannel = res
  })

  xb.$api.getAddress({ isDefault: 1 }).then(res => {
    xb.$user.activeAddress = res[0]
  })


  xb.$api.getHashMapByKey({ key: 'pay_password_switch' }).then(res => {
    xb.$user.payPasswordSwitch = res?.value == 0
  })

  xb.$api.getHashMapByKey({ key: 'wallet_address_modification' }).then(res => {
    xb.$user.editAddress = !(res?.value == 0)
  })
  // 开启轮训
  xb.$user.scheduledTasksForBuyer()
}
xb.$user.getShopLevel()

xb.$on('logOut',e=>{
  router.push({path:'/'})
})
router.afterEach((to)=>{
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})
onMounted(()=>{
  xb.$sys.setLang(xb.$sys.lang)
})
</script>

<template>
  <div class="versioon">
    v1.2.3
  </div>
  <router-view />
</template>

<style lang="scss">
.versioon{
  position: fixed;
  bottom:10px;
  right:10px
}
#app {
  background-color: #f4f4f4;
  height: 100%;
  width: 100%;
  min-width: 1512px;
}

@font-face {
  font-family: 'D-DIN-PRO';
  src: url('@/assets/fonts/D-DIN-PRO-700-Bold.ttf');
}

html,
body {
  height: 100%;
  width: 100%;
  min-width: 1512px;
  background-color: #f4f4f4;
}

</style>
