import { createWebHashHistory, createRouter } from "vue-router";

const route = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'layout',
            redirect: '/home',
            component: () => import('@/layout/index.vue'),
            children: [{
                path: '/',
                name: 'home',
                component: () => import('@/buyer/views/home/index.vue'),
                meta: {
                    title: '首页'
                }
            },
            {
                path: '/category',
                name: 'category',
                component: () => import('@/buyer/views/category/index.vue'),
                meta: {
                    title: '分类'
                }
            },
            {
                path: '/goodsDetail',
                name: 'goodsDetail',
                component: () => import('@/buyer/views/goodsDetail/index.vue'),
                meta: {
                    title: '商品详情'
                }
            },
            {
                path: '/personal',
                name: 'personal',
                component: () => import('@/buyer/views/personal/index.vue'),
                redirect: '/baseInfo',
                meta: {
                    title: '个人中心',
                    closeFoot: true
                },
                children: [
                    {
                        path: '/baseInfo',
                        name: 'baseInfo',
                        component: () => import('@/buyer/views/personal/component/baseInfo.vue'),
                        meta: {
                            title: '基础信息',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/deposit',
                        name: 'deposit',
                        component: () => import('@/buyer/views/personal/component/deposit.vue'),
                        meta: {
                            title: '存款',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/withdraw',
                        name: 'withdraw',
                        component: () => import('@/buyer/views/personal/component/withdraw.vue'),
                        meta: {
                            title: '提现',
                            closeFoot: true
                        },
                    },

                    {
                        path: '/billingDetails',
                        name: 'billingDetails',
                        component: () => import('@/buyer/views/personal/component/billingDetails.vue'),
                        meta: {
                            title: '账单明细',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/depositList',
                        name: 'depositList',
                        component: () => import('@/buyer/views/personal/component/depositList.vue'),
                        meta: {
                            title: '存款日志',
                            closeFoot: true
                        },
                    },

                    {
                        path: '/withdrawalList',
                        name: 'withdrawalList',
                        component: () => import('@/buyer/views/personal/component/withdrawalList.vue'),
                        meta: {
                            title: '存款日志',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/bindingUSDT',
                        name: 'bindingUSDT',
                        component: () => import('@/buyer/views/personal/component/bindingUSDT.vue'),
                        meta: {
                            title: '存款日志',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/MyOrder',
                        name: 'MyOrder',
                        component: () => import('@/buyer/views/personal/component/MyOrder.vue'),
                        meta: {
                            title: '我的订单',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/selected',
                        name: 'selected',
                        component: () => import('@/buyer/views/personal/component/selected.vue'),
                        meta: {
                            title: '精选商铺',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/openShop',
                        name: 'openShop',
                        redirect:'/openAStore',
                        component: () => import('@/buyer/views/personal/component/openShop.vue'),
                        children:[{
                            path: '/openAStore',
                            name: 'openAStore',
                            component: () => import('@/buyer/views/personal/component/openShop.vue'),
                            meta: {
                                title: '申请供应商',
                                closeFoot: true
                            },
                        }],
                        meta: {
                            title: '申请供应商',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/changPwd',
                        name: 'changPwd',
                        component: () => import('@/buyer/views/personal/component/changPwd.vue'),
                        meta: {
                            title: '申请供应商',
                            closeFoot: true
                        },
                    }, {
                        path: '/shippingAddress',
                        name: 'shippingAddress',
                        component: () => import('@/buyer/views/personal/component/shippingAddress.vue'),
                        meta: {
                            title: '送货地址',
                            closeFoot: true
                        },
                    },

                    {
                        path: '/changzfPwd',
                        name: 'changzfPwd',
                        component: () => import('@/buyer/views/personal/component/changzfPwd.vue'),
                        meta: {
                            title: '修改支付密码',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/help',
                        name: 'help',
                        component: () => import('@/buyer/views/personal/component/help.vue'),
                        meta: {
                            title: '帮助服务',
                            closeFoot: true
                        },
                    }, {
                        path: '/aboutUs',
                        name: 'aboutUs',
                        component: () => import('@/buyer/views/personal/component/aboutUs.vue'),
                        meta: {
                            title: '关于我们',
                            closeFoot: true
                        },
                    },




                ]
            },
            {
                path: '/selectedStores',
                name: 'selectedStores',
                component: () => import('@/buyer/views/selectedStores/index.vue'),
                meta: {
                    title: '商品详情'
                }
            },
            {
                path: '/shopDetail',
                name: 'shopDetail',
                component: () => import('@/buyer/views/shopDetail/index.vue'),
                meta: {
                    title: '店铺详情'
                }
            },
            {
                path: '/payment',
                name: 'payment',
                component: () => import('@/buyer/views/payment/index.vue'),
                meta: {
                    title: '确认订单'
                }
            },
                {
                    path: '/myHeart',
                    name: 'myHeart',
                    component: () => import('@/buyer/views/myHeart/index.vue'),
                    meta: {
                        title: '我的关注'
                    }
                },

            ]
        },

    ]
})
// route.beforeEach((to,from,next)=>{
//     const sysStore = systemStroe()
//     const user = userStore()
//     if(!xb.router.hasRoute(to.name)){
//         next('/404')
//         return
//     }
//     if((!user.userInfo.token || user.userInfo.token == '') && to.path !== '/login'){
//         next(`/login?redirect=${to.path}`)
//     }else{
//         if(user.userInfo.token && to.name == 'login'){
//             next('/')
//             return;
//         }
//     }
//     sysStore.routerBreadList = to.matched.filter(item=>item.name!='layout' && item.name != '404' && item.name != 'login')
//     if(!sysStore.history.some(it=>it.name == to.name) && !(to.name == '404'||to.name == 'login')) sysStore.history.push(to)
//     next()
// })
export default route
