export default {
    size:'tamanho',
    color:'Cor',
    dongjies:'Esta conta foi congelada, entre em contato com o atendimento ao cliente',
    dongjie:'Os fundos foram congelados, entre em contato com o atendimento ao cliente',
    "mg": {
        "fy1": "Tesouro De Juros",
        "fy2": "Cupão",
        "fy3": "Recarga Móvel",
        "fy4": "Código Do Convite",
        "fy5": "Em Custódia",
        "fy6": "Dia",
        "fy7": "Rendimento Diário",
        "fy8": "Compra Mínima",
        "fy9": "Assinatura Imediata",
        "fy10": "Saldo Disponível",
        "fy11": "Indique o valor da compra",
        "fy12": "Inteiro",
        "fy13": "Período De Duração",
        "fy14": "Rendimento Diário",
        "fy15": "Tempo de pagamento dos dividendos",
        "fy16": "Todos Os Dias",
        "fy17": "Fundos De Garantia",
        "fy18": "Rendimento Devido",
        "fy19": "Compra Mínima",
        "fy20": "Receitas Previstas",
        "fy21": "Assinatura Imediata",
        "fy22": "Compra Mínima",
        "fy23": "Equilíbrio insuficiente, recarregue e tente novamente",
        "fy24": "Registo de Encomendas",
        "fy25": "Todas As Encomendas",
        "fy26": "Em Curso",
        "fy27": "Concluído",
        "fy28": "Tempo Restante",
        "fy29": "Em Curso",
        "fy30": "Concluído",
        "fy31": "Número De Ordem",
        "fy32": "Montante da subscrição",
        "fy33": "Lucro Diário",
        "fy34": "Período De Duração",
        "fy35": "Receitas Previstas",
        "fy36": "Lucrativo",
        "fy37": "Data De Compra",
        "fy38": "Resgate",
        "fy39": "Confirmar O Resgate",
        "fy40": "Cancelar O Resgate",
        "fy41": "Você tem certeza de resgatar os fundos de custódia",
        "fy42": "Nota: Uma vez que os fundos de custódia são resgatados, os juros serão compensados",
        "fy43": "Recarga Móvel",
        "fy44": "Recarregar O Registo",
        "fy45": "Introduza o seu número de telefone",
        "fy46": "Montante Da Recarga",
        "fy47": "Não Seleccionado",
        "fy48": "Por favor, note que após a recarga bem sucedida, preste atenção ao pagamento da conta de telefone",
        "fy49": "Recarregar Agora",
        "fy50": "Introduza o seu número de telefone",
        "fy51": "Ir Comentar",
        "fy52": "Revisado",
        "fy53": "Escrever um comentário",
        "fy54": "Avaliação Do Produto",
        "fy55": "Escrever um comentário",
        "fy56": "Deixe seus preciosos comentários",
        "fy57": "Sol Bem",
        "fy58": "Enviar",
        "fy59": "Muito Pobre",
        "fy60": "Muito Pobre",
        "fy61": "Comummente",
        "fy62": "Muito Bom",
        "fy63": "Muito Bom",
        "fy64": "Recarregar O Registo",
        "fy65": "Recarregado com sucesso",
        "fy66": "Código Do Convite",
        "fy67": "Indique por favor o código do convite",
        "fy68": "Lucro Total",
        "fy69": "O Lucro De Hoje",
        "fy70": "Incompleto",
        "fy71": "O cupom atual atingiu o limite de compra",
        "fy72": "Limiar do cupão não atingido",
        "fy73": "Recarga do utilizador",
        "fy74": "Este Pagamento",
        "fy75": "Indique A Quantidade",
        "fy76": "Pagamento Rápido",
        "fy77": "Recarregar Agora",
        "fy78": "Seleccione por favor a sua carteira de pagamento",
        "fy79": "Actual",
        "fy80": "Cadeia de campo de ondas",
        "fy81": "Cadeia Ethereum",
        "fy82": "Cadeia de segurança de moedas",
        "fy83": "Cadeia Italiana Europeia",
        "fy84": "Ouyi",
        "fy85": "Cadeia",
        "fy86": "Canal",
        "fy87": "Preferred"
    },
    "newadd": { "fy1": "Nome do Titular do Cartão", "fy2": "Indique o nome do titular do cartão", "fy3": "Número do cartão bancário", "fy4": "Introduza o número do seu cartão bancário", "fy5": "Nome Do Banco", "fy6": "Indique por favor o nome do banco", "fy7": "Código Bancário", "fy8": "Introduza o seu código bancário", "fy9": "Entrega de comércio electrónico", "fy10": "Simplifique seu processo de entrega e forneça aos seus clientes opções de entrega rápidas e convenientes. Nossa plataforma garante que seus itens sejam entregues de forma segura e pontual todas as vezes.", "fy11": "Rastreamento em tempo real", "fy12": "Mantenha seus clientes informados sobre atualizações de rastreamento em tempo real em cada etapa. Nossa plataforma oferece a experiência de ferramenta necessária para a entrega de primeira classe.", "fy13": "Escalabilidade", "fy14": "À medida que seu negócio cresce, nossa plataforma também crescerá de acordo. Nossa rede de entrega visa acompanhar seu negócio e garantir que você possa lidar com qualquer aumento no volume de entrega." },
    "components": {
        "fy1": "Peça",
        "fy2": "Pagamento",
        "fy3": "Esqueceu- se da senha",
        "fy4": "Acordo Imediato",
        "fy5": "Total",
        "fy6": "Commodity",
        "fy7": "Vá escolher os produtos que você gosta",
        "fy8": "Por favor, adicione um endereço de entrega primeiro",
        "fy9": "Por favor, defina a senha de pagamento primeiro",
        "fy10": "Introduza A Senha",
        "fy11": "Para Pagar",
        "fy12": "Introduza a senha de pagamento",
        "fy13": "Actualizar A Senha",
        "fy14": "Definir A Senha",
        "fy15": "Senha Antiga",
        "fy16": "Senha",
        "fy17": "Por Favor, Introduza",
        "fy18": "Nova Senha",
        "fy19": "Confirmar A Senha",
        "fy20": "Introduza uma nova senha",
        "fy21": "Por favor confirme a senha",
        "fy22": "Preservar",
        "fy23": "Introduza uma senha de 6 dígitos",
        "fy24": "Insira a senha correta de 6 dígitos",
        "fy25": "As senhas inseridas duas vezes não correspondem",
        "fy26": "Notícias",
        "fy27": "Enviar",
        "fy28": "O envio da imagem falhou",
        "fy29": "Indique por favor os atributos",
        "fy30": "Confirmar",
        "fy31": "Carregar Mais",
        "fy32": "Comprador",
        "fy33": "Vendedor",
        "fy34": "Caixa De Correio",
        "fy35": "Telefone",
        "fy36": "Senha",
        "fy37": "Esqueceu- se da senha",
        "fy38": "Registo",
        "fy39": "Login",
        "fy40": "Iniciar sessão usando o telefone",
        "fy41": "Iniciar sessão usando e- mail",
        "fy42": "Ajuda",
        "fy43": "Introduza um número de telefone",
        "fy44": "Introduza um endereço de e-mail",
        "fy45": "Verificação de e-mail",
        "fy46": "Verificação Móvel",
        "fy47": "Introduza o código de verificação",
        "fy48": "Carregar Mais",
        "fy49": "Carregar Mais",
        "fy50": "Escolha O Seu Género",
        "fy51": "Rapazes",
        "fy52": "Meninas",
        "fy53": "Passo Seguinte",
        "fy54": "Escolha A Sua Idade",
        "fy55": "Escolha os seus interesses",
        "fy56": "Seguir pelo menos 4 interesses",
        "fy57": "Começar A Explorar",
        "fy58": "Deslize para cima para seleccionar a idade",
        "fy59": "Enviar código de verificação",
        "fy60": "Erro no código de verificação",
        "fy61": "Ano",
        "fy62": "Tente novamente em segundos",
        "fy63": "Código de verificação enviado com sucesso",
        "fy64": "Reiniciar com sucesso, por favor, faça o login",
        "fy65": "Tem a certeza de iniciar a conta corrente?",
        "fy66": "Sair Do Login",
        "fy67": "Patrocinador",
        "fy68": "Vendas Mensais",
        "fy69": "Actualmente, não existem dados disponíveis",
        "fy70": "Pagamento Imediato",
        "fy71": "Carteira",
        "fy72": "A Pagar",
        "fy73": "Confirmar o pagamento",
        "fy74": "BI, carta de condução, passaporte",
        "fy75": "Por favor, envie o seu ID primeiro",
        "fy76": "Aplicação bem sucedida, aguarde por favor a revisão",
        "fy77": "Seleccione Por Favor"
    },
    "buyer": {
        "withdrawList": {
            "fy1": "Dados relativos à retirada",
            "fy2": "Tempo",
            "fy3": "Tipo",
            "fy4": "Dinheiro",
            "fy5": "Estado",
            "fy6": "Revisão Pendente",
            "fy7": "Adoptar",
            "fy8": "Recusar"
        },
        "withdraw": {
            "fy1": "Método de retirada",
            "fy2": "Nada",
            "fy3": "Taxa De Serviço",
            "fy4": "Montante De Retirada",
            "fy5": "Indique O Montante",
            "fy6": "Taxa De Serviço",
            "fy7": "Actividade",
            "fy8": "Endereço de retirada",
            "fy9": "Retirada",
            "fy10": "Senha De Pagamento",
            "fy11": "Introduza a senha de pagamento",
            "fy12": "Confirmar",
            "fy13": "Por favor, defina a senha de pagamento primeiro",
            "fy14": "Indique O Montante",
            "fy15": "Introduza A Senha",
            "fy16": "Por favor, adicione o método de retirada",
            "fy17": "Saldo Disponível"
        },
        "shippingAddress": {
            "fy1": "Endereço De Entrega"
        },
        "selected": {
            "fy1": "Lojas Seleccionadas",
            "fy2": "Pesquisa",
            "fy3": "Seguir",
            "fy4": "Visitas",
            "fy5": "Inteiro"
        },
        "orderInfo": {
            "fy1": "Detalhes da encomenda",
            "fy2": "Número De Ordem",
            "fy3": "Hora Da Encomenda",
            "fy4": "Quantidade do produto",
            "fy5": "Preços Dos Itens",
            "fy6": "Pagamento Efectivo",
            "fy7": "Confirmar"
        },
        "layout": {
            "fy1": "Sobre Nós",
            "fy2": "Voltar Ao Topo",
            "fy3": "Serviços De Ajuda",
            "fy4": "Política de Privacidade",
            "fy5": "Abrir Uma Loja",
            "fy6": "Livre",
            "fy7": "Abra uma loja imediatamente",
            "fy8": "Commodity",
            "fy9": "Loja",
            "fy10": "Pesquisa",
            "fy11": "Cancelar",
            "fy12": "Login",
            "fy13": "Mais",
            "fy14": "Lojas Seleccionadas",
            "fy15": "Insira pelo menos três caracteres para pesquisa",
            "fy16": "Histórico de Pesquisa",
            "fy17": "Seguir",
            "fy18": "Visitas",
            "fy19": "Ver Todos"
        },
        "category": {
            "fy1": "Recomendações seleccionadas",
            "fy2": "Lojas Seleccionadas",
            "fy3": "Seleccionado"
        },
        "goodsDetail": {
            "fy1": "Quantidade",
            "fy2": "Volume De Vendas",
            "fy3": "Adicionar ao carrinho de compras",
            "fy4": "Expedição Urgente",
            "fy5": "Frete grátis/devolução",
            "fy6": "Descrição Do Produto",
            "fy7": "Avaliação Do Produto",
            "fy8": "Estrela",
            "fy9": "Não existe avaliação actualmente disponível",
            "fy10": "Este produto está temporariamente indisponível para compra"
        },
        "home": {
            "fy1": "Últimos Descontos",
            "fy2": "Boa recomendação de produto",
            "fy3": "Buy One Get One Free",
            "fy4": "Comprar Agora",
            "fy5": "Oferta especial por tempo limitado",
            "fy6": "Contagem Regressiva",
            "fy7": "Produtos Populares",
            "fy8": "Frete Grátis",
            "fy9": "Todos Os Produtos",
            "fy10": "Lojas Populares",
            "fy11": "Ver Lojas Populares"
        },
        "myHeart": {
            "fy1": "Seguir",
            "fy2": "Ver Todos",
            "fy3": "Saldo disponível da carteira"
        },
        "shopDetail": {
            "fy1": "Seguir",
            "fy2": "Visitas",
            "fy3": "Abrangente",
            "fy4": "Tempo",
            "fy5": "Volume De Vendas",
            "fy6": "Preço",
            "fy7": "Ver Todos"
        },
        "personal": {
            "fy1": "Sair Do Login",
            "fy2": "Centro Pessoal",
            "fy3": "Informação De Base",
            "fy4": "A Minha Carteira",
            "fy5": "Depósito",
            "fy6": "Retirada",
            "fy7": "Detalhes Da Factura",
            "fy8": "Registo De Depósitos",
            "fy9": "Registo De Retirada",
            "fy10": "Ligar Usdt",
            "fy11": "Centro De Pedidos",
            "fy12": "A Minha Ordem",
            "fy13": "Endereço De Entrega",
            "fy14": "Serviços Funcionais",
            "fy15": "Lojas Seleccionadas",
            "fy16": "Pedido de fornecedores",
            "fy17": "Mudar A Senha",
            "fy18": "Alterar a senha do pagamento",
            "fy19": "Definir a senha do pagamento",
            "fy20": "Contactar o serviço de apoio ao cliente",
            "fy21": "Sobre Nós",
            "fy22": "Serviços De Ajuda",
            "fy23": "Modificar",
            "fy24": "Adicionar",
            "fy25": "Endereço",
            "fy26": "Nome",
            "fy27": "Por Favor, Introduza",
            "fy28": "Informações de contacto",
            "fy29": "Preservar",
            "fy30": "Apagar",
            "fy31": "Modificado com sucesso",
            "fy32": "Adição Com Sucesso",
            "fy33": "O endereço predefinido não pode ser apagado",
            "fy34": "Eliminado com sucesso",
            "fy35": "Seleccione por favor a rede principal",
            "fy36": "Seleccione por favor um protocolo",
            "fy37": "Endereço Da Carteira",
            "fy38": "Seleccione por favor o endereço da carteira USDT",
            "fy39": "Membros Ordinários",
            "fy40": "O Meu Equilíbrio",
            "fy41": "Pesquisa",
            "fy42": "A Minha Atenção",
            "fy43": "Seguir",
            "fy44": "Visitas",
            "fy45": "Inteiro",
            "fy46": "Detalhes Da Factura",
            "fy47": "Tempo",
            "fy48": "Número De Ordem",
            "fy49": "Dinheiro",
            "fy50": "Observações",
            "fy51": "A senha foi alterada com sucesso, por favor faça login",
            "fy52": "As duas senhas não correspondem",
            "fy53": "A nova senha não pode estar vazia",
            "fy54": "A senha antiga não pode estar vazia",
            "fy55": "Mudar A Senha",
            "fy56": "A Minha Conta",
            "fy57": "Senha Antiga",
            "fy58": "Por favor, introduza a sua senha antiga",
            "fy59": "Nova Senha",
            "fy60": "Introduza uma nova senha",
            "fy61": "Repetir A Senha Nova",
            "fy62": "Por favor repita a senha",
            "fy63": "Actualizar A Senha",
            "fy64": "Definir A Senha",
            "fy65": "Confirmar A Senha",
            "fy66": "Por favor confirme a senha",
            "fy67": "Introduza uma senha de 6 dígitos",
            "fy68": "Insira a senha correta de 6 dígitos",
            "fy69": "Senha",
            "fy70": "Montante Do Depósito",
            "fy71": "Introduza o valor do depósito",
            "fy72": "Lembrete Gentil",
            "fy73": "É uma plataforma de e-commerce que lhe permite comprar produtos conhecidos online, descobrir novas lojas e vendedores, pesquisar necessidades diárias nas lojas, ou navegar uma grande variedade de produtos em categorias como saúde e beleza, eletrônicos, moda, casa e vida, bebê e brinquedos, etc.",
            "fy74": "Montante Da Recarga",
            "fy75": "Endereço Usdt",
            "fy76": "Cópia",
            "fy77": "Recarga UnionPay, entre em contato com o secretário de atendimento ao cliente para lidar com o negócio",
            "fy78": "Envie o voucher de pagamento",
            "fy79": "Enviar",
            "fy80": "Indique O Montante",
            "fy81": "Não existe actualmente nenhum canal de pagamento disponível",
            "fy82": "Ficheiro demasiado grande",
            "fy83": "Detalhes Do Depósito",
            "fy84": "Tempo",
            "fy85": "Tipo",
            "fy86": "Dinheiro",
            "fy87": "Estado",
            "fy88": "Revisão Pendente",
            "fy89": "Adoptar",
            "fy90": "Recusar",
            "fy91": "O que é fazer compras",
            "fy92": "É uma plataforma de e-commerce que lhe permite comprar marcas que já conhece online enquanto descobre novas lojas e vendedores.",
            "fy93": "Procure itens essenciais diários na loja, ou navegue através de uma grande variedade de produtos em categorias como saúde e beleza, eletrônicos, moda, casa e vida, bebês e brinquedos.",
            "fy94": "Você também pode prestar atenção às infinitas atividades promocionais em nossa plataforma. Acompanhe as ofertas flash diárias, jogue jogos para ganhar produtos ou assista conferências ao vivo para desfrutar de descontos exclusivos em streaming ao vivo.",
            "fy95": "Quando você estiver pronto para fazer uma compra, certifique-se de que a plataforma permite que você compre através de um canal seguro e contínuo.",
            "fy96": "Se você quiser saber mais informações, consulte nosso artigo final do centro de ajuda de compras para começar, ou você pode terminar o passeio rápido através deste vídeo.",
            "fy97": "A Minha Ordem",
            "fy98": "Número De Ordem",
            "fy99": "Tempo De Compra",
            "fy100": "Pagamento Efectivo",
            "fy101": "Detalhes",
            "fy102": "Restituição",
            "fy103": "Contactar O Vendedor",
            "fy104": "Inteiro",
            "fy105": "Pagamento do Comprador",
            "fy106": "À espera da expedição",
            "fy107": "Enviado",
            "fy108": "Entrega Em Curso",
            "fy109": "Recibo Do Comprador",
            "fy110": "Retorno",
            "fy111": "Devolvido",
            "fy112": "Pedido de fornecedores",
            "fy113": "Telemóvel",
            "fy114": "Caixa De Correio",
            "fy115": "Introduza o seu endereço de e-mail",
            "fy116": "Introduza um número de telefone",
            "fy117": "Código de verificação",
            "fy118": "Introduza o código de verificação",
            "fy119": "Enviar De Novo",
            "fy120": "Obter código de verificação",
            "fy121": "Senha",
            "fy122": "Introduza A Senha",
            "fy123": "Código da recomendação",
            "fy124": "Introduza o código de recomendação",
            "fy125": "Categoria do fornecedor",
            "fy126": "Seleccione por favor o tipo de comerciante",
            "fy127": "Nome Da Loja",
            "fy128": "Indique por favor o nome da loja",
            "fy129": "Conservar O Ogo",
            "fy130": "Envie por favor o logótipo da loja",
            "fy131": "BI, carta de condução, passaporte",
            "fy132": "Por favor, envie seu documento de identidade, carteira de motorista e passaporte",
            "fy133": "Enviar",
            "fy134": "Seleccione por favor uma categoria",
            "fy135": "A verificação móvel falhou",
            "fy136": "A verificação do e- mail falhou",
            "fy137": "Seleccione por favor o tipo de comerciante"
        }
    },
    "seller": {
        "layout": {
            "fy1": "Página Inicial",
            "fy2": "Ferramentas Básicas",
            "fy3": "Serviço Online",
            "fy4": "Mensagens Do Sistema",
            "fy5": "Centro Pessoal"
        },
        "home": {
            "fy1": "Dados Comerciais",
            "fy2": "Número total de encomendas",
            "fy3": "Lucro Total",
            "fy4": "Volume total de vendas",
            "fy5": "Ordem Bem Sucedida",
            "fy6": "Volume total das transacções",
            "fy7": "Total De Visitas",
            "fy8": "No Mesmo Dia",
            "fy9": "Esta Semana",
            "fy10": "Este Mês",
            "fy11": "Número De Encomendas",
            "fy12": "Número de encomendas concluídas",
            "fy13": "Número De Encomendas",
            "fy14": "Número de encomendas concluídas",
            "fy15": "Sair Do Login",
            "fy16": "Seguir",
            "fy17": "Visitas",
            "fy18": "Saldo Da Conta",
            "fy19": "Serviço de Encomendas",
            "fy20": "Todas As Encomendas",
            "fy21": "Ordens Pendentes",
            "fy22": "Ordens De Envio",
            "fy23": "Ordem de devolução/reembolso",
            "fy24": "Ordens A Avaliar",
            "fy25": "Dados Comerciais",
            "fy26": "Dados Comerciais",
            "fy27": "A Minha Ordem",
            "fy28": "Número De Ordem",
            "fy29": "Tempo De Compra",
            "fy30": "Pagamento Efectivo",
            "fy31": "Detalhes",
            "fy32": "Confirmar",
            "fy33": "Inteiro",
            "fy34": "Pagamento do Comprador",
            "fy35": "À espera da expedição",
            "fy36": "Enviado",
            "fy37": "Entrega Em Curso",
            "fy38": "Recibo Do Comprador",
            "fy39": "Retorno",
            "fy40": "Devolvido",
            "fy41": "Pagamento do Comprador",
            "fy42": "À espera da expedição",
            "fy43": "Enviado",
            "fy44": "Entrega Em Curso",
            "fy45": "Recibo Do Comprador",
            "fy46": "Retorno",
            "fy47": "Devolvido",
            "fy48": "Detalhes da encomenda",
            "fy49": "Número De Ordem",
            "fy50": "Cópia",
            "fy51": "Hora Da Encomenda",
            "fy52": "Quantidade do produto",
            "fy53": "Preços Dos Itens",
            "fy54": "Pagamento Efectivo",
            "fy55": "Confirmar"
        },
        "tool": {
            "fy1": "Sair Do Login",
            "fy2": "Seguir",
            "fy3": "Visitas",
            "fy4": "Ferramentas Básicas",
            "fy5": "Centro Grossista",
            "fy6": "Gestão De Produtos",
            "fy7": "Gestão De Encomendas",
            "fy8": "Margem",
            "fy9": "Gestão Da Avaliação",
            "fy10": "Actualização do negócio",
            "fy11": "Principais tendências",
            "fy12": "Serviço Online",
            "fy13": "Armazenar a revisão em curso",
            "fy14": "Principais tendências",
            "fy15": "Formulário de encomenda",
            "fy16": "Empurrão direccionado",
            "fy17": "Empurre Rápido",
            "fy18": "Será apresentado em recomendações populares",
            "fy19": "Dia",
            "fy20": "Exclusivo para novas lojas",
            "fy21": "Será No Futuro",
            "fy22": "Lojas Recomendadas",
            "fy23": "Taxas Exigidas",
            "fy24": "Leia e use o Contrato de Serviço",
            "fy25": "Confirmar o pagamento",
            "fy26": "Equilíbrio insuficiente, recarregue primeiro",
            "fy27": "Recomendar a loja a potenciais utilizadores",
            "fy28": "Recomendação inteligente do sistema",
            "fy29": "Empurrão direccionado personalizado",
            "fy30": "O Meu Código Push",
            "fy31": "Cópia",
            "fy32": "Configuração Personalizada de Push",
            "fy33": "Sistema de correspondência automática de código push fixo de entrada",
            "fy34": "Sexo (escolha Única)",
            "fy35": "Idade (escolhas múltiplas)",
            "fy36": "Categoria de juros (escolha única)",
            "fy37": "País/região",
            "fy38": "Confirmar",
            "fy39": "Ilimitado",
            "fy40": "Masculino",
            "fy41": "Fêmea",
            "fy42": "Detalhes Dos Dados",
            "fy43": "Novas Visitas",
            "fy44": "Número de novos ventiladores",
            "fy45": "Novo volume de transacções",
            "fy46": "Montante da transacção",
            "fy47": "Quantidade da nova encomenda",
            "fy48": "Proporção",
            "fy49": "Hoje",
            "fy50": "Estado De Lançamento",
            "fy51": "Número De Ordem",
            "fy52": "Tempo De Compra",
            "fy53": "Ciclo De Compra",
            "fy54": "Montante Da Compra",
            "fy55": "Tipo De Compra",
            "fy56": "Empurrão direccionado",
            "fy57": "Empurre Rápido",
            "fy58": "Detalhes Dos Dados",
            "fy59": "Todas As Encomendas",
            "fy60": "No Lançamento",
            "fy61": "Fim Do Lançamento",
            "fy62": "Centro Grossista",
            "fy63": "Produto Seleccionado",
            "fy64": "Código Do Produto",
            "fy65": "Introduza o código do produto",
            "fy66": "Pesquisa",
            "fy67": "Preço Mínimo",
            "fy68": "Preço Máximo",
            "fy69": "Procurar Produtos",
            "fy70": "Rankings Bestseller",
            "fy71": "Antes De Vender Bem",
            "fy72": "Classificação dos lucros",
            "fy73": "Antes Do Lucro",
            "fy74": "Classificação dos requisitos",
            "fy75": "Antes Da Procura",
            "fy76": "Lista Rápida",
            "fy77": "Estantes Manuais",
            "fy78": "Selecção Estratégica",
            "fy79": "Seleccione por favor um produto",
            "fy80": "Lançado Com Sucesso",
            "fy81": "Escolha",
            "fy82": "Selecione o tipo de produto (várias opções são permitidas)",
            "fy83": "Seleccione por favor um tipo de produto",
            "fy84": "Indique O Montante",
            "fy85": "Indique a quantidade de produtos",
            "fy86": "Correspondência",
            "fy87": "Recomendação de correspondência do sistema",
            "fy88": "Correspondência",
            "fy89": "Item",
            "fy90": "Rematch",
            "fy91": "Pagar E Utilizar",
            "fy92": "Por Favor, Introduza",
            "fy93": "Número",
            "fy94": "Seleccione por favor um tipo de produto",
            "fy95": "Indique a quantidade de produtos",
            "fy96": "Indique O Montante",
            "fy97": "Procurar Produtos",
            "fy98": "Código Do Produto",
            "fy99": "Cópia",
            "fy100": "Preço De Compra",
            "fy101": "Preço Promocional",
            "fy102": "Produtos fora de prateleira",
            "fy103": "Margem",
            "fy104": "Enviar",
            "fy105": "Todos Os Produtos",
            "fy106": "Número de comentários",
            "fy107": "Operação",
            "fy108": "Ver Detalhes",
            "fy109": "Este produto tem um total de",
            "fy110": "Observações"
        },
        "sys": {
            "fy1": "Mensagens Do Sistema",
            "fy2": "Faz As Malas.",
            "fy3": "Detalhes",
            "fy4": "Para Enviar"
        },
        "myCenter": {
            "fy1": "Sair Do Login",
            "fy2": "Centro Pessoal",
            "fy3": "A Minha Delegação",
            "fy4": "O Meu Convite",
            "fy5": "A Minha Carteira",
            "fy6": "Transferência",
            "fy7": "Depósito",
            "fy8": "Retirada",
            "fy9": "Detalhes Da Factura",
            "fy10": "Ligação",
            "fy11": "Serviços Funcionais",
            "fy12": "Mudar A Senha",
            "fy13": "Alterar a senha do pagamento",
            "fy14": "Definir a senha do pagamento",
            "fy15": "Armazenar a Informação",
            "fy16": "Seguir",
            "fy17": "Visitas",
            "fy18": "Saldo De Promoção",
            "fy19": "O Meu Equilíbrio",
            "fy20": "A Minha Delegação",
            "fy21": "A Minha Comissão",
            "fy22": "Loja Convidada",
            "fy23": "Código Do Convite",
            "fy24": "Convidar A Ligação",
            "fy25": "Código Do Convite",
            "fy26": "Número de pessoal de primeiro nível",
            "fy27": "Número de pessoal secundário",
            "fy28": "Número de pessoal de terceiro nível",
            "fy29": "O Meu Convite",
            "fy30": "Os Meus Utilizadores Convidados",
            "fy31": "Registo de Convites/Benefícios",
            "fy32": "Equilíbrio",
            "fy33": "Saldo De Promoção",
            "fy34": "Por Favor, Introduza",
            "fy35": "Máximo Resgatável",
            "fy36": "Enviar",
            "fy37": "Recarregar o meu saldo",
            "fy38": "Entrada Máxima",
            "fy39": "Indique o valor da transferência",
            "fy40": "Transferência bem sucedida",
            "fy41": "Montante Do Depósito",
            "fy42": "Introduza o valor do depósito",
            "fy43": "Lembrete Gentil",
            "fy44": "É uma plataforma de e-commerce que lhe permite comprar produtos conhecidos online, descobrir novas lojas e vendedores, pesquisar necessidades diárias nas lojas, ou navegar uma grande variedade de produtos em categorias como saúde e beleza, eletrônicos, moda, casa e vida, bebê e brinquedos, etc.",
            "fy45": "Montante Da Recarga",
            "fy46": "Endereço",
            "fy47": "Cópia",
            "fy48": "Recarga UnionPay, entre em contato com o secretário de atendimento ao cliente para lidar com o negócio",
            "fy49": "Envie o voucher de pagamento",
            "fy50": "Enviar",
            "fy51": "Indique O Montante",
            "fy52": "Não existe actualmente nenhum canal de pagamento disponível",
            "fy53": "Até Carregar",
            "fy54": "Método De Retirada",
            "fy55": "Seleccione por favor o método de retirada",
            "fy56": "Taxa De Serviço",
            "fy57": "Montante De Retirada",
            "fy58": "Indique O Montante",
            "fy59": "Taxa De Serviço",
            "fy60": "Actividade",
            "fy61": "Saldo Disponível",
            "fy62": "Refletir O Endereço",
            "fy63": "Retirada",
            "fy64": "Senha De Pagamento",
            "fy65": "Introduza a senha de pagamento",
            "fy66": "Para Pagar",
            "fy67": "Indique O Montante",
            "fy68": "Por favor, adicione o método de retirada",
            "fy69": "Detalhes Da Factura",
            "fy70": "Tempo",
            "fy71": "Número De Ordem",
            "fy72": "Dinheiro",
            "fy73": "Observações",
            "fy74": "Ligação",
            "fy75": "Seleccione por favor um protocolo",
            "fy76": "Endereço Da Carteira",
            "fy77": "Por Favor, Introduza",
            "fy78": "Preservar",
            "fy79": "Adição Com Sucesso",
            "fy80": "Modificar",
            "fy81": "Adicionar",
            "fy82": "Endereço",
            "fy83": "Seleccione por favor a rede principal",
            "fy84": "Seleccione por favor um protocolo",
            "fy85": "Endereço Da Carteira",
            "fy86": "Por Favor, Introduza",
            "fy87": "Preservar",
            "fy88": "Apagar",
            "fy89": "Modificado com sucesso",
            "fy90": "Adição Com Sucesso",
            "fy91": "Eliminado com sucesso",
            "fy92": "Actualizar as informações do armazenamento",
            "fy93": "Indique por favor o nome da loja",
            "fy94": "Preservar",
            "fy95": "Até Carregar",
            "fy96": "Indique por favor o nome da loja"
        },
        size: {
            fy1: 'tamanho'
        }
    }
}
