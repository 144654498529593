export default {
    size:'크기',
    color:'색',
    "dongjie": "자금이 이미 동결되었으니, 고객센터에 연락하십시오.",
    "dongjies": "이 계정은 이미 동결되었으니 고객센터에 연락하십시오.",
    "mg": {
        "fy1": "이자보",
        "fy2": "쿠폰",
        "fy3": "휴대폰 충전",
        "fy4": "초대 코드",
        "fy5": "호스팅 중",
        "fy6": "일",
        "fy7": "일일 수익률",
        "fy8": "최소 구매",
        "fy9": "지금 신청",
        "fy10": "사용 가능한 잔액",
        "fy11": "구매 금액을 입력하십시오.",
        "fy12": "모두",
        "fy13": "지속 주기",
        "fy14": "일일 수익률",
        "fy15": "배당 시간",
        "fy16": "매일",
        "fy17": "위탁 자금",
        "fy18": "만료 반환",
        "fy19": "최소 구매",
        "fy20": "예상 이익",
        "fy21": "지금 신청",
        "fy22": "최소 구매",
        "fy23": "잔액 부족 충전 후 다시 시도 하세요",
        "fy24": "주문 기록",
        "fy25": "전체 주문",
        "fy26": "진행 중",
        "fy27": "완료됨",
        "fy28": "남은 시간",
        "fy29": "진행 중",
        "fy30": "완료됨",
        "fy31": "주문 번호",
        "fy32": "요청 금액",
        "fy33": "일일 이익",
        "fy34": "지속 주기",
        "fy35": "예상 이익",
        "fy36": "이득",
        "fy37": "구매 날짜",
        "fy38": "되찾다",
        "fy39": "환매 확인",
        "fy40": "되찾기 취소",
        "fy41": "위탁 관리 자금의 환매 확인 여부",
        "fy42": "주의: 일단 위탁 관리 자금을 상환하면, 이자는 모두 비워질 것이다",
        "fy43": "휴대폰 충전",
        "fy44": "충전 기록",
        "fy45": "전화 번호를 입력하십시오.",
        "fy46": "충전 금액",
        "fy47": "선택 안 함",
        "fy48": "충전 성공 후 전화 요금 입금 주의하세요",
        "fy49": "즉시 충전",
        "fy50": "전화 번호를 입력하십시오.",
        "fy51": "댓글 달기",
        "fy52": "설명됨",
        "fy53": "댓글을 쓰다",
        "fy54": "상품 평가",
        "fy55": "댓글을 쓰다",
        "fy56": "소중한 댓글 남겨주세요.",
        "fy57": "좋은 물건을 말리다",
        "fy58": "제출",
        "fy59": "매우 나쁘다",
        "fy60": "매우 나쁘다",
        "fy61": "보통",
        "fy62": "좋아",
        "fy63": "아주 좋아",
        "fy64": "충전 기록",
        "fy65": "충전 성공",
        "fy66": "초대 코드",
        "fy67": "초대 코드를 입력하십시오.",
        "fy68": "총이익",
        "fy69": "오늘의 이윤",
        "fy70": "미완성",
        "fy71": "현재 쿠폰이 구매 상한에 도달하였습니다.",
        "fy72": "쿠폰 문턱 미달",
        "fy73": "사용자 충전",
        "fy74": "이번 지불",
        "fy75": "수량을 입력하십시오.",
        "fy76": "간편결제",
        "fy77": "즉시 충전",
        "fy78": "결제 지갑을 선택하십시오.",
        "fy79": "현재",
        "fy80": "파장 체인",
        "fy81": "이더리움 체인",
        "fy82": "화폐 보안 사슬",
        "fy83": "유럽-이탈리아 체인",
        "fy84": "유럽",
        "fy85": "체인",
        "fy86": "채널",
        "fy87": "우선"
    },
    "newadd": {
        "fy1": "카드 소지자 이름",
        "fy2": "카드 소지자 이름을 입력하십시오.",
        "fy3": "은행카드 번호",
        "fy4": "은행카드 번호를 입력하십시오.",
        "fy5": "은행명",
        "fy6": "은행 이름을 입력하십시오.",
        "fy7": "은행 코드",
        "fy8": "은행 코드를 입력하십시오.",
        "fy9": "전자 상거래 제공",
        "fy10": "제공 프로세스를 단순화하고 고객에게 빠르고 편리한 제공 옵션을 제공합니다.우리의 플랫폼은 당신의 물품이 매번 안전하고 제시간에 인도되도록 보장합니다.",
        "fy11": "실시간 추적",
        "fy12": "고객이 각 단계의 실시간 추적 업데이트를 이해할 수 있도록 합니다.Dell의 플랫폼은 최상의 서비스를 제공하는 데 필요한 도구 경험을 제공합니다.",
        "fy13": "확장성",
        "fy14": "비즈니스가 성장함에 따라 Dell의 플랫폼도 성장합니다.Dell의 제공 네트워크는 고객의 비즈니스에 따라 고객이 추가 배송을 처리할 수 있도록 설계되었습니다."
    },
    "components": {
        "fy1": "부품",
        "fy2": "지불",
        "fy3": "암호를 잊었습니다.",
        "fy4": "즉시 결제",
        "fy5": "합계",
        "fy6": "상품",
        "fy7": "마음에 드는 상품을 고르세요.",
        "fy8": "먼저 배송 주소를 추가하십시오.",
        "fy9": "먼저 지불 비밀번호를 설정하십시오.",
        "fy10": "암호를 입력하십시오.",
        "fy11": "지불하러 가다",
        "fy12": "결제 비밀번호를 입력하십시오.",
        "fy13": "암호 업데이트",
        "fy14": "암호 설정",
        "fy15": "이전 암호",
        "fy16": "암호",
        "fy17": "입력하십시오.",
        "fy18": "새 암호",
        "fy19": "암호 확인",
        "fy20": "새 암호를 입력하십시오.",
        "fy21": "비밀번호 확인",
        "fy22": "저장",
        "fy23": "6자리 암호를 입력하십시오.",
        "fy24": "올바른 6자리 암호를 입력하십시오.",
        "fy25": "두 번 입력한 암호가 일치하지 않습니다.",
        "fy26": "메시지",
        "fy27": "발송",
        "fy28": "그림 업로드 실패",
        "fy29": "등록 정보를 입력하십시오.",
        "fy30": "확인",
        "fy31": "추가 로드",
        "fy32": "구매자",
        "fy33": "판매자",
        "fy34": "메일박스",
        "fy35": "전화기",
        "fy36": "암호",
        "fy37": "암호를 잊었습니다.",
        "fy38": "등록",
        "fy39": "로그인",
        "fy40": "전화로 로그인",
        "fy41": "사서함으로 로그인",
        "fy42": "도움말",
        "fy43": "전화 입력",
        "fy44": "이메일 주소를 입력하십시오.",
        "fy45": "메일박스 인증",
        "fy46": "휴대폰 인증",
        "fy47": "인증 코드를 입력하십시오.",
        "fy48": "추가 로드",
        "fy49": "추가 로드",
        "fy50": "성별을 선택하세요",
        "fy51": "남자",
        "fy52": "여자",
        "fy53": "다음 단계",
        "fy54": "나이를 선택하세요",
        "fy55": "취미를 선택하세요",
        "fy56": "최소 4개의 관심",
        "fy57": "탐색 시작",
        "fy58": "위로 슬라이딩 선택 나이",
        "fy59": "인증 코드 보내기",
        "fy60": "인증 코드 오류",
        "fy61": "세",
        "fy62": "초 후에 다시 시도",
        "fy63": "인증 코드 전송 성공",
        "fy64": "재설정되었습니다. 로그인하십시오.",
        "fy65": "현재 계정 출시 확인?",
        "fy66": "로그인 종료",
        "fy67": "협찬",
        "fy68": "월 판매량",
        "fy69": "데이터 없음",
        "fy70": "즉시 지불",
        "fy71": "지갑",
        "fy72": "미지급",
        "fy73": "지급 확인",
        "fy74": "Id,운전면허증,여권",
        "fy75": "증명서를 먼저 올려주세요.",
        "fy76": "신청이 성공하였으니 심사를 기다려 주십시오",
        "fy77": "선택하십시오."
    },
    "buyer": {
        "withdrawList": {
            "fy1": "상세 정보 현금 인출",
            "fy2": "시간",
            "fy3": "유형",
            "fy4": "금액",
            "fy5": "상태",
            "fy6": "보류 중",
            "fy7": "통과",
            "fy8": "거부"
        },
        "withdraw": {
            "fy1": "현금 인출 방식",
            "fy2": "없음",
            "fy3": "서비스 요금",
            "fy4": "현금 인출 금액",
            "fy5": "금액을 입력하세요",
            "fy6": "서비스 요금",
            "fy7": "활동",
            "fy8": "현금 인출 주소",
            "fy9": "현금 인출",
            "fy10": "결제 비밀번호",
            "fy11": "결제 비밀번호를 입력하십시오.",
            "fy12": "확인",
            "fy13": "먼저 지불 비밀번호를 설정하십시오.",
            "fy14": "금액을 입력하세요",
            "fy15": "암호를 입력하십시오.",
            "fy16": "현금 인출 방법을 추가하십시오.",
            "fy17": "사용 가능한 잔액"
        },
        "shippingAddress": {
            "fy1": "배송 주소"
        },
        "selected": {
            "fy1": "정선 점포",
            "fy2": "검색",
            "fy3": "관심",
            "fy4": "액세스",
            "fy5": "모두"
        },
        "orderInfo": {
            "fy1": "주문 상세 정보",
            "fy2": "주문 번호",
            "fy3": "주문 시간",
            "fy4": "상품 수량",
            "fy5": "상품 단가",
            "fy6": "실비",
            "fy7": "확인"
        },
        "layout": {
            "fy1": "우리에 대해",
            "fy2": "맨 위로",
            "fy3": "도움말 서비스",
            "fy4": "개인 정보 보호 정책",
            "fy5": "개점",
            "fy6": "무료",
            "fy7": "즉시 개점 하다",
            "fy8": "상품",
            "fy9": "가게",
            "fy10": "검색",
            "fy11": "취소",
            "fy12": "로그인",
            "fy13": "추가",
            "fy14": "정선 점포",
            "fy15": "검색하려면 최소 3자를 입력하십시오.",
            "fy16": "검색 내역",
            "fy17": "관심",
            "fy18": "액세스",
            "fy19": "모두 보기"
        },
        "category": {
            "fy1": "정선 추천",
            "fy2": "정선 점포",
            "fy3": "정선"
        },
        "goodsDetail": {
            "fy1": "수량",
            "fy2": "판매량",
            "fy3": "카트에 추가",
            "fy4": "급속 발송",
            "fy5": "무료 배송 / 반품",
            "fy6": "상품 설명",
            "fy7": "상품 평가",
            "fy8": "별",
            "fy9": "평가 없음",
            "fy10": "이 상품은 당분간 구매할 수 없습니다."
        },
        "home": {
            "fy1": "최신 혜택",
            "fy2": "좋은 물건 추천",
            "fy3": "하나를 사면 하나를 증정한다",
            "fy4": "지금 구매",
            "fy5": "한시적 특별 행사",
            "fy6": "카운트다운",
            "fy7": "인기 상품",
            "fy8": "배송료 면제",
            "fy9": "전 상품",
            "fy10": "인기 점포",
            "fy11": "인기 점포 보기"
        },
        "myHeart": {
            "fy1": "관심",
            "fy2": "모두 보기",
            "fy3": "지갑 사용 가능한 잔액"
        },
        "shopDetail": {
            "fy1": "관심",
            "fy2": "액세스",
            "fy3": "종합",
            "fy4": "시간",
            "fy5": "판매량",
            "fy6": "가격",
            "fy7": "모두 보기"
        },
        "personal": {
            "fy1": "로그인 종료",
            "fy2": "개인 센터",
            "fy3": "기본 정보",
            "fy4": "내 지갑",
            "fy5": "저금",
            "fy6": "현금 인출",
            "fy7": "계산서 명세",
            "fy8": "예금 일지",
            "fy9": "현금 인출 로그",
            "fy10": "Usdt 바인딩",
            "fy11": "주문 센터",
            "fy12": "내 주문",
            "fy13": "배송 주소",
            "fy14": "기능 서비스",
            "fy15": "정선 상점",
            "fy16": "공급업체 신청",
            "fy17": "암호 수정",
            "fy18": "결제 암호 수정",
            "fy19": "결제 비밀번호 설정",
            "fy20": "고객 서비스에 문의",
            "fy21": "우리에 대해",
            "fy22": "도움말 서비스",
            "fy23": "수정",
            "fy24": "추가",
            "fy25": "주소",
            "fy26": "이름",
            "fy27": "입력하십시오.",
            "fy28": "연락처",
            "fy29": "저장",
            "fy30": "삭제",
            "fy31": "수정 성공",
            "fy32": "신규 성공",
            "fy33": "기본 주소는 삭제할 수 없습니다.",
            "fy34": "삭제 성공",
            "fy35": "홈 네트워크를 선택하십시오.",
            "fy36": "계약을 선택하십시오.",
            "fy37": "지갑 주소",
            "fy38": "Usdt 지갑 주소를 선택하십시오.",
            "fy39": "일반 회원",
            "fy40": "내 잔액",
            "fy41": "검색",
            "fy42": "나의 관심",
            "fy43": "관심",
            "fy44": "액세스",
            "fy45": "모두",
            "fy46": "계산서 명세",
            "fy47": "시간",
            "fy48": "주문 번호",
            "fy49": "금액",
            "fy50": "설명",
            "fy51": "암호 변경에 성공했습니다. 로그인하십시오.",
            "fy52": "비밀번호가 두 번 일치하지 않습니다.",
            "fy53": "새 암호는 비워둘 수 없습니다.",
            "fy54": "이전 암호는 비워둘 수 없습니다.",
            "fy55": "암호 수정",
            "fy56": "내 계정",
            "fy57": "이전 암호",
            "fy58": "이전 암호를 입력하십시오.",
            "fy59": "새 암호",
            "fy60": "새 암호를 입력하십시오.",
            "fy61": "새 암호 반복",
            "fy62": "암호를 반복하십시오.",
            "fy63": "암호 업데이트",
            "fy64": "암호 설정",
            "fy65": "암호 확인",
            "fy66": "비밀번호 확인",
            "fy67": "6자리 숫자 비밀번호를 입력하십시오.",
            "fy68": "올바른 6자리 숫자 암호를 입력하십시오.",
            "fy69": "암호",
            "fy70": "예금 금액",
            "fy71": "예금 금액을 입력하세요",
            "fy72": "따뜻한 힌트",
            "fy73": "이미 알고 있는 상품을 온라인으로 구매하면서 새로운 상점과 판매자를 발견하고 상점에서 일상적인 필수품을 찾거나 건강과 미용, 전자상품, 패션, 가정과 생활, 아기와 장난감 등 카테고리의 대량의 정선상품을 찾아볼 수 있는 전자상거래 플랫폼입니다.",
            "fy74": "충전 금액",
            "fy75": "Usdt 주소",
            "fy76": "복제",
            "fy77": "유니온페이 충전, 고객센터 비서에게 연락하여 업무를 처리하십시오",
            "fy78": "지불 증명서를 업로드하십시오.",
            "fy79": "제출",
            "fy80": "금액을 입력하세요",
            "fy81": "지불 채널 없음",
            "fy82": "파일이 너무 큽니다.",
            "fy83": "예금 상세 정보",
            "fy84": "시간",
            "fy85": "유형",
            "fy86": "금액",
            "fy87": "상태",
            "fy88": "보류 중",
            "fy89": "통과",
            "fy90": "거부",
            "fy91": "쇼핑이란 무엇인가?",
            "fy92": "이미 알고 있는 브랜드를 온라인으로 구매하고 새로운 상점과 판매자를 발견할 수 있는 전자상거래 플랫폼입니다.",
            "fy93": "상점에서 일상필수품을 찾거나 건강미용, 전자상품, 류행, 가정과 생활 영아와 장난감 등 류형을 찾아보면 대량의 정선상품이 있다.",
            "fy94": "저희 플랫폼의 무궁무진한 판촉 활동도 주의하실 수 있습니다.매일 플래시몹 거래를 따라잡고, 게임을 하여 상품을 얻거나, Live 회의를 시청하고, 생방송 독점 혜택을 누린다.",
            "fy95": "구매를 준비할 때 플랫폼에서 안전하고 빈틈없는 파이프라인으로 구매할 수 있는지 확인하십시오. 상품 등급과 의견을 찾아보고, Guarantee를 사용하여 위험 없는 지불을 할 수 있으며, 상품 납품 상황을 쉽게 추적할 수 있습니다.",
            "fy96": "자세한 내용을 보려면 최종 쇼핑 도움말 센터 문서를 찾아 시작하거나 이 비디오를 통해 빠른 스트리밍을 끝낼 수 있습니다.",
            "fy97": "내 주문",
            "fy98": "주문 번호",
            "fy99": "구매 기간",
            "fy100": "실비",
            "fy101": "상세 정보",
            "fy102": "환불",
            "fy103": "판매자에게 연락하다",
            "fy104": "모두",
            "fy105": "구매자 결제",
            "fy106": "배송 대기 중",
            "fy107": "배송됨",
            "fy108": "배송 중",
            "fy109": "구매자가 물건을 받다",
            "fy110": "반품 중",
            "fy111": "반품됨",
            "fy112": "공급업체 신청",
            "fy113": "핸드폰",
            "fy114": "메일박스",
            "fy115": "메일박스 입력",
            "fy116": "전화 입력",
            "fy117": "인증 코드",
            "fy118": "인증 코드를 입력하십시오.",
            "fy119": "새로 보내기",
            "fy120": "인증 코드 가져오기",
            "fy121": "암호",
            "fy122": "암호를 입력하십시오.",
            "fy123": "추천 코드",
            "fy124": "추천 코드를 입력하세요",
            "fy125": "공급업체 범주",
            "fy126": "사업자 유형 선택",
            "fy127": "가게 이름",
            "fy128": "가게 이름을 입력하세요",
            "fy129": "가게 Logo",
            "fy130": "가게 로고 올려주세요.",
            "fy131": "Id, 운전면허증, 여권",
            "fy132": "Id, 운전면허증, 여권 올려주세요.",
            "fy133": "제출",
            "fy134": "범주를 선택하십시오.",
            "fy135": "휴대폰 인증 실패",
            "fy136": "메일박스 인증 실패",
            "fy137": "사업자 유형 선택"
        }
    },
    "seller": {
        "layout": {
            "fy1": "첫 페이지",
            "fy2": "기본 도구",
            "fy3": "온라인 고객 서비스",
            "fy4": "시스템 메시지",
            "fy5": "개인 센터"
        },
        "home": {
            "fy1": "비즈니스 데이터",
            "fy2": "총 주문 단수",
            "fy3": "총 이익",
            "fy4": "총 판매량",
            "fy5": "주문 성공",
            "fy6": "총 거래액",
            "fy7": "총 액세스",
            "fy8": "당일",
            "fy9": "금주",
            "fy10": "이달",
            "fy11": "주문 수",
            "fy12": "거래 주문 수",
            "fy13": "주문 수",
            "fy14": "거래 주문 수",
            "fy15": "로그인 종료",
            "fy16": "관심",
            "fy17": "액세스",
            "fy18": "계좌 잔액",
            "fy19": "주문 서비스",
            "fy20": "전체 주문",
            "fy21": "보류 중인 주문",
            "fy22": "배송 중 주문",
            "fy23": "반품 / 환불 주문",
            "fy24": "평가 대기 주문",
            "fy25": "비즈니스 데이터",
            "fy26": "비즈니스 데이터",
            "fy27": "내 주문",
            "fy28": "주문 번호",
            "fy29": "구매 기간",
            "fy30": "실비",
            "fy31": "상세 정보",
            "fy32": "확인",
            "fy33": "모두",
            "fy34": "구매자 결제",
            "fy35": "배송 대기 중",
            "fy36": "배송됨",
            "fy37": "배송 중",
            "fy38": "구매자가 물건을 받다",
            "fy39": "반품 중",
            "fy40": "반품됨",
            "fy41": "구매자 결제",
            "fy42": "배송 대기 중",
            "fy43": "배송됨",
            "fy44": "배송 중",
            "fy45": "구매자가 물건을 받다",
            "fy46": "반품 중",
            "fy47": "반품됨",
            "fy48": "주문 상세 정보",
            "fy49": "주문 번호",
            "fy50": "복제",
            "fy51": "주문 시간",
            "fy52": "상품 수량",
            "fy53": "상품 단가",
            "fy54": "실비",
            "fy55": "확인"
        },
        "tool": {
            "fy1": "로그인 종료",
            "fy2": "관심",
            "fy3": "액세스",
            "fy4": "기본 도구",
            "fy5": "도매 센터",
            "fy6": "상품 관리",
            "fy7": "주문 관리",
            "fy8": "보증금",
            "fy9": "평가 관리",
            "fy10": "비즈니스 업그레이드",
            "fy11": "인기",
            "fy12": "온라인 고객 서비스",
            "fy13": "점포 심사 중",
            "fy14": "인기",
            "fy15": "주문",
            "fy16": "정방향 푸시",
            "fy17": "빠른 푸시",
            "fy18": "인기 있는 추천",
            "fy19": "일",
            "fy20": "새 가게 전용",
            "fy21": "미래에",
            "fy22": "추천 가게",
            "fy23": "소요 비용",
            "fy24": "서비스 계약 읽기 및 일반",
            "fy25": "결제 결정",
            "fy26": "잔액 부족 우선 충전하세요",
            "fy27": "점포를 잠재 사용자에게 추천하다.",
            "fy28": "시스템 인텔리전스 권장 사항",
            "fy29": "사용자 지정 푸시",
            "fy30": "내 푸시 코드",
            "fy31": "복제",
            "fy32": "사용자 지정 푸시 구성",
            "fy33": "자동 매칭 체계를 입력하십시오.",
            "fy34": "성별 (라디오 선택)",
            "fy35": "나이 (복수 선택)",
            "fy36": "관심 범주 (라디오 선택)",
            "fy37": "국가 지역",
            "fy38": "확인",
            "fy39": "무제한",
            "fy40": "남자",
            "fy41": "여자",
            "fy42": "데이터 상세 정보",
            "fy43": "신규 액세스",
            "fy44": "신규 팬 수",
            "fy45": "신규 거래액",
            "fy46": "거래 금액",
            "fy47": "신규 주문량",
            "fy48": "차지하는 비율",
            "fy49": "오늘",
            "fy50": "투입 상태",
            "fy51": "주문 번호",
            "fy52": "구매 기간",
            "fy53": "구매 주기",
            "fy54": "구매 금액",
            "fy55": "구매 유형",
            "fy56": "정방향 푸시",
            "fy57": "빠른 푸시",
            "fy58": "데이터 상세 정보",
            "fy59": "전체 주문",
            "fy60": "투입 중",
            "fy61": "투입 종료",
            "fy62": "도매 센터",
            "fy63": "품목 선택",
            "fy64": "상품 코드",
            "fy65": "상품 번호를 입력하십시오.",
            "fy66": "검색",
            "fy67": "최소 가격",
            "fy68": "최대 가격",
            "fy69": "상품 검색",
            "fy70": "베스트셀러 순위",
            "fy71": "베스트셀러 전",
            "fy72": "이익 순위",
            "fy73": "이익 이전",
            "fy74": "수요 순위",
            "fy75": "요구 전",
            "fy76": "빠른 업로드",
            "fy77": "수동 업로드",
            "fy78": "정책 선택",
            "fy79": "상품을 선택하세요",
            "fy80": "업로드 성공",
            "fy81": "선택",
            "fy82": "상품 유형 선택 (다중 선택 가능)",
            "fy83": "상품 유형 선택",
            "fy84": "금액을 입력하세요",
            "fy85": "상품 수량을 입력하세요",
            "fy86": "일치",
            "fy87": "시스템 매칭 권장 사항",
            "fy88": "총 일치",
            "fy89": "상품",
            "fy90": "다시 일치",
            "fy91": "결제 및 사용",
            "fy92": "입력하십시오.",
            "fy93": "숫자",
            "fy94": "상품 유형 선택",
            "fy95": "상품 수량을 입력하세요",
            "fy96": "금액을 입력하세요",
            "fy97": "상품 검색",
            "fy98": "상품 코드",
            "fy99": "복제",
            "fy100": "구매 가격",
            "fy101": "프로모션 가격",
            "fy102": "하차 상품",
            "fy103": "보증금",
            "fy104": "제출",
            "fy105": "모든 상품",
            "fy106": "댓글 수",
            "fy107": "작업",
            "fy108": "자세히 보기",
            "fy109": "본 상품 총",
            "fy110": "개의 설명"
        },
        "sys": {
            "fy1": "시스템 메시지",
            "fy2": "집어치우다",
            "fy3": "상세 정보",
            "fy4": "발송하러 가다"
        },
        "myCenter": {
            "fy1": "로그인 종료",
            "fy2": "개인 센터",
            "fy3": "내 부탁",
            "fy4": "나의 초대",
            "fy5": "내 지갑",
            "fy6": "회전",
            "fy7": "저금",
            "fy8": "현금 인출",
            "fy9": "계산서 명세",
            "fy10": "바인딩",
            "fy11": "기능 서비스",
            "fy12": "암호 수정",
            "fy13": "결제 암호 수정",
            "fy14": "결제 비밀번호 설정",
            "fy15": "점포 정보",
            "fy16": "관심",
            "fy17": "액세스",
            "fy18": "보급 잔액",
            "fy19": "내 잔액",
            "fy20": "내 부탁",
            "fy21": "나의 리베이트",
            "fy22": "초대 점포",
            "fy23": "초대 코드",
            "fy24": "초대 연결",
            "fy25": "초대 코드",
            "fy26": "일급 인원",
            "fy27": "2급 인원",
            "fy28": "3급 인원",
            "fy29": "나의 초대",
            "fy30": "내 초대 사용자",
            "fy31": "초대 / 이익 기록",
            "fy32": "잔액",
            "fy33": "보급 잔액",
            "fy34": "입력하십시오.",
            "fy35": "최대 교환 가능",
            "fy36": "제출",
            "fy37": "내 잔액 충전",
            "fy38": "최대 입력 가능",
            "fy39": "이월 금액을 입력하십시오.",
            "fy40": "회전 성공",
            "fy41": "예금 금액",
            "fy42": "예금 금액을 입력하세요",
            "fy43": "따뜻한 힌트",
            "fy44": "이미 알고 있는 상품을 온라인으로 구매하면서 새로운 상점과 판매자를 발견하고 상점에서 일상적인 필수품을 찾거나 건강과 미용, 전자상품, 패션, 가정과 생활, 아기와 장난감 등 카테고리의 대량의 정선상품을 찾아볼 수 있는 전자상거래 플랫폼입니다.",
            "fy45": "충전 금액",
            "fy46": "주소",
            "fy47": "복제",
            "fy48": "유니온페이 충전, 고객센터 비밀에 연락하여 업무를 처리하십시오",
            "fy49": "지불 증명서를 업로드하십시오.",
            "fy50": "제출",
            "fy51": "금액을 입력하세요",
            "fy52": "지불 채널 없음",
            "fy53": "최대 업로드",
            "fy54": "현금 인출 방식",
            "fy55": "현금 인출 방식을 선택하십시오.",
            "fy56": "서비스 요금",
            "fy57": "현금 인출 금액",
            "fy58": "금액을 입력하세요",
            "fy59": "서비스 요금",
            "fy60": "활동",
            "fy61": "잔액 가능",
            "fy62": "현금 인출 주소",
            "fy63": "현금 인출",
            "fy64": "결제 비밀번호",
            "fy65": "결제 비밀번호를 입력하십시오.",
            "fy66": "지불하러 가다",
            "fy67": "금액을 입력하세요",
            "fy68": "현금 인출 방법을 추가하십시오.",
            "fy69": "계산서 명세",
            "fy70": "시간",
            "fy71": "주문 번호",
            "fy72": "금액",
            "fy73": "설명",
            "fy74": "바인딩",
            "fy75": "계약을 선택하십시오.",
            "fy76": "지갑 주소",
            "fy77": "입력하십시오.",
            "fy78": "저장",
            "fy79": "신규 성공",
            "fy80": "수정",
            "fy81": "추가",
            "fy82": "주소",
            "fy83": "홈 네트워크를 선택하십시오.",
            "fy84": "계약을 선택하십시오.",
            "fy85": "지갑 주소",
            "fy86": "입력하십시오.",
            "fy87": "저장",
            "fy88": "삭제",
            "fy89": "수정 성공",
            "fy90": "신규 성공",
            "fy91": "삭제 성공",
            "fy92": "상가 정보 업데이트",
            "fy93": "상점 이름을 입력하십시오.",
            "fy94": "저장",
            "fy95": "최대 업로드",
            "fy96": "상점 이름을 입력하십시오."
        },
        "size": {
            "fy1": "치수"
        }
    }
}