export default {
    size:'tamaño',
    color:'Color',
    dongjies:'Esta cuenta ha sido congelada, póngase en contacto con el servicio de atención al cliente',
    dongjie:'Los fondos han sido congelados, póngase en contacto con el servicio de atención al cliente',
    "mg": {
        "fy1": "Tesoro De Intereses",
        "fy2": "Cupón",
        "fy3": "Recarga de teléfonos móviles",
        "fy4": "Código De Invitación",
        "fy5": "En Fideicomiso",
        "fy6": "Días",
        "fy7": "Rendimiento Diario",
        "fy8": "Compra Mínima",
        "fy9": "Compra De Inmediato",
        "fy10": "Saldo Disponible",
        "fy11": "Por favor, introduzca el monto de la compra",
        "fy12": "Todo",
        "fy13": "Ciclo Continuo",
        "fy14": "Rendimiento Diario",
        "fy15": "Tiempo de pago de dividendos",
        "fy16": "Diario",
        "fy17": "Fondos Bajo Custodia",
        "fy18": "Devolución al vencimiento",
        "fy19": "Compra Mínima",
        "fy20": "Ganancias Estimadas",
        "fy21": "Compra De Inmediato",
        "fy22": "Compra Mínima",
        "fy23": "El saldo es insuficiente, recarga e intenta de nuevo.",
        "fy24": "Registro De Pedidos",
        "fy25": "Todos Los Pedidos",
        "fy26": "En Curso",
        "fy27": "Completado",
        "fy28": "Tiempo Restante",
        "fy29": "En Curso",
        "fy30": "Completado",
        "fy31": "Número De Pedido",
        "fy32": "Importe De La Compra",
        "fy33": "Ganancias Diarias",
        "fy34": "Ciclo Continuo",
        "fy35": "Ganancias Estimadas",
        "fy36": "Ha obtenido beneficios",
        "fy37": "Fecha De Compra",
        "fy38": "Rescate",
        "fy39": "Confirmación del rescate",
        "fy40": "Rescate",
        "fy41": "Si se confirma el rescate de los fondos de custodia",
        "fy42": "Nota: una vez redimidos los fondos de custodia, los intereses se vaciarán",
        "fy43": "Recarga de teléfonos móviles",
        "fy44": "Registro De Recarga",
        "fy45": "Por favor, introduzca su teléfono",
        "fy46": "Cantidad Recargada",
        "fy47": "No se ha seleccionado",
        "fy48": "Tenga en cuenta que después de que la recarga sea exitosa, preste atención a la llegada de la tarifa telefónica.",
        "fy49": "Recargar de inmediato",
        "fy50": "Por favor, introduzca su teléfono",
        "fy51": "Ir A Comentar",
        "fy52": "Comentado",
        "fy53": "Escribe un comentario",
        "fy54": "Evaluación de productos básicos",
        "fy55": "Escribe un comentario",
        "fy56": "Deja tus preciosos comentarios",
        "fy57": "Cosas buenas para secar",
        "fy58": "Presentación",
        "fy59": "Muy Pobre",
        "fy60": "Muy Pobre",
        "fy61": "General",
        "fy62": "Está Bien.",
        "fy63": "Muy Bien.",
        "fy64": "Registro De Recarga",
        "fy65": "Recarga Exitosa",
        "fy66": "Código De Invitación",
        "fy67": "Por favor, introduzca el Código de invitación.",
        "fy68": "Ganancias Totales",
        "fy69": "Ganancias De Hoy",
        "fy70": "Inacabado",
        "fy71": "En la actualidad, los cupones han alcanzado el límite máximo de compra.",
        "fy72": "No se alcanza la puerta del cupón",
        "fy73": "Recarga Del Usuario",
        "fy74": "Este Pago",
        "fy75": "Por favor, introduzca la cantidad",
        "fy76": "Pago Rápido",
        "fy77": "Recargar de inmediato",
        "fy78": "Por favor, elija su billetera de pago",
        "fy79": "Actual",
        "fy80": "Cadena de campo de ondas",
        "fy81": "Cadena Ethereum",
        "fy82": "Cadena de Seguridad monetaria",
        "fy83": "Cadena Europa - Italia",
        "fy84": "Ouyi",
        "fy85": "Cadena",
        "fy86": "Canal",
        "fy87": "Selección"
    },
    "newadd": { "fy1": "Nombre del titular de la tarjeta", "fy2": "Por favor, introduzca el nombre del titular de la tarjeta.", "fy3": "Número de tarjeta bancaria", "fy4": "Por favor, introduzca el número de tarjeta bancaria", "fy5": "Nombre Del Banco", "fy6": "Introduzca el nombre del Banco", "fy7": "Código Bancario", "fy8": "Por favor, introduzca el Código bancario", "fy9": "Entrega de comercio electrónico", "fy10": "Simplifique su proceso de entrega y proporcione a sus clientes opciones de entrega rápidas y convenientes. Nuestra plataforma garantiza que sus artículos se entreguen de manera segura y puntual cada vez.", "fy11": "Seguimiento en tiempo real", "fy12": "Deje que sus clientes entiendan cada paso de la actualización de seguimiento en tiempo real. Nuestra plataforma le ofrece la experiencia de herramientas necesarias para proporcionar entregas de primera clase.", "fy13": "Escalabilidad", "fy14": "A medida que su negocio crezca, nuestra plataforma también crecerá. Nuestra red de entrega está diseñada para acompañar su negocio y asegurarse de que puede manejar cualquier aumento de entrega." },
    "components": {
        "fy1": "Piezas",
        "fy2": "Pago",
        "fy3": "Olvida La Contraseña",
        "fy4": "Liquidación inmediata",
        "fy5": "Total",
        "fy6": "Productos Básicos",
        "fy7": "Ve a elegir los productos favoritos.",
        "fy8": "Por favor, agregue la dirección de recepción primero.",
        "fy9": "Por favor, establezca primero la contraseña de pago.",
        "fy10": "Por favor, introduzca la contraseña",
        "fy11": "Ir A Pagar",
        "fy12": "Introduzca la contraseña de pago",
        "fy13": "Actualizar la contraseña",
        "fy14": "Establecer contraseña",
        "fy15": "Contraseña Antigua",
        "fy16": "Contraseña",
        "fy17": "Por favor, introduzca",
        "fy18": "Nueva Contraseña",
        "fy19": "Confirmar Contraseña",
        "fy20": "Por favor, introduzca una nueva contraseña",
        "fy21": "Por favor, confirme la contraseña",
        "fy22": "Guardar",
        "fy23": "Introduzca una contraseña de 6 dígitos",
        "fy24": "Introduzca la contraseña correcta de 6 dígitos",
        "fy25": "La contraseña introducida dos veces es inconsistente",
        "fy26": "Noticias",
        "fy27": "Enviar",
        "fy28": "Falló la carga de la imagen",
        "fy29": "Por favor, introduzca los atributos",
        "fy30": "Confirmación",
        "fy31": "Cargar Más",
        "fy32": "Compradores",
        "fy33": "Vendedor",
        "fy34": "Buzón",
        "fy35": "Teléfono",
        "fy36": "Contraseña",
        "fy37": "Olvida La Contraseña",
        "fy38": "Registro",
        "fy39": "Iniciar Sesión",
        "fy40": "Iniciar sesión con el teléfono",
        "fy41": "Iniciar sesión con el buzón",
        "fy42": "Ayudar",
        "fy43": "Por favor, introduzca el teléfono",
        "fy44": "Por favor, introduzca la dirección del buzón.",
        "fy45": "Verificación del buzón",
        "fy46": "Verificación del teléfono móvil",
        "fy47": "Por favor, introduzca el Código de verificación",
        "fy48": "Cargar Más",
        "fy49": "Cargar Más",
        "fy50": "Elige Tu Sexo",
        "fy51": "Chicos",
        "fy52": "Niñas",
        "fy53": "Siguiente Paso",
        "fy54": "Elige Tu Edad",
        "fy55": "Elige Tus Intereses",
        "fy56": "Concéntrese en al menos 4 intereses",
        "fy57": "Empezar A Explorar",
        "fy58": "Desliza hacia arriba para seleccionar la edad",
        "fy59": "Enviar Código de verificación",
        "fy60": "Error en el Código de verificación",
        "fy61": "Años",
        "fy62": "Intenta de nuevo en segundos.",
        "fy63": "Enviar Código de verificación con éxito",
        "fy64": "Se ha restablecido con éxito, inicie sesión",
        "fy65": "¿Confirmar el lanzamiento de la cuenta actual?",
        "fy66": "Salir de inicio de sesión",
        "fy67": "Patrocinio",
        "fy68": "Ventas Mensuales",
        "fy69": "No hay datos por el momento",
        "fy70": "Pago Inmediato",
        "fy71": "Billetera",
        "fy72": "Pendiente De Pago",
        "fy73": "Confirmación del pago",
        "fy74": "Id, licencia de conducir, pasaporte",
        "fy75": "Por favor, suba su documento primero.",
        "fy76": "La solicitud es exitosa, por favor espere la revisión.",
        "fy77": "Por Favor, Elija"
    },
    "buyer": {
        "withdrawList": {
            "fy1": "Detalles del retiro de efectivo",
            "fy2": "Tiempo",
            "fy3": "Tipo",
            "fy4": "Importe",
            "fy5": "Estado",
            "fy6": "Pendiente de revisión",
            "fy7": "A Través",
            "fy8": "Rechazar"
        },
        "withdraw": {
            "fy1": "Método de retiro de efectivo",
            "fy2": "Ninguno",
            "fy3": "Tarifas De Servicio",
            "fy4": "Importe Del Retiro",
            "fy5": "Por favor, introduzca la cantidad",
            "fy6": "Tarifas De Servicio",
            "fy7": "Actividades",
            "fy8": "Dirección de retiro de efectivo",
            "fy9": "Retiro de efectivo",
            "fy10": "Contraseña De Pago",
            "fy11": "Introduzca la contraseña de pago",
            "fy12": "Determinar",
            "fy13": "Por favor, establezca primero la contraseña de pago.",
            "fy14": "Por favor, introduzca la cantidad",
            "fy15": "Por favor, introduzca la contraseña",
            "fy16": "Por favor, agregue el método de retiro de efectivo.",
            "fy17": "Saldo Disponible"
        },
        "shippingAddress": {
            "fy1": "Dirección De Entrega"
        },
        "selected": {
            "fy1": "Tiendas seleccionadas",
            "fy2": "Buscar",
            "fy3": "Atención",
            "fy4": "Número De Visitas",
            "fy5": "Todo"
        },
        "orderInfo": {
            "fy1": "Detalles Del Pedido",
            "fy2": "Número De Pedido",
            "fy3": "Tiempo De Pedido",
            "fy4": "Número de productos básicos",
            "fy5": "Precio unitario de los productos básicos",
            "fy6": "Pago Real",
            "fy7": "Determinar"
        },
        "layout": {
            "fy1": "Sobre Nosotros",
            "fy2": "Volver A La Cima",
            "fy3": "Servicios De Ayuda",
            "fy4": "Política de privacidad",
            "fy5": "Abrir Una Tienda",
            "fy6": "Gratis",
            "fy7": "Abrir una tienda de inmediato",
            "fy8": "Productos Básicos",
            "fy9": "Tienda",
            "fy10": "Buscar",
            "fy11": "Cancelación",
            "fy12": "Iniciar Sesión",
            "fy13": "Más",
            "fy14": "Tiendas seleccionadas",
            "fy15": "Introduzca al menos tres caracteres para buscar",
            "fy16": "Historia de la búsqueda",
            "fy17": "Atención",
            "fy18": "Número De Visitas",
            "fy19": "Ver Todo"
        },
        "category": {
            "fy1": "Recomendaciones seleccionadas",
            "fy2": "Tiendas seleccionadas",
            "fy3": "Selección"
        },
        "goodsDetail": {
            "fy1": "Cantidad",
            "fy2": "Ventas",
            "fy3": "Añadir al carrito de la compra",
            "fy4": "Envío Rápido",
            "fy5": "Envío / devolución gratuito",
            "fy6": "Descripción de la mercancía",
            "fy7": "Evaluación de productos básicos",
            "fy8": "Estrella",
            "fy9": "No hay evaluación por el momento",
            "fy10": "El producto no se puede comprar por el momento."
        },
        "home": {
            "fy1": "Las Últimas Ofertas",
            "fy2": "Recomendación de cosas buenas",
            "fy3": "Comprar uno y regalar uno",
            "fy4": "Comprar De Inmediato",
            "fy5": "Ofertas limitadas en el tiempo",
            "fy6": "Cuenta Atrás",
            "fy7": "Productos Populares",
            "fy8": "Sin Flete",
            "fy9": "Todos Los Productos",
            "fy10": "Tiendas Populares",
            "fy11": "Ver tiendas populares"
        },
        "myHeart": {
            "fy1": "Atención",
            "fy2": "Ver Todo",
            "fy3": "Saldo disponible de la billetera"
        },
        "shopDetail": {
            "fy1": "Atención",
            "fy2": "Número De Visitas",
            "fy3": "Integral",
            "fy4": "Tiempo",
            "fy5": "Ventas",
            "fy6": "Precio",
            "fy7": "Ver Todo"
        },
        "personal": {
            "fy1": "Salir de inicio de sesión",
            "fy2": "Centro Personal",
            "fy3": "Información Básica",
            "fy4": "Mi Billetera",
            "fy5": "Depósitos",
            "fy6": "Retiro De Efectivo",
            "fy7": "Detalles de la factura",
            "fy8": "Registro de depósitos",
            "fy9": "Diario de retiro de efectivo",
            "fy10": "Vincular Usdt",
            "fy11": "Centro De Pedidos",
            "fy12": "Mi Pedido",
            "fy13": "Dirección De Entrega",
            "fy14": "Servicios funcionales",
            "fy15": "Tiendas seleccionadas",
            "fy16": "Solicitar proveedores",
            "fy17": "Modificar la contraseña",
            "fy18": "Modificar la contraseña de pago",
            "fy19": "Establecer la contraseña de pago",
            "fy20": "Contacto con el servicio al cliente",
            "fy21": "Sobre Nosotros",
            "fy22": "Servicios De Ayuda",
            "fy23": "Modificación",
            "fy24": "Añadir",
            "fy25": "Dirección",
            "fy26": "Nombre",
            "fy27": "Por favor, introduzca",
            "fy28": "Datos De Contacto",
            "fy29": "Guardar",
            "fy30": "Eliminar",
            "fy31": "Modificación Exitosa",
            "fy32": "Nuevo Éxito",
            "fy33": "La dirección predeterminada no se puede borrar",
            "fy34": "Eliminación Exitosa",
            "fy35": "Por favor, elija la red principal",
            "fy36": "Por favor, elija el Acuerdo",
            "fy37": "Dirección de la billetera",
            "fy38": "Por favor, elija la dirección de la billetera usdt",
            "fy39": "Miembros Ordinarios",
            "fy40": "Mi Saldo",
            "fy41": "Buscar",
            "fy42": "Mi Atención",
            "fy43": "Atención",
            "fy44": "Número De Visitas",
            "fy45": "Todo",
            "fy46": "Detalles de la factura",
            "fy47": "Tiempo",
            "fy48": "Número De Pedido",
            "fy49": "Importe",
            "fy50": "Nota",
            "fy51": "Cambio de contraseña exitoso, inicie sesión",
            "fy52": "Dos contraseñas inconsistentes",
            "fy53": "La nueva contraseña no puede estar vacía",
            "fy54": "La contraseña antigua no puede estar vacía",
            "fy55": "Modificar la contraseña",
            "fy56": "Mi Cuenta",
            "fy57": "Contraseña Antigua",
            "fy58": "Por favor, introduzca la contraseña antigua",
            "fy59": "Nueva Contraseña",
            "fy60": "Por favor, introduzca una nueva contraseña",
            "fy61": "Repetir la nueva contraseña",
            "fy62": "Por favor, repita la contraseña",
            "fy63": "Actualizar la contraseña",
            "fy64": "Establecer contraseña",
            "fy65": "Confirmar Contraseña",
            "fy66": "Por favor, confirme la contraseña",
            "fy67": "Introduzca una contraseña de 6 dígitos",
            "fy68": "Introduzca la contraseña digital correcta de 6 dígitos",
            "fy69": "Contraseña",
            "fy70": "Importe Del Depósito",
            "fy71": "Por favor, introduzca el monto del depósito.",
            "fy72": "Consejos Cálidos",
            "fy73": "Es una plataforma de comercio electrónico que le permite comprar productos que conoce en línea, al tiempo que descubre nuevas tiendas y vendedores, buscar artículos de primera necesidad en las tiendas o navegar por una gran selección de productos en las categorías de salud y belleza, electrónica, moda, hogar y vida, bebés y juguetes, etc.",
            "fy74": "Cantidad Recargada",
            "fy75": "Dirección Usdt",
            "fy76": "Copiar",
            "fy77": "UnionPay recarga, por favor póngase en contacto con el Secretario de servicio al cliente para manejar el negocio",
            "fy78": "Por favor, suba el comprobante de pago",
            "fy79": "Presentación",
            "fy80": "Por favor, introduzca la cantidad",
            "fy81": "No hay Canal de pago por el momento",
            "fy82": "El archivo es demasiado grande",
            "fy83": "Detalles del depósito",
            "fy84": "Tiempo",
            "fy85": "Tipo",
            "fy86": "Importe",
            "fy87": "Estado",
            "fy88": "Pendiente de revisión",
            "fy89": "A Través",
            "fy90": "Rechazar",
            "fy91": "¿¿ qué son las compras?",
            "fy92": "Es una plataforma de comercio electrónico que le permite comprar marcas que ya conoce en línea mientras descubre nuevas tiendas y vendedores.",
            "fy93": "Encontrar las necesidades diarias en las tiendas o navegar por las categorías de belleza saludable, productos electrónicos, moda, hogar y vida bebés y juguetes requiere una gran selección de productos.",
            "fy94": "También puede prestar atención a las interminables promociones en nuestra plataforma. Alcanzar las transacciones diarias de flash, jugar juegos para ganar productos o ver reuniones en vivo y disfrutar de descuentos exclusivos en vivo.",
            "fy95": "Cuando esté listo para comprar, asegúrese de que la plataforma le permite comprar con tuberías seguras y sin fisuras, puede navegar por las calificaciones y comentarios de los productos, usar Guarantee para pagos sin riesgo y hacer un seguimiento fácil de la entrega de sus productos.",
            "fy96": "Si desea obtener más información, navegue por nuestro artículo final del Centro de ayuda comercial para comenzar, o puede terminar la vista rápida a través de este video.",
            "fy97": "Mi Pedido",
            "fy98": "Número De Pedido",
            "fy99": "Tiempo De Compra",
            "fy100": "Pago Real",
            "fy101": "Detalles",
            "fy102": "Reembolso",
            "fy103": "Contactar con el vendedor",
            "fy104": "Todo",
            "fy105": "Pago Del Comprador",
            "fy106": "Esperando El Envío",
            "fy107": "Enviado",
            "fy108": "En Distribución",
            "fy109": "El comprador recibe la mercancía",
            "fy110": "En Devolución",
            "fy111": "Devolución",
            "fy112": "Solicitar proveedores",
            "fy113": "Teléfono Móvil",
            "fy114": "Buzón",
            "fy115": "Por favor, introduzca el buzón",
            "fy116": "Por favor, introduzca el teléfono",
            "fy117": "Código de verificación",
            "fy118": "Por favor, introduzca el Código de verificación",
            "fy119": "De Nuevo Enviado",
            "fy120": "Obtener el Código de verificación",
            "fy121": "Contraseña",
            "fy122": "Por favor, introduzca la contraseña",
            "fy123": "Código de recomendación",
            "fy124": "Por favor, introduzca el Código de recomendación",
            "fy125": "Categoría de proveedor",
            "fy126": "Por favor, elija el tipo de comerciante",
            "fy127": "Nombre De La Tienda",
            "fy128": "Por favor, introduzca el nombre de la tienda.",
            "fy129": "Tienda Loogo",
            "fy130": "Por favor, suba el logotipo de la tienda",
            "fy131": "Id, licencia de conducir, pasaporte",
            "fy132": "Por favor, suba su identificación, licencia de conducir y pasaporte.",
            "fy133": "Presentación",
            "fy134": "Por favor, elija la categoría",
            "fy135": "Falló la verificación del teléfono móvil",
            "fy136": "Falló la verificación del buzón",
            "fy137": "Por favor, elija el tipo de comerciante"
        }
    },
    "seller": {
        "layout": {
            "fy1": "Página De Inicio",
            "fy2": "Herramientas Básicas",
            "fy3": "Servicio al cliente en línea",
            "fy4": "Mensajes Del Sistema",
            "fy5": "Centro Personal"
        },
        "home": {
            "fy1": "Datos Empresariales",
            "fy2": "Número de pedidos totales",
            "fy3": "Beneficio Total",
            "fy4": "Ventas Totales",
            "fy5": "Pedido Exitoso",
            "fy6": "Volumen total de transacciones",
            "fy7": "Total De Visitas",
            "fy8": "El Mismo Día",
            "fy9": "Esta Semana",
            "fy10": "Este Mes",
            "fy11": "Número De Pedidos",
            "fy12": "Número de pedidos negociados",
            "fy13": "Número De Pedidos",
            "fy14": "Número de pedidos negociados",
            "fy15": "Salir de inicio de sesión",
            "fy16": "Atención",
            "fy17": "Número De Visitas",
            "fy18": "Saldo De La Cuenta",
            "fy19": "Servicio De Pedidos",
            "fy20": "Todos Los Pedidos",
            "fy21": "Pedidos Pendientes",
            "fy22": "Pedidos En Envío",
            "fy23": "Pedidos de devolución / reembolso",
            "fy24": "Pedidos pendientes de evaluación",
            "fy25": "Datos Empresariales",
            "fy26": "Datos Empresariales",
            "fy27": "Mi Pedido",
            "fy28": "Número De Pedido",
            "fy29": "Tiempo De Compra",
            "fy30": "Pago Real",
            "fy31": "Detalles",
            "fy32": "Confirmación",
            "fy33": "Todo",
            "fy34": "Pago Del Comprador",
            "fy35": "Esperando El Envío",
            "fy36": "Enviado",
            "fy37": "En Distribución",
            "fy38": "El comprador recibe la mercancía",
            "fy39": "En Devolución",
            "fy40": "Devolución",
            "fy41": "Pago Del Comprador",
            "fy42": "Esperando El Envío",
            "fy43": "Enviado",
            "fy44": "En Distribución",
            "fy45": "El comprador recibe la mercancía",
            "fy46": "En Devolución",
            "fy47": "Devolución",
            "fy48": "Detalles Del Pedido",
            "fy49": "Número De Pedido",
            "fy50": "Copiar",
            "fy51": "Tiempo De Pedido",
            "fy52": "Número de productos básicos",
            "fy53": "Precio unitario de los productos básicos",
            "fy54": "Pago Real",
            "fy55": "Determinar"
        },
        "tool": {
            "fy1": "Salir de inicio de sesión",
            "fy2": "Atención",
            "fy3": "Número De Visitas",
            "fy4": "Herramientas Básicas",
            "fy5": "Centro Mayorista",
            "fy6": "Gestión de productos básicos",
            "fy7": "Gestión De Pedidos",
            "fy8": "Margen",
            "fy9": "Gestión de la evaluación",
            "fy10": "Actualización de Negocios",
            "fy11": "Popular",
            "fy12": "Servicio al cliente en línea",
            "fy13": "Revisión de la tienda",
            "fy14": "Popular",
            "fy15": "Pedidos",
            "fy16": "Empuje Direccional",
            "fy17": "Empuje Rápido",
            "fy18": "Se recomendará en los populares",
            "fy19": "Días",
            "fy20": "Exclusivo de la nueva tienda",
            "fy21": "Será En El Futuro",
            "fy22": "Tienda Recomendada",
            "fy23": "Gastos Necesarios",
            "fy24": "Se ha leído y difundido el Acuerdo de servicio",
            "fy25": "Determinar El Pago",
            "fy26": "Si el saldo es insuficiente, recarga primero.",
            "fy27": "Recomendar la tienda a los usuarios potenciales",
            "fy28": "Recomendación inteligente del sistema",
            "fy29": "Empuje direccional personalizado",
            "fy30": "Mi Código de empuje fijo",
            "fy31": "Copiar",
            "fy32": "Configuración personalizada de empuje",
            "fy33": "Introduzca el esquema de coincidencia automática del Código de empuje fijo",
            "fy34": "Género (individual)",
            "fy35": "Edad (selección múltiple)",
            "fy36": "Categoría de interés (primaria)",
            "fy37": "Países Y Regiones",
            "fy38": "Determinar",
            "fy39": "Sin Límite",
            "fy40": "Hombre",
            "fy41": "Mujer",
            "fy42": "Detalles de los datos",
            "fy43": "Nuevas Visitas",
            "fy44": "Número de nuevos fanáticos",
            "fy45": "Nueva Facturación",
            "fy46": "Importe de la transacción",
            "fy47": "Nuevo volumen de pedidos",
            "fy48": "Proporción",
            "fy49": "Hoy.",
            "fy50": "Estado de lanzamiento",
            "fy51": "Número De Pedido",
            "fy52": "Tiempo De Compra",
            "fy53": "Ciclo De Compra",
            "fy54": "Importe De La Compra",
            "fy55": "Tipo De Compra",
            "fy56": "Empuje Direccional",
            "fy57": "Empuje Rápido",
            "fy58": "Detalles de los datos",
            "fy59": "Todos Los Pedidos",
            "fy60": "En Lanzamiento",
            "fy61": "Fin De La Colocación",
            "fy62": "Centro Mayorista",
            "fy63": "Productos seleccionados",
            "fy64": "Código De Producto",
            "fy65": "Por favor, introduzca el Código de producto.",
            "fy66": "Buscar",
            "fy67": "Precio Mínimo",
            "fy68": "Precio Máximo",
            "fy69": "Buscar Productos",
            "fy70": "Clasificación más vendida",
            "fy71": "Antes De La Venta",
            "fy72": "Clasificación de beneficios",
            "fy73": "Antes de las ganancias",
            "fy74": "Clasificación de la demanda",
            "fy75": "Antes De La Demanda",
            "fy76": "Estante Rápido",
            "fy77": "Estante Manual",
            "fy78": "Selección estratégica",
            "fy79": "Por favor, elija el producto",
            "fy80": "éxito en los estantes",
            "fy81": "Selección",
            "fy82": "Elija el tipo de producto (se puede seleccionar más)",
            "fy83": "Por favor, elija el tipo de producto",
            "fy84": "Por favor, introduzca la cantidad",
            "fy85": "Por favor, introduzca la cantidad de productos.",
            "fy86": "Coincidencia",
            "fy87": "Recomendación de coincidencia del sistema",
            "fy88": "Coincidencia",
            "fy89": "Artículo",
            "fy90": "Revancha",
            "fy91": "Pago Y Uso",
            "fy92": "Por favor, introduzca",
            "fy93": "Números",
            "fy94": "Por favor, elija el tipo de producto",
            "fy95": "Por favor, introduzca la cantidad de productos.",
            "fy96": "Por favor, introduzca la cantidad",
            "fy97": "Buscar Productos",
            "fy98": "Código De Producto",
            "fy99": "Copiar",
            "fy100": "Precio De Compra",
            "fy101": "Precio Promocional",
            "fy102": "Productos retirados de los estantes",
            "fy103": "Margen",
            "fy104": "Presentación",
            "fy105": "Todos Los Productos",
            "fy106": "Número de comentarios",
            "fy107": "Operación",
            "fy108": "Ver Detalles",
            "fy109": "Total de este producto",
            "fy110": "Comentarios"
        },
        "sys": {
            "fy1": "Mensajes Del Sistema",
            "fy2": "Guardar",
            "fy3": "Detalles",
            "fy4": "Ir A La Entrega"
        },
        "myCenter": {
            "fy1": "Salir de inicio de sesión",
            "fy2": "Centro Personal",
            "fy3": "Mi Encargo",
            "fy4": "Mi Invitación",
            "fy5": "Mi Billetera",
            "fy6": "Giro",
            "fy7": "Depósitos",
            "fy8": "Retiro de efectivo",
            "fy9": "Detalles de la factura",
            "fy10": "Vinculación",
            "fy11": "Servicios funcionales",
            "fy12": "Modificar la contraseña",
            "fy13": "Modificar la contraseña de pago",
            "fy14": "Establecer la contraseña de pago",
            "fy15": "Información de la tienda",
            "fy16": "Atención",
            "fy17": "Número De Visitas",
            "fy18": "Saldo De Promoción",
            "fy19": "Mi Saldo",
            "fy20": "Mi Encargo",
            "fy21": "Mi devolución de Comisión",
            "fy22": "Tiendas Invitadas",
            "fy23": "Código De Invitación",
            "fy24": "Invitar A Conectar",
            "fy25": "Código De Invitación",
            "fy26": "Número de personas en el primer nivel",
            "fy27": "Número de personas en el segundo nivel",
            "fy28": "Número de personas en el tercer nivel",
            "fy29": "Mi Invitación",
            "fy30": "Mis usuarios invitados",
            "fy31": "Registro de invitaciones / ingresos",
            "fy32": "Saldo",
            "fy33": "Saldo De Promoción",
            "fy34": "Por favor, introduzca",
            "fy35": "Hasta Convertible",
            "fy36": "Presentación",
            "fy37": "Recargar Mi Saldo",
            "fy38": "Entrada Máxima",
            "fy39": "Por favor, introduzca el monto de la transferencia.",
            "fy40": "Transferencia exitosa",
            "fy41": "Importe Del Depósito",
            "fy42": "Por favor, introduzca el monto del depósito.",
            "fy43": "Consejos Cálidos",
            "fy44": "Es una plataforma de comercio electrónico que le permite comprar productos que conoce en línea, al tiempo que descubre nuevas tiendas y vendedores, buscar artículos de primera necesidad en las tiendas o navegar por una gran selección de productos en las categorías de salud y belleza, electrónica, moda, hogar y vida, bebés y juguetes, etc.",
            "fy45": "Cantidad Recargada",
            "fy46": "Dirección",
            "fy47": "Copiar",
            "fy48": "UnionPay recarga, por favor póngase en contacto con el Secretario de servicio al cliente para manejar el negocio",
            "fy49": "Por favor, suba el comprobante de pago",
            "fy50": "Presentación",
            "fy51": "Por favor, introduzca la cantidad",
            "fy52": "No hay Canal de pago por el momento",
            "fy53": "Carga Como Máximo",
            "fy54": "Método de retiro de efectivo",
            "fy55": "Por favor, elija el método de retiro de efectivo.",
            "fy56": "Tarifas De Servicio",
            "fy57": "Importe Del Retiro",
            "fy58": "Por favor, introduzca la cantidad",
            "fy59": "Tarifas De Servicio",
            "fy60": "Actividades",
            "fy61": "Saldo Disponible",
            "fy62": "Refleja La Dirección",
            "fy63": "Retiro de efectivo",
            "fy64": "Contraseña De Pago",
            "fy65": "Introduzca la contraseña de pago",
            "fy66": "Ir A Pagar",
            "fy67": "Por favor, introduzca la cantidad",
            "fy68": "Por favor, agregue el método de retiro de efectivo.",
            "fy69": "Detalles de la factura",
            "fy70": "Tiempo",
            "fy71": "Número De Pedido",
            "fy72": "Importe",
            "fy73": "Nota",
            "fy74": "Vinculación",
            "fy75": "Por favor, elija el Acuerdo",
            "fy76": "Dirección de la billetera",
            "fy77": "Por favor, introduzca",
            "fy78": "Guardar",
            "fy79": "Nuevo Éxito",
            "fy80": "Modificación",
            "fy81": "Añadir",
            "fy82": "Dirección",
            "fy83": "Por favor, elija la red principal",
            "fy84": "Por favor, elija el Acuerdo",
            "fy85": "Dirección de la billetera",
            "fy86": "Por favor, introduzca",
            "fy87": "Guardar",
            "fy88": "Eliminar",
            "fy89": "Modificación Exitosa",
            "fy90": "Nuevo Éxito",
            "fy91": "Eliminación exitosa",
            "fy92": "Actualizar la información de la tienda",
            "fy93": "Por favor, introduzca el nombre de la tienda.",
            "fy94": "Guardar",
            "fy95": "Carga Como Máximo",
            "fy96": "Por favor, introduzca el nombre de la tienda."
        },
        size: {
            fy1: 'Tamaño'
        }
    }
}
